import React, { Component } from 'react';
import sortBy from 'lodash.sortby';
import PropTypes from 'prop-types';
import differenceBy from 'lodash.differenceby';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import {
  getGroupsPinnedNames,
  getGroupsById,
  getMainGroupId,
  getFavoritesGroupId,
  getMembersFetched,
} from '../js/groups/selectors';
import LinkButton from './LinkButton';
import { getMeId } from '../js/me/selectors';
import { fetchGroupMembersRequest } from '../js/groups/actions';
import { PbxSettings } from '../js/phone/PbxSettingsUtils';
import { PhoneEnums } from '../js/phone/PhoneUtils';
import { getSupplier } from '../js/phone/selectors';

const messages = defineMessages({
  select: {
    id: 'GroupsMembersShortcuts.label.select',
    defaultMessage: 'Select: ',
  },
  yourself: {
    id: 'GroupsMembersShortcuts.label.yourself',
    defaultMessage: 'yourself',
  },
  favorites: {
    id: 'GroupsMembersShortcuts.label.favorites',
    defaultMessage: 'favorites',
  },
  everyUser: {
    id: 'GroupsMembersShortcuts.label.everyUser',
    defaultMessage: 'every user',
  },
  fromGroup: {
    id: 'GroupsMembersShortcuts.label.fromGroup',
    defaultMessage: 'Select from group: ',
  },
  or: {
    id: 'GroupsMembersShortcuts.label.or',
    defaultMessage: 'Or: ',
  },
  nobody: {
    id: 'GroupsMembersShortcuts.label.nobody',
    defaultMessage: 'nobody',
  },
  showLess: {
    id: 'GroupsMembersShortcuts.label.showLess',
    defaultMessage: 'show less',
  },
  showMore: {
    id: 'GroupsMembersShortcuts.label.showMore',
    defaultMessage: 'show more',
  },
});

class GroupsMembersShortcuts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
    };
  }

  componentDidMount = () => {
    if (this.props.fetchUnloadedMembers) {
      this.props.groupsShortcuts.forEach((group) => {
        if (!group.membersFetched) {
          this.props.fetchMembers(group.id);
        }
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.fetchUnloadedMembers &&
      (!prevProps.groupsShortcuts || !prevProps.groupsShortcuts.length) &&
      this.props.groupsShortcuts &&
      this.props.groupsShortcuts.length
    ) {
      this.props.groupsShortcuts.forEach((group) => {
        if (!group.membersFetched) {
          this.props.fetchMembers(group.id);
        }
      });
    }
  };

  toggle = () => {
    this.setState({
      showAll: !this.state.showAll,
    });
  };

  handleGroupSelect = (id) => {
    if (this.props.fetchExtensions) {
      this.props.onSelect(
        this.props.members[id]
          ? this.props.members[id].members
              .filter(
                (member) =>
                  member.mainExtensionNumber &&
                  member.mainExtensionNumber !==
                    PbxSettings.DISABLED_PHONE_NUMBER
              )
              .map((user) => ({
                value:
                  this.props.supplier === PhoneEnums.PbxType.ABILIS
                    ? user.mainExtensionNumber
                    : user.mainExtensionUsername,
                label: `${user.mainExtensionNumber} - ${user.departmentFullname}`,
              }))
          : []
      );
    } else {
      this.props.onSelect(
        this.props.members[id]
          ? this.props.allowedContacts
            ? this.props.members[id].members.filter((m) => m.type !== 'U' || !this.props.excludeMe || m.id !== this.props.me)
            : this.props.members[id].members
                .filter((m) => m.type === 'U' && (!this.props.excludeMe || m.id !== this.props.me))
                .map((m) => m.id)
          : []
      );
    }
  };

  render() {
    const {
      includeMyself,
      includeFavourites,
      groupsToShow,
      intl: { formatMessage },
    } = this.props;

    const pinned = this.props.groupsShortcuts.filter(
      (group) => group.hidden === false || group.hidden === null
    );
    const others = sortBy(differenceBy(this.props.groupsShortcuts, pinned), [
      'name',
    ]);
    const orderedShortcuts = [...pinned, ...others];

    return (
      <>
        {(includeMyself || includeFavourites) && (
          <div className="row">
            <div className="col">
              {formatMessage(messages.select)}
              {includeMyself && (
                <>
                  <LinkButton
                    onClick={() =>
                      this.props.onSelect([{ type: 'U', id: this.props.me }])
                    }
                  >
                    {formatMessage(messages.yourself)}
                  </LinkButton>
                  {includeFavourites && <>|</>}
                </>
              )}
              {includeFavourites && (
                <LinkButton
                  onClick={() => this.handleGroupSelect(this.props.favoritesId)}
                >
                  {formatMessage(messages.favorites)}
                </LinkButton>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            {formatMessage(messages.fromGroup)}
            {!this.state.showAll &&
              orderedShortcuts.slice(0, groupsToShow).map((group, idx) => (
                <span key={`show_${group.id}_${idx}`}>
                  <LinkButton onClick={() => this.handleGroupSelect(group.id)}>
                    {group.name}
                  </LinkButton>
                  {(idx < orderedShortcuts.slice(0, groupsToShow).length - 1 ||
                    orderedShortcuts.length > groupsToShow) &&
                    '|'}
                </span>
              ))}
            {this.state.showAll &&
              orderedShortcuts.map((group, idx) => (
                <span key={`show_${group.id}_${idx}`}>
                  <LinkButton onClick={() => this.handleGroupSelect(group.id)}>
                    {group.name}
                  </LinkButton>
                  |
                </span>
              ))}
            {orderedShortcuts.length > groupsToShow ? (
              this.state.showAll ? (
                <LinkButton onClick={this.toggle}>
                  {formatMessage(messages.showLess)}
                </LinkButton>
              ) : (
                <LinkButton onClick={this.toggle}>
                  {formatMessage(messages.showMore)}
                </LinkButton>
              )
            ) : (
              <div />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {formatMessage(messages.or)}
            <LinkButton
              onClick={() => this.handleGroupSelect(this.props.mainId)}
            >
              {formatMessage(messages.everyUser)}
            </LinkButton>
            |
            <LinkButton onClick={() => this.props.onSelect([])}>
              {formatMessage(messages.nobody)}
            </LinkButton>
          </div>
        </div>
      </>
    );
  }
}

GroupsMembersShortcuts.propTypes = {
  includeMyself: PropTypes.bool,
  includeFavourites: PropTypes.bool,
  allowedContacts: PropTypes.bool,
  groupsToShow: PropTypes.number,
  fetchUnloadedMembers: PropTypes.bool,
  fetchExtensions: PropTypes.bool,
};

GroupsMembersShortcuts.defaultProps = {
  includeMyself: true,
  includeFavourites: true,
  allowedContacts: true,
  fetchUnloadedMembers: true,
  groupsToShow: 3,
  fetchExtensions: false,
};

function mapStateToProps(state) {
  return {
    groupsShortcuts: getGroupsPinnedNames(state).slice(
      1,
      getGroupsPinnedNames(state).length - 1
    ),
    me: getMeId(state),
    groups: getGroupsById(state),
    members: getMembersFetched(state),
    favoritesId: getFavoritesGroupId(state),
    mainId: getMainGroupId(state),
    supplier: getSupplier(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetchMembers: fetchGroupMembersRequest,
  })(GroupsMembersShortcuts)
);
