import React from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import PbxSettings from './PbxSettings';
import { getSupplier } from '../../../../js/phone/selectors';
import { PhoneEnums } from '../../../../js/phone/PhoneUtils';
import PbxPhoneRulesShowNethesis from './PbxPhoneRulesShowNethesis';
import { PhoneRulesEnums } from '../../../../js/phoneRules/PhoneRulesUtils';

const PbxPhoneRulesShowAbilis = React.lazy(() =>
  import('./PbxPhoneRulesShowAbilis')
);
const PbxPhoneRulesShowPage = (props) => {
  let context = '';
  const path = props.location.pathname.split('/');
  if (path.indexOf('users') > -1) {
    context = PhoneRulesEnums.SettingsContext.users;
  } else {
    context = PhoneRulesEnums.SettingsContext.pbx;
  }
  return (
    <PbxSettings scrolling>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          {props.supplier === PhoneEnums.PbxType.NETHESIS ? (
            <PbxPhoneRulesShowNethesis {...props} context={context} />
          ) : (
            <PbxPhoneRulesShowAbilis {...props} />
          )}
        </React.Suspense>
      </ErrorBoundary>
    </PbxSettings>
  );
};

function mapStateToProps(state) {
  return {
    supplier: getSupplier(state),
  };
}

export default connect(mapStateToProps)(PbxPhoneRulesShowPage);
