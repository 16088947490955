import keyBy from 'lodash.keyby';
import pull from 'lodash.pull';
import pick from 'lodash.pick';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
import unionWith from 'lodash.unionwith';
import merge from 'lodash.merge';
import moment from 'moment';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_CITIES_REQUEST,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILURE,
  FETCH_NATIONS_REQUEST,
  FETCH_NATIONS_SUCCESS,
  FETCH_NATIONS_FAILURE,
  FETCH_ABOOK_CONTACTS_REQUEST,
  FETCH_ABOOK_CONTACTS_SUCCESS,
  FETCH_ABOOK_CONTACTS_FAILURE,
  FETCH_ABOOK_CONTACT_REQUEST,
  FETCH_ABOOK_CONTACT_SUCCESS,
  FETCH_ABOOK_CONTACT_FAILURE,
  SAVE_ABOOK_CONTACT_REQUEST,
  SAVE_ABOOK_CONTACT_SUCCESS,
  SAVE_ABOOK_CONTACT_FAILURE,
  DELETE_ABOOK_CONTACT_REQUEST,
  DELETE_ABOOK_CONTACT_SUCCESS,
  DELETE_ABOOK_CONTACT_FAILURE,
  FETCH_ABOOK_INITIALS_REQUEST,
  FETCH_ABOOK_INITIALS_SUCCESS,
  FETCH_ABOOK_INITIALS_FAILURE,
  FETCH_ABOOK_TAGS_REQUEST,
  FETCH_ABOOK_TAGS_SUCCESS,
  FETCH_ABOOK_TAGS_FAILURE,
  SET_ABOOK_FILTER,
  FETCH_CACHED_CONTACT_SUCCESS,
  DELETE_MASSIVE_CONTACTS_REQUEST,
  DELETE_MASSIVE_CONTACTS_SUCCESS,
  DELETE_MASSIVE_CONTACTS_FAILURE,
  IMPORT_ABOOK_CONTACT_REQUEST,
  IMPORT_ABOOK_CONTACT_SUCCESS,
  IMPORT_ABOOK_CONTACT_FAILURE,
  CANCEL_IMPORT_ABOOK_CONTACT_REQUEST,
  CANCEL_IMPORT_ABOOK_CONTACT_SUCCESS,
  CANCEL_IMPORT_ABOOK_CONTACT_FAILURE,
  TOGGLE_IMPORT_ABOOK_CONTACT_MODAL,
  CONFIRM_IMPORT_ABOOK_CONTACT_REQUEST,
  CONFIRM_IMPORT_ABOOK_CONTACT_SUCCESS,
  CONFIRM_IMPORT_ABOOK_CONTACT_FAILURE,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  ABOOK_UPDATE_NOTIFICATION,
  ABOOK_DELETE_NOTIFICATION,
  FETCH_IMPORT_STATUS_REQUEST,
  FETCH_IMPORT_STATUS_SUCCESS,
  FETCH_IMPORT_STATUS_FAILURE,
  FETCH_ATECO_CODES_REQUEST,
  FETCH_ATECO_CODES_SUCCESS,
  FETCH_ATECO_CODES_FAILURE,
  FETCH_ABOOK_LISTS_REQUEST,
  FETCH_ABOOK_LISTS_SUCCESS,
  FETCH_ABOOK_LISTS_FAILURE,
  FETCH_ABOOK_LIST_REQUEST,
  FETCH_ABOOK_LIST_SUCCESS,
  FETCH_ABOOK_LIST_FAILURE,
  SAVE_ABOOK_LIST_REQUEST,
  SAVE_ABOOK_LIST_SUCCESS,
  SAVE_ABOOK_LIST_FAILURE,
  DELETE_ABOOK_LIST_REQUEST,
  DELETE_ABOOK_LIST_SUCCESS,
  DELETE_ABOOK_LIST_FAILURE,
  FETCH_ABOOK_CONTACT_INFO_CALLS_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_CALLS_SUCCESS,
  FETCH_ABOOK_CONTACT_INFO_CALLS_FAILURE,
  FETCH_ABOOK_CONTACT_INFO_TICKETS_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_TICKETS_SUCCESS,
  FETCH_ABOOK_CONTACT_INFO_TICKETS_FAILURE,
  FETCH_ABOOK_CONTACT_INFO_EVENTS_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_EVENTS_SUCCESS,
  FETCH_ABOOK_CONTACT_INFO_EVENTS_FAILURE,
  FETCH_ABOOK_CONTACT_INFO_FAXES_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_FAXES_SUCCESS,
  FETCH_ABOOK_CONTACT_INFO_FAXES_FAILURE,
  SET_ABOOK_INITIAL,
  FETCH_ABOOK_NUMBER_SUCCESS,
  ABOOK_INSERT_NOTIFICATION,
} from './types';
import {
  FETCH_GROUP_MEMBERS_SUCCESS,
  FETCH_GROUP_SUCCESS,
} from '../groups/types';
import Utils from '../lib/utils';
import { PUT_AVATAR_SUCCESS } from '../files/types';
import { AVATAR_TYPES } from '../files/FileUtils';
import { INSERT_CALLS_NOTES_SUCCESS } from '../cdr/types';
import {
  DELETE_CALENDAR_EVENT_FAILURE,
  DELETE_CALENDAR_EVENT_SUCCESS,
} from '../calendar/types';

const initialState = {
  fetchContactsLoaded: true,
  fetchContactsError: null,
  fetchContactsEnd: false,
  fetchContactLoaded: true,
  fetchContactError: null,
  isContactOperation: true,
  saveContactLoaded: true,
  savedContactId: null,
  saveContactError: null,
  deleteContactLoaded: true,
  deleteContactError: null,
  cachedContacts: {},
  pinnedGroupsContacts: [],
  listContacts: {
    byId: {},
    allIds: [],
    currentPage: 0,
    currentInitial: '',
  },
  companies: {
    loading: false,
    error: false,
    searched: '',
    data: [],
  },
  cities: {
    loading: false,
    error: false,
    searched: '',
    data: [],
  },
  nations: {
    loading: false,
    error: false,
    searched: '',
    data: [],
  },
  initials: {
    loaded: true,
    error: null,
    data: [],
  },
  tags: {
    loaded: true,
    error: null,
    data: [],
  },
  filter: {
    public: true,
    private: true,
    name: '',
    tags: [],
    atecoCode: null,
    vatFiscalCode: '',
  },
  deleteMassiveContacts: {
    loaded: true,
    success: null,
    error: null,
  },
  importContact: {
    error: null,
    loaded: true,
    success: null,
  },
  cancelImportContact: {
    error: null,
    loaded: true,
    success: null,
  },
  confirmImportContact: {
    error: null,
    loaded: true,
    success: null,
  },
  importStatus: {
    error: null,
    loaded: true,
    pending: null,
  },
  atecoCodes: {
    loading: false,
    error: false,
    searched: '',
    byId: {},
    allIds: [],
    selectedAtecoCode: null,
  },
  fetchAbookListsLoaded: true,
  fetchAbookListsError: null,
  fetchAbookListLoaded: true,
  fetchAbookListError: null,
  saveAbookListLoaded: true,
  saveAbookListError: null,
  savedAbookListId: null,
  deleteAbookListLoaded: true,
  deleteAbookListError: null,
  lists: {
    byId: {},
    allIds: [],
    currentPage: 0,
    total: 0,
  },
  lastRoute: [],
  contactInfo: {
    callsLoaded: true,
    callsError: true,
    calls: [],
    ticketsLoaded: true,
    ticketsError: true,
    tickets: [],
    eventsLoaded: true,
    eventsError: true,
    events: [],
    faxesLoaded: true, //check whi true
    faxesError: true,
    faxes: [],
  },
};
export default function listContacts(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case SET_ABOOK_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
        },
      };
    case SET_ABOOK_INITIAL:
      return {
        ...state,
        listContacts: {
          ...state.listContacts,
          currentInitial: action.initial,
        },
      };
    case FETCH_ABOOK_TAGS_REQUEST:
      return {
        ...state,
        tags: {
          loaded: false,
          error: null,
          data: [],
        },
      };
    case FETCH_ABOOK_TAGS_SUCCESS:
      return {
        ...state,
        tags: {
          loaded: true,
          error: null,
          data: action.tags,
        },
      };
    case FETCH_ABOOK_TAGS_FAILURE:
      return {
        ...state,
        tags: {
          loaded: false,
          error: action.errors,
          data: [],
        },
      };
    case FETCH_ABOOK_INITIALS_REQUEST:
      return {
        ...state,
        initials: {
          loaded: false,
          error: null,
          data: [],
        },
      };
    case FETCH_ABOOK_INITIALS_SUCCESS:
      return {
        ...state,
        initials: {
          loaded: true,
          error: null,
          data: action.initials,
        },
        listContacts: {
          ...state.listContacts,
          currentInitial:
            action.filters && action.filters.onMount && state.listContacts.currentInitial ? state.listContacts.currentInitial : action.initials.length > 0 ? action.initials[0] : null,
        },
      };
    case FETCH_ABOOK_INITIALS_FAILURE:
      return {
        ...state,
        initials: {
          loaded: true,
          error: action.errors,
          data: [],
        },
      };
    case FETCH_CITIES_REQUEST:
      return {
        ...state,
        cities: {
          loading: true,
          error: false,
          searched: action.name,
          data: [],
        },
      };
    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        cities: {
          ...state.cities,
          loading: false,
          data: action.cities,
        },
      };
    case FETCH_CITIES_FAILURE:
      return {
        ...state,
        cities: {
          ...state.cities,
          loading: false,
          error: action.errors,
        },
      };

    case FETCH_NATIONS_REQUEST:
      return {
        ...state,
        nations: {
          loading: true,
          error: false,
          searched: action.name,
          data: [],
        },
      };

    case FETCH_NATIONS_SUCCESS:
      return {
        ...state,
        nations: {
          ...state.nations,
          loading: false,
          data: action.nations,
        },
      };

    case FETCH_NATIONS_FAILURE:
      return {
        ...state,
        nations: {
          ...state.nations,
          loading: false,
          error: action.errors,
        },
      };
    case FETCH_COMPANIES_REQUEST:
      return {
        ...state,
        companies: {
          loading: true,
          error: false,
          searched: action.name,
          data: [],
        },
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: {
          ...state.companies,
          loading: false,
          data: action.companies,
        },
      };
    case FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        companies: {
          ...state.companies,
          loading: false,
          error: action.errors,
        },
      };
    case FETCH_ABOOK_CONTACTS_REQUEST:
      return {
        ...state,
        fetchContactsLoaded: false,
        fetchContactsError: null,
        isContactOperation: false,
      };
    case FETCH_ABOOK_CONTACTS_SUCCESS: {
      if (state.isContactOperation) {
        return state;
      }
      let {
        listContacts: { byId, allIds },
      } = state;
      let end = false;
      if (action.contacts.data.length > 0) {
        if (action.contacts.page > 0) {
          action.contacts.data.forEach((contact) => {
            byId[contact.id] = merge(byId[contact.id] || {}, contact);
          });
        } else {
          byId = {
            ...pick(state.listContacts.byId, state.pinnedGroupsContacts),
            ...keyBy(action.contacts.data, 'id'),
          };
        }
        if (action.contacts.page > 0) {
          allIds = unionWith(
            allIds,
            action.contacts.data.map((obj) => obj.id),
            isEqual
          );
        } else {
          allIds = action.contacts.data.map((obj) => obj.id);
        }
        end = action.contacts.data.length < action.contacts.pageSize;
      } else if (action.contacts.page === 0) {
        byId = {};
        allIds = [];
      } else {
        end = true;
      }

      return {
        ...state,
        fetchContactsLoaded: true,
        fetchContactsError: null,
        fetchContactsEnd: end,
        listContacts: {
          ...state.listContacts,
          byId: { ...byId },
          allIds: [...allIds],
          currentPage: action.contacts.page,
        },
      };
    }
    case FETCH_ABOOK_CONTACTS_FAILURE:
      return {
        ...state,
        fetchContactsLoaded: true,
        fetchContactsError: action.errors,
      };

    case FETCH_ABOOK_CONTACT_REQUEST:
      return {
        ...state,
        fetchContactLoaded: false,
        fetchContactError: null,
        isContactOperation: true,
      };
    case FETCH_ABOOK_CONTACT_SUCCESS:
      return {
        ...state,
        listContacts: {
          ...state.listContacts,
          byId: {
            ...state.listContacts.byId,
            [action.contactData.id]: {
              ...state.listContacts.byId[action.contactData.id],
              ...action.contactData,
            },
          },
        },
        fetchContactLoaded: true,
        fetchContactError: null,
        isContactOperation: true,
      };
    case FETCH_ABOOK_CONTACT_FAILURE:
      return {
        ...state,
        fetchContactLoaded: false,
        fetchContactError: action.errors,
        isContactOperation: false,
      };
    case SAVE_ABOOK_CONTACT_REQUEST:
      return {
        ...state,
        saveContactLoaded: false,
        savedContactId: null,
        saveContactError: null,
      };
    case SAVE_ABOOK_CONTACT_SUCCESS:
      const cachedContacts = { ...state.cachedContacts };
      action.contactData.numbers.forEach((numberData) => {
        if (cachedContacts[numberData.number]) {
          delete cachedContacts[numberData.number];
        }
      });
      return {
        ...state,
        saveContactLoaded: true,
        savedContactId: action.contactData.id,
        saveContactError: null,
        cachedContacts,
        listContacts: {
          ...state.listContacts,
          byId: {
            ...state.listContacts.byId,
            [action.contactData.id]: {
              ...state.listContacts.byId[action.contactData.id],
              ...action.contactData,
              fullname: Utils.getAbookFullname(
                action.contactData.name,
                action.contactData.surname,
                action.contactData.company
              ),
            },
          },
          allIds: [...state.listContacts.allIds, action.contactData.id],
        },
      };
    case SAVE_ABOOK_CONTACT_FAILURE:
      return {
        ...state,
        saveContactLoaded: true,
        saveContactError: action.errors,
      };
    case ABOOK_INSERT_NOTIFICATION: {
      const cachedContacts = { ...state.cachedContacts };
      if (action.payload.numbers) {
        action.payload.numbers.forEach((numberData) => {
          if (cachedContacts[numberData.number]) {
            delete cachedContacts[numberData.number];
          }
        });
      }
      return {
        ...state,
        cachedContacts,
      };
    }

    case FETCH_GROUP_SUCCESS: {
      return {
        ...state,
        listContacts: {
          ...state.listContacts,
          byId: {
            ...state.listContacts.byId,
            ...keyBy(action.group.listContacts, 'id'),
          },
        },
      };
    }
    case DELETE_ABOOK_CONTACT_REQUEST:
      return {
        ...state,
        deleteContactLoaded: false,
        deleteContactError: null,
      };
    case DELETE_ABOOK_CONTACT_SUCCESS: {
      const allIds = [...state.listContacts.allIds];
      const byId = { ...state.listContacts.byId };
      delete byId[action.contactId];
      pull(allIds, action.contactId);
      return {
        ...state,
        listContacts: { ...state.listContacts, byId, allIds },
        deleteContactLoaded: true,
        deleteContactError: null,
      };
    }
    case DELETE_ABOOK_CONTACT_FAILURE:
      return {
        ...state,
        deleteContactLoaded: false,
        deleteContactError: action.errors,
      };
    case FETCH_CACHED_CONTACT_SUCCESS:
      return {
        ...state,
        cachedContacts: {
          ...state.cachedContacts,
          [action.contactData.number]: action.contactData,
        },
      };

    case DELETE_MASSIVE_CONTACTS_REQUEST:
      return {
        ...state,
        deleteMassiveContacts: {
          loaded: false,
          success: null,
          error: null,
        },
      };
    case DELETE_MASSIVE_CONTACTS_SUCCESS:
      return {
        ...state,
        deleteMassiveContacts: {
          loaded: true,
          success: action.success.status,
          error: null,
          numberOfDeletedContacts: action.success.data.count,
        },
      };
    case DELETE_MASSIVE_CONTACTS_FAILURE:
      return {
        ...state,
        deleteMassiveContacts: {
          loaded: true,
          success: null,
          error: action.errors,
        },
      };
    case IMPORT_ABOOK_CONTACT_REQUEST:
      return {
        ...state,
        importContact: {
          loaded: false,
          success: null,
          error: null,
        },
      };
    case IMPORT_ABOOK_CONTACT_SUCCESS: {
      return {
        ...state,
        importContact: {
          loaded: true,
          error: null,
          success: action.success.status,
        },
      };
    }
    case IMPORT_ABOOK_CONTACT_FAILURE:
      return {
        ...state,
        importContact: {
          loaded: true,
          error: action.errors,
          success: null,
        },
      };
    case CANCEL_IMPORT_ABOOK_CONTACT_REQUEST:
      return {
        ...state,
        cancelImportContact: {
          loaded: false,
          success: null,
          error: null,
        },
      };
    case CANCEL_IMPORT_ABOOK_CONTACT_SUCCESS:
      return {
        ...state,
        cancelImportContact: {
          loaded: true,
          error: null,
          success: action.success.status,
        },
      };
    case CANCEL_IMPORT_ABOOK_CONTACT_FAILURE:
      return {
        ...state,
        cancelImportContact: {
          loaded: true,
          error: action.errors,
          success: null,
        },
      };
    case CONFIRM_IMPORT_ABOOK_CONTACT_REQUEST:
      return {
        ...state,
        confirmImportContact: {
          loaded: false,
          success: null,
          error: null,
        },
      };
    case CONFIRM_IMPORT_ABOOK_CONTACT_SUCCESS:
      return {
        ...state,
        confirmImportContact: {
          loaded: true,
          error: null,
          success: action.data,
        },
      };
    case CONFIRM_IMPORT_ABOOK_CONTACT_FAILURE:
      return {
        ...state,
        confirmImportContact: {
          loaded: true,
          error: action.errors,
          success: null,
        },
      };
    case TOGGLE_IMPORT_ABOOK_CONTACT_MODAL:
      return {
        ...state,
        contactPreviewModal: action.state,
      };
    case ABOOK_UPDATE_NOTIFICATION: {
      const updatedEntity = state.listContacts.byId[action.payload.idEntity];
      if (!updatedEntity) {
        return state;
      }
      if (action.payload.surname) {
        updatedEntity.surname = action.payload.surname;
      }
      if (action.payload.name) {
        updatedEntity.name = action.payload.name;
      }
      if (action.payload.company) {
        updatedEntity.company = action.payload.company;
      }
      updatedEntity.fullname = Utils.getAbookFullname(
        updatedEntity.name,
        updatedEntity.surname,
        updatedEntity.company
      );
      if (action.payload.email) {
        updatedEntity.email = action.payload.email;
      }
      if (action.payload.avatar) {
        updatedEntity.avatar = action.payload.avatar;
      }
      if (action.payload.notes) {
        updatedEntity.notes = action.payload.notes;
      }
      if (action.payload.addresses) {
        updatedEntity.addresses = [...action.payload.addresses];
      }
      if (
        action.payload.tagsRemoved.length > 0 ||
        action.payload.tagsAdded.length > 0
      ) {
        const existingTags = [...updatedEntity.tags];
        action.payload.tagsRemoved.forEach((tag) => pull(existingTags, tag));
        action.payload.tagsAdded.forEach((tag) => existingTags.push(tag));
        updatedEntity.tags = existingTags;
      }
      if (
        action.payload.groupsRemoved.length > 0 ||
        action.payload.groupsAdded.length > 0
      ) {
        const existingGroups = [...updatedEntity.groups];
        action.payload.groupsRemoved.forEach((group) =>
          pull(existingGroups, group)
        );
        action.payload.groupsAdded.forEach((group) =>
          existingGroups.push(group)
        );
        updatedEntity.groups = existingGroups;
      }
      if (
        action.payload.numbersRemoved.length > 0 ||
        action.payload.numbersAdded.length > 0
      ) {
        const existingNumbers = updatedEntity.numbers.filter(
          (existingNumber) => {
            let toBeRemoved = false;
            action.payload.numbersRemoved.forEach((number) => {
              if (
                existingNumber.type === number.type &&
                existingNumber.number === number.number
              ) {
                toBeRemoved = true;
              }
            });
            return !toBeRemoved;
          }
        );
        action.payload.numbersAdded.forEach((number) =>
          existingNumbers.push(number)
        );
        updatedEntity.numbers = existingNumbers;
      }
      const cachedContacts = { ...state.cachedContacts };
      action.payload.numbersRemoved.forEach((numberData) => {
        if (cachedContacts[numberData.number]) {
          delete cachedContacts[numberData.number];
        }
      });
      action.payload.numbersAdded.forEach((numberData) => {
        if (cachedContacts[numberData.number]) {
          delete cachedContacts[numberData.number];
        }
      });
      return {
        ...state,
        cachedContacts,
        listContacts: {
          ...state.listContacts,
          byId: {
            ...state.listContacts.byId,
            [action.payload.idEntity]: { ...updatedEntity },
          },
        },
      };
    }
    case ABOOK_DELETE_NOTIFICATION: {
      if (action.payload.archived) {
        return state;
      }
      const byId = { ...state.listContacts.byId };
      const allIds = [...state.listContacts.allIds];
      delete byId[action.payload.idEntity];
      pull(allIds, action.payload.idEntity);
      const cachedContacts = { ...state.cachedContacts };
      action.payload.numbers.forEach((numberData) => {
        if (cachedContacts[numberData.number]) {
          delete cachedContacts[numberData.number];
        }
      });
      return {
        ...state,
        cachedContacts,
        listContacts: {
          ...state.listContacts,
          byId,
          allIds,
        },
      };
    }
    case LOCATION_CHANGE: {
      const newRoute = action.payload.location.pathname.substr(1).split('/');
      const { lastRoute } = state;
      if (lastRoute.length > 0 && lastRoute[0] !== newRoute[0]) {
        return {
          ...initialState,
          filter: newRoute[0] !== 'abook' ? initialState.filter : state.filter,
          listContacts: {
            ...state.listContacts,
            currentPage: 0,
            allIds: [],
            byId: pick(state.listContacts.byId, state.pinnedGroupsContacts),
          },
          initials: state.initials,
          cachedContacts: state.cachedContacts,
          pinnedGroupsContacts: state.pinnedGroupsContacts,
          lastRoute: newRoute,
        };
      }
      return {
        ...initialState,
        filter: newRoute[0] !== 'abook' ? initialState.filter : state.filter,
        listContacts: {
          ...state.listContacts,
          currentPage: 0,
          //allIds: [],
          //byId: pick(state.listContacts.byId, state.pinnedGroupsContacts),
        },
        initials: state.initials,
        cachedContacts: state.cachedContacts,
        pinnedGroupsContacts: state.pinnedGroupsContacts,
        lastRoute: newRoute,
      };
    }
    case FETCH_IMPORT_STATUS_REQUEST:
      return {
        ...state,
        importStatus: {
          error: null,
          loaded: false,
          pending: null,
        },
      };
    case FETCH_IMPORT_STATUS_SUCCESS:
      return {
        ...state,
        importStatus: {
          loaded: true,
          pending: action.response.data.pending,
          error: action.response.data.error,
        },
      };
    case FETCH_IMPORT_STATUS_FAILURE:
      return {
        ...state,
        importStatus: {
          error: action.errors,
          loaded: true,
          pending: null,
        },
      };

    case FETCH_ATECO_CODES_REQUEST:
      return {
        ...state,
        atecoCodes: {
          loading: true,
          error: false,
          data: [],
        },
      };
    case FETCH_ATECO_CODES_SUCCESS: {
      const orderedAtecoCode = sortBy(action.payload, 'code', 'asc');
      return {
        ...state,
        atecoCodes: {
          ...state.atecoCodes,
          loading: false,
          byId: keyBy(orderedAtecoCode, 'id'),
          allIds: orderedAtecoCode.map((obj) => obj.id),
        },
      };
    }
    case FETCH_ATECO_CODES_FAILURE:
      return {
        ...state,
        atecoCodes: {
          ...state.atecoCodes,
          loading: false,
          error: action.errors,
        },
      };
    case FETCH_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        pinnedGroupsContacts: [
          ...state.pinnedGroupsContacts,
          ...action.group.members
            .filter(
              (m) =>
                m.type === 'A' && state.pinnedGroupsContacts.indexOf(m.id) < 0
            )
            .map((m) => m.id),
        ],
      };
    case PUT_AVATAR_SUCCESS:
      if (action.avatar.type === AVATAR_TYPES.CONTACT && action.avatar.id) {
        return {
          ...state,
          listContacts: {
            ...state.listContacts,
            byId: {
              ...state.listContacts.byId,
              [action.avatar.id]: {
                ...state.listContacts.byId[action.avatar.id],
                avatar: action.avatar.stored,
              },
            },
          },
        };
      }
      return state;
    case FETCH_ABOOK_LISTS_REQUEST:
      return {
        ...state,
        fetchAbookListsLoaded: false,
        fetchAbookListsError: null,
      };
    case FETCH_ABOOK_LISTS_SUCCESS:
      return {
        ...state,
        fetchAbookListsLoaded: true,
        fetchAbookListsError: null,
        lists: {
          byId: keyBy(action.payload.data, 'id'),
          allIds: action.payload.data.map((row) => row.id),
        },
      };
    case FETCH_ABOOK_LISTS_FAILURE:
      return {
        ...state,
        fetchAbookListsLoaded: true,
        fetchAbookListsError: action.payload.error,
      };
    case FETCH_ABOOK_LIST_REQUEST:
      return {
        ...state,
        fetchAbookListLoaded: false,
        fetchAbookListError: null,
      };
    case FETCH_ABOOK_LIST_SUCCESS:
      return {
        ...state,
        fetchAbookListLoaded: true,
        fetchAbookListError: null,
        lists: {
          ...state.lists,
          byId: {
            ...state.lists.byId,
            [action.payload.id]: action.payload,
          },
        },
      };
    case FETCH_ABOOK_LIST_FAILURE:
      return {
        ...state,
        fetchAbookListLoaded: true,
        fetchAbookListError: action.payload.error,
      };
    case SAVE_ABOOK_LIST_REQUEST:
      return {
        ...state,
        saveAbookListLoaded: false,
        saveAbookListError: null,
        savedAbookListId: null,
      };
    case SAVE_ABOOK_LIST_SUCCESS:
      return {
        ...state,
        saveAbookListLoaded: true,
        saveAbookListError: null,
        savedAbookListId: action.id,
      };
    case SAVE_ABOOK_LIST_FAILURE:
      return {
        ...state,
        saveAbookListLoaded: true,
        saveAbookListError: action.payload.error,
      };
    case DELETE_ABOOK_LIST_REQUEST:
      return {
        ...state,
        deleteAbookListLoaded: false,
        deleteAbookListError: null,
      };
    case DELETE_ABOOK_LIST_SUCCESS:
      return {
        ...state,
        deleteAbookListLoaded: true,
        deleteAbookListError: null,
      };
    case DELETE_ABOOK_LIST_FAILURE:
      return {
        ...state,
        deleteAbookListLoaded: true,
        deleteAbookListError: action.payload.error,
      };
    case FETCH_ABOOK_CONTACT_INFO_CALLS_REQUEST:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          callsLoaded: false,
          callsError: false,
          calls: [],
        },
      };
    case FETCH_ABOOK_CONTACT_INFO_CALLS_SUCCESS:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          callsLoaded: true,
          callsError: false,
          calls: action.calls,
        },
      };
    case FETCH_ABOOK_CONTACT_INFO_CALLS_FAILURE:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          callsLoaded: true,
          callsError: true,
          calls: [],
        },
      };
    case INSERT_CALLS_NOTES_SUCCESS: {
      if (action.context !== 'abook') {
        return state;
      }
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          calls: state.contactInfo.calls.map((call) => {
            if (call.id === action.params.call) {
              call.notes = call.notes
                ? [
                    ...call.notes,
                    {
                      user: action.params.user,
                      text: action.params.text,
                      time: moment().utc().valueOf(),
                    },
                  ]
                : [
                    {
                      user: action.params.user,
                      text: action.params.text,
                      time: moment().utc().valueOf(),
                    },
                  ];
              return { ...call };
            }
            return call;
          }),
        },
      };
    }
    case FETCH_ABOOK_CONTACT_INFO_TICKETS_REQUEST:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          ticketsLoaded: false,
          ticketsError: false,
          tickets: [],
        },
      };
    case FETCH_ABOOK_CONTACT_INFO_TICKETS_SUCCESS:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          ticketsLoaded: true,
          ticketsError: false,
          tickets: action.data,
        },
      };
    case FETCH_ABOOK_CONTACT_INFO_TICKETS_FAILURE:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          ticketsLoaded: true,
          ticketsError: true,
          tickets: [],
        },
      };
    case FETCH_ABOOK_CONTACT_INFO_EVENTS_REQUEST:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          eventsLoaded: false,
          eventsError: false,
          events: [],
        },
      };
    case FETCH_ABOOK_CONTACT_INFO_EVENTS_SUCCESS:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          eventsLoaded: true,
          eventsError: false,
          events: action.data,
        },
      };
    case FETCH_ABOOK_CONTACT_INFO_EVENTS_FAILURE:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          eventsLoaded: true,
          eventsError: true,
          events: [],
        },
      };
    case FETCH_ABOOK_CONTACT_INFO_FAXES_REQUEST:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          faxesLoaded: false,
          faxesError: false,
          faxes: [],
        },
      };
    case FETCH_ABOOK_CONTACT_INFO_FAXES_SUCCESS:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          faxesLoaded: true,
          faxesError: false,
          faxes: action.faxes,
        },
      };
    case FETCH_ABOOK_CONTACT_INFO_FAXES_FAILURE:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          faxesLoaded: true,
          faxesError: true,
          faxes: [],
        },
      };
    case DELETE_CALENDAR_EVENT_SUCCESS: {
      const { events } = state.contactInfo;
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          events: events.filter((event) => event.id !== action.event.key),
        },
      };
    }
    case DELETE_CALENDAR_EVENT_FAILURE: {
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          eventsError: true,
        },
      };
    }
    case FETCH_ABOOK_NUMBER_SUCCESS:
      return {
        ...state,
        cachedContacts: {
          ...state.cachedContacts,
          [action.payload.number]: action.payload,
        },
      };
    default:
      return state;
  }
}
