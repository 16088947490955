import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select2 from '../../../components/formControls/Select2';
import { getPbxRoutes } from '../../../js/phone/selectors';

const PbxRoutesSelect = ({
  routes,
  selected,
  onSelect,
  error,
  placeholder,
  isClearable,
}) => {
  const routesOptions =
    routes &&
    routes.map((r) => ({
      value: r.route,
      label: `${r.route} ${r.name ? ` - ${r.name}` : ''}`,
    }));

  const selectedRoute =
    selected &&
    routesOptions &&
    routesOptions.filter((r) => r.value === selected);

  return (
    <Select2
      id="pbxRoutes"
      name="pbxRoutes"
      value={selectedRoute}
      onChange={onSelect}
      options={routesOptions}
      error={error}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

PbxRoutesSelect.propTypes = {
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.string,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

PbxRoutesSelect.defaultProps = {
  selected: null,
  error: null,
  isClearable: false,
  placeholder: null,
};

function mapStateToProps(state) {
  return {
    routes: getPbxRoutes(state) && getPbxRoutes(state).filter((r) => !r.hidden),
  };
}

export default connect(mapStateToProps)(PbxRoutesSelect);
