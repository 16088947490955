import unionWith from 'lodash.unionwith';
import isEqual from 'lodash.isequal';
import pull from 'lodash.pull';
import endsWith from 'lodash.endswith';
import {
  FETCH_PHONE_SETTINGS_SUCCESS,
  LOGIN_PHONE_SUCCESS,
  LOGIN_PHONE_FAILURE,
  LOGOUT_PHONE_SUCCESS,
  LOGOUT_PHONE_FAILURE,
  INITIAL_ABILIS_PHONE_STATUS_SUCCESS,
  INITIAL_NETHESIS_PHONE_STATUS_SUCCESS,
  ABILIS_PHONE_STATUS_CHANGES_SUCCESS,
  NETHESIS_PHONE_STATUS_CHANGES_SUCCESS,
  FETCH_INTERLOCUTOR_DATA_SUCCESS,
  FETCH_SEARCHED_CONTACTS_REQUEST,
  FETCH_SEARCHED_CONTACTS_SUCCESS,
  FETCH_SEARCHED_CONTACTS_FAILURE,
  TOGGLE_MUTE_SUCCESS,
  ACTIVATE_WEBRTC_REQUEST,
  ACTIVATE_WEBRTC_SUCCESS,
  ACTIVATE_WEBRTC_FAILURE,
  DEACTIVATE_WEBRTC_REQUEST,
  DEACTIVATE_WEBRTC_SUCCESS,
  DEACTIVATE_WEBRTC_FAILURE,
  INCOMING_WEBRTC_CALL,
  ACCEPTED_WEBRTC_CALL,
  SET_SEARCH_CONTACTS_FILTER,
  CHANGE_PHONE_STATUS,
  RECORD_CALL_SUCCESS,
  NETHESIS_WEBSOCKET_DISCONNECTED,
  UNSET_CALLED_BUSY,
  SPY_CALL_REQUEST,
  RESET_WEBRTC_SPY,
  SPY_CALL_FAILURE,
  INTRUDE_CALL_REQUEST,
  INTRUDE_CALL_FAILURE,
  SEND_STOP_USE_WEBPHONE_REQUEST,
  SEND_STOP_USE_WEBPHONE_FAILURE,
  FETCH_DEFAULT_DEVICE_SUCCESS,
  SEND_STOP_USE_WEBPHONE_SUCCESS,
  NO_SDP_WEBRTC_CALL,
  BUSY_WEBRTC_CALL,
  HANGUP_WEBRTC_CALL,
  DECLINING_WEBRTC_CALL,
  OUTGOING_WEBRTC_CALL,
  WEBPHONE_DETACHED,
  SET_NO_VALID_JSEP_CALL,
  REACTIVATE_WEBRTC_PHONE,
  SDP_WEBRTC_CALL,
  FETCH_PBX_ROUTES_REQUEST,
  FETCH_PBX_ROUTES_SUCCESS,
  FETCH_PBX_ROUTES_FAILURE,
  SAVE_PBX_ROUTE_REQUEST,
  SAVE_PBX_ROUTE_SUCCESS,
  SAVE_PBX_ROUTE_FAILURE,
  LOGIN_PHONE_REQUEST,
  FETCH_PBX_QUEUES_REQUEST,
  FETCH_PBX_QUEUES_SUCCESS,
  FETCH_PBX_QUEUES_FAILURE,
  SAVE_PBX_QUEUE_REQUEST,
  SAVE_PBX_QUEUE_SUCCESS,
  SAVE_PBX_QUEUE_FAILURE,
} from './types';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_ME_SUCCESS,
  NETHESIS_ME_PHONE_STATUS_CHANGES_SUCCESS,
} from '../me/types';
import { PhoneEnums } from './PhoneUtils';
import { PbxSettings, addUsernameToCallid } from './PbxSettingsUtils';
import { PhoneRulesEnums } from '../phoneRules/PhoneRulesUtils';

const initialState = {
  status: null,
  muted: false,
  recording: false,
  playbusy: false,
  webrtc: {
    using: false,
    active: null,
    registered: false,
    detached: false,
    error: false,
    incomingCallJsep: null,
    noSdpCall: false,
    sdpCall: false,
    validJsep: false,
    incomingCall: false,
    outgoingCall: false,
    activeCall: false,
    noValidJSepCall: false,
    reactivating: false,
  },
  fetchedUsers: false,
  sentStopUseWebphoneError: false,
  sentStopUseWebphoneLoaded: true,
  extensionsByUsername: {},
  allCallsById: {},
  allCallsIds: [],
  allParksById: {},
  allParksIds: [],
  allQueuesById: {},
  allQueuesIds: [],
  queues: [],
  oldCallsIds: [],
  searchContacts: {
    data: [],
    loaded: true,
    error: null,
    PhonePanel: {
      number: '',
      listOpen: false,
      selected: false,
    },
    CallPanel: {
      number: '',
      listOpen: false,
      selected: false,
    },
    ConferencePanel: {
      number: '',
      listOpen: false,
      selected: false,
    },
    FaxSendReceiver: {
      number: '',
      listOpen: false,
      selected: false,
    },
    FaxReceivedListSender: {
      number: '',
      listOpen: false,
      selected: false,
    },
    FaxSentListReceiver: {
      number: '',
      listOpen: false,
      selected: false,
    },
  },
};

export function phone(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_ME_SUCCESS:
      return {
        ...state,
        status: action.me.mainExtensionUsername
          ? state.status
          : PhoneEnums.PhoneStatuses.nophone,
      };
    case FETCH_PHONE_SETTINGS_SUCCESS:
      return {
        ...state,
        status:
          action.phoneSettings.extensions &&
          action.phoneSettings.extensions.main.number ===
            PbxSettings.DISABLED_PHONE_NUMBER
            ? PhoneEnums.PhoneStatuses.nophone
            : state.status,
      };
    case LOGIN_PHONE_REQUEST: {
      return {
        ...state,
        status: PhoneEnums.PhoneStatuses.logging,
      };
    }
    case LOGIN_PHONE_SUCCESS: {
      return {
        ...state,
        status: PhoneEnums.PhoneStatuses.logged,
      };
    }
    case LOGIN_PHONE_FAILURE: {
      return {
        ...state,
        status: PhoneEnums.PhoneStatuses.error,
      };
    }
    case LOGOUT_PHONE_SUCCESS:
    case LOGOUT_PHONE_FAILURE:
      return initialState;
    case INITIAL_ABILIS_PHONE_STATUS_SUCCESS:
    case ABILIS_PHONE_STATUS_CHANGES_SUCCESS: {
      let extensionsByUsername = { ...state.extensionsByUsername };
      let allCallsById = { ...state.allCallsById };
      let allParksById = { ...state.allParksById };
      let allQueuesById = { ...state.allQueuesById };
      let allCallsIds = [...state.allCallsIds];
      let allParksIds = [...state.allParksIds];
      let allQueuesIds = [...state.allQueuesIds];
      const users = action.status.users.filter(
        (user) => user.number !== PbxSettings.DISABLED_PHONE_NUMBER
      );
      users.forEach((user) => {
        extensionsByUsername = {
          ...extensionsByUsername,
          [user.username]: {
            ...extensionsByUsername[user.username],
            interface: user.interface,
            dnd: user.dnd,
            numbers: { [user.number]: user.status },
          },
        };
      });
      const newStatus = {};
      action.status.calls.forEach((call) => {
        if (call.status && extensionsByUsername[call.username]) {
          if (call.status !== PhoneEnums.CallsStatuses.none) {
            if (
              !newStatus[call.username] ||
              newStatus[call.username] !== PhoneEnums.CallsStatuses.active
            ) {
              newStatus[call.username] = call.status;
            }
          }
          // if I disable a SIP phone, this part put status=READY to that number replacing status=NOTREADY
          // https://gitlab.com/ambrogio-dev/YN-2dot0/yn-refactor-client/issues/443
          else if (
            extensionsByUsername[call.username].numbers[call.username] !==
            PhoneEnums.UserStatuses.unavailable
          ) {
            newStatus[call.username] =
              extensionsByUsername[call.username].interface ===
              PhoneEnums.Interfaces.CTIP
                ? PhoneEnums.UserStatuses.available
                : PhoneEnums.UserStatuses.ready;
          }
          extensionsByUsername = {
            ...extensionsByUsername,
            [call.username]: {
              ...extensionsByUsername[call.username],
              numbers: {
                ...extensionsByUsername[call.username].numbers,
                [call.username]:
                  newStatus[call.username] ||
                  extensionsByUsername[call.username].numbers[call.username],
              },
            },
          };
          if (call.callid) {
            const callId = addUsernameToCallid(call.callid, call.username);

            if (
              call.status === PhoneEnums.CallsStatuses.none ||
              call.status === PhoneEnums.CallsStatuses.offhook
            ) {
              if (allCallsById[callId]) delete allCallsById[callId];
              allCallsIds = pull(allCallsIds, callId);
            } else {
              const callData = {
                ...allCallsById[callId],
                ...call,
                called:
                  // WORKAROUND to manage Abilis configurations problems
                  // see ticket 3535
                  // PF 24/09/19
                  !extensionsByUsername[call.calling] &&
                  !extensionsByUsername[call.called]
                    ? call.username
                    : call.called,
              };
              allCallsById = {
                ...allCallsById,
                [callId]: callData,
              };
              allCallsIds = unionWith(allCallsIds, [callId], isEqual);
            }
          }
        }
      });
      action.status.parks.forEach((park) => {
        if (park.status === PhoneEnums.QueueStatus.added) {
          allParksById = {
            ...allParksById,
            [park.id]: park,
          };
          allParksIds = unionWith(allParksIds, [park.id], isEqual);
        } else {
          delete allParksById[park.id];
          allParksIds = pull(allParksIds, park.id);
        }
      });
      action.status.queues.forEach((queue) => {
        if (queue.status === PhoneEnums.QueueStatus.added) {
          allQueuesById = {
            ...allQueuesById,
            [queue.id]: queue,
          };
          allQueuesIds = unionWith(allQueuesIds, [queue.id], isEqual);
        } else {
          delete allQueuesById[queue.id];
          allQueuesIds = pull(allQueuesIds, queue.id);
        }
      });
      return {
        ...state,
        status: PhoneEnums.PhoneStatuses.inited,
        extensionsByUsername,
        fetchedUsers: true,
        allCallsById,
        allCallsIds,
        allParksById,
        allParksIds,
        allQueuesById,
        allQueuesIds,
      };
    }

    case INITIAL_NETHESIS_PHONE_STATUS_SUCCESS: {
      let extensionsByUsername = { ...state.extensionsByUsername };
      let allCallsById = {};
      let allParksById = {};
      let allQueuesById = {};
      let allCallsIds = [];
      let allParksIds = [];
      let allQueuesIds = [];
      action.status.users.forEach((user) => {
        const userData = extensionsByUsername[user.username] || {};
        let numbers = userData.numbers || {};
        numbers = { ...numbers, [user.number]: user.status };
        extensionsByUsername = {
          ...extensionsByUsername,
          [user.username]: {
            ...userData,
            dnd: user.dnd,
            numbers,
            pbxSettings: user.pbxSettings,
            queues: action.status.queuesData
              .filter((q) => q.members.indexOf(user.username) > -1)
              .map((q) => q.id),
          },
        };
      });
      action.status.calls.forEach((call) => {
        extensionsByUsername = {
          ...extensionsByUsername,
          [call.username]: {
            ...extensionsByUsername[call.username],
            numbers: {
              ...extensionsByUsername[call.username].numbers,
              [call.number]:
                call.status === PhoneEnums.CallsStatuses.active
                  ? PhoneEnums.UserStatuses.calling
                  : call.status,
            },
          },
        };
        const callId = addUsernameToCallid(call.callid, call.username);
        if (call.status === PhoneEnums.CallsStatuses.none) {
          if (allCallsById[callId]) delete allCallsById[callId];
          allCallsIds = pull(allCallsIds, callId);
        } else {
          const callData = {
            ...allCallsById[callId],
            ...call,
          };
          allCallsById = {
            ...allCallsById,
            [callId]: callData,
          };
          allCallsIds = unionWith(allCallsIds, [callId], isEqual);
        }
      });
      action.status.parks.forEach((park) => {
        if (park.status === PhoneEnums.QueueStatus.added) {
          allParksById = {
            ...allParksById,
            [park.id]: park,
          };
          allParksIds = unionWith(allParksIds, [park.id], isEqual);
          allCallsIds.forEach((callId) => {
            if (
              allCallsById[callId].number !== park.owner &&
              (extensionsByUsername[allCallsById[callId].username].numbers[
                park.calling
              ] ||
                extensionsByUsername[allCallsById[callId].username].numbers[
                  park.called
                ])
            ) {
              allCallsById = {
                ...allCallsById,
                [callId]: {
                  ...allCallsById[callId],
                  status: PhoneEnums.UserStatuses.parked,
                  timestamp: park.timestamp,
                },
              };
            }
          });
        } else {
          delete allParksById[park.id];
          allParksIds = pull(allParksIds, park.id);
        }
      });
      action.status.queues.forEach((queue) => {
        if (queue.status === PhoneEnums.QueueStatus.added) {
          allQueuesById = {
            ...allQueuesById,
            [queue.id]: queue,
          };
          allQueuesIds = unionWith(allQueuesIds, [queue.id], isEqual);
        } else {
          delete allQueuesById[queue.id];
          allQueuesIds = pull(allQueuesIds, queue.id);
        }
      });
      return {
        ...state,
        status: PhoneEnums.PhoneStatuses.inited,
        extensionsByUsername,
        fetchedUsers: true,
        allCallsById,
        allCallsIds,
        allParksById,
        allParksIds,
        allQueuesById,
        allQueuesIds,
        queues: action.status.queuesData,
      };
    }

    case NETHESIS_PHONE_STATUS_CHANGES_SUCCESS: {
      let extensionsByUsername = { ...state.extensionsByUsername };
      let allCallsById = { ...state.allCallsById };
      let allParksById = { ...state.allParksById };
      let allQueuesById = { ...state.allQueuesById };
      let allCallsIds = [...state.allCallsIds];
      let allParksIds = [...state.allParksIds];
      let allQueuesIds = [...state.allQueuesIds];
      let oldCallsIds = [];
      if (action.status.user) {
        const { user } = action.status;
        const userData = extensionsByUsername[user.username] || {};
        let numbers = userData.numbers || {};
        numbers = { ...numbers, [user.number]: user.status };
        extensionsByUsername = {
          ...extensionsByUsername,
          [user.username]: {
            ...userData,
            dnd: user.dnd,
            numbers,
            pbxSettings: action.status.user.pbxSettings,
          },
        };
        oldCallsIds = state.allCallsIds.filter(
          (callId) =>
            endsWith(callId, `_${action.status.user.username}`) &&
            state.allCallsById[callId].number === action.status.user.number
        );
      }
      if (action.status.calls && action.status.calls.length > 0) {
        /*allCallsById =
          (Object.keys(allCallsById).length > 0 &&
            pickBy(
              state.allCallsById,
              (value, key) => !endsWith(key, `_${action.status.user.username}`)
            )) ||
          {};
        allCallsIds = state.allCallsIds.filter(
          id => !endsWith(id, `_${action.status.user.username}`)
        );*/

        // if user available remove all calls for him
        /* if (
          action.status.user &&
          action.status.user.status === PhoneEnums.UserStatuses.available
        ) {
          allCallsById = pickBy(
            state.allCallsById,
            (value, key) => !endsWith(key, `_${action.status.user.username}`)
          );
          allCallsIds = state.allCallsIds.filter(
            callId => !endsWith(callId, `_${action.status.user.username}`)
          );
        } */

        action.status.calls.forEach((call) => {
          const callId = addUsernameToCallid(call.callid, call.username);
          // FIX for ticket 4713, missing answer/hangup for incoming webrtc call because there is an extenUpdate towards physical with same callid of webrtc and it rewrite it, then physical has a new callid
          if (
            allCallsById[callId] &&
            allCallsById[callId].number !== call.number
          ) {
            return;
          }
          if (call.status !== PhoneEnums.CallsStatuses.none) {
            oldCallsIds = pull(oldCallsIds, callId);
            extensionsByUsername = {
              ...extensionsByUsername,
              [call.username]: {
                ...extensionsByUsername[call.username],
                numbers: {
                  ...extensionsByUsername[call.username].numbers,
                  [call.number]:
                    call.status === PhoneEnums.CallsStatuses.active
                      ? PhoneEnums.UserStatuses.calling
                      : call.status,
                },
              },
            };
            if (
              // eslint-disable-next-line no-constant-condition
              true
              /*(call.conference && call.called !== call.calling) ||
              !call.conference*/
            ) {
              const callData = {
                ...allCallsById[callId],
                ...call,
              };
              if (
                callData.callingData &&
                callData.calling !== callData.callingData.number
              ) {
                delete callData.callingData;
              }
              if (
                callData.calledData &&
                callData.called !== callData.calledData.number
              ) {
                delete callData.calledData;
              }
              allCallsById = {
                ...allCallsById,
                [callId]: callData,
              };
              allCallsIds = unionWith(allCallsIds, [callId], isEqual);
            }
          } else {
            delete allCallsById[callId];
            allCallsIds = pull(allCallsIds, callId);
          }
        });
        oldCallsIds.forEach((oldCallId) => {
          delete allCallsById[oldCallId];
          allCallsIds = pull(allCallsIds, oldCallId);
        });
      } else if (action.status.user) {
        // remove calls for that username and that number
        const { username, number } = action.status.user;
        Object.keys(allCallsById).forEach((callId) => {
          if (
            callId.substring(callId.lastIndexOf('_') + 1) === username &&
            allCallsById[callId].number === number
          ) {
            delete allCallsById[callId];
            allCallsIds = pull(allCallsIds, callId);
          }
        });
      }

      if (action.status.parks && action.status.parks.length > 0) {
        action.status.parks.forEach((park) => {
          if (park.status === PhoneEnums.QueueStatus.added) {
            allParksById = {
              ...allParksById,
              [park.id]: park,
            };
            allParksIds = unionWith(allParksIds, [park.id], isEqual);
            let parkedNumbers = {};
            state.allCallsIds.forEach((callId) => {
              if (
                state.allCallsById[callId] &&
                state.allCallsById[callId].number !== park.owner &&
                (state.extensionsByUsername[state.allCallsById[callId].username]
                  .numbers[park.calling] ||
                  state.extensionsByUsername[
                    state.allCallsById[callId].username
                  ].numbers[park.called])
              ) {
                parkedNumbers =
                  state.extensionsByUsername[
                    state.allCallsById[callId].username
                  ].numbers;

                allCallsById = {
                  ...allCallsById,
                  [callId]: {
                    ...allCallsById[callId],
                    status: PhoneEnums.UserStatuses.parked,
                    timestamp: park.timestamp,
                  },
                };
              }

              // remove calls of the parker (there is an event to erase it but it arrives too late)
              state.allCallsIds.forEach((checkingCallId) => {
                if (
                  allCallsById[checkingCallId] &&
                  allCallsById[checkingCallId].number === park.owner &&
                  (parkedNumbers[allCallsById[checkingCallId].calling] ||
                    parkedNumbers[allCallsById[checkingCallId].called])
                ) {
                  delete allCallsById[checkingCallId];
                  allCallsIds = pull(allCallsIds, checkingCallId);
                }
              });
            });
          } else {
            delete allParksById[park.id];
            allParksIds = pull(allParksIds, park.id);
          }
        });
      }

      if (action.status.queues && action.status.queues.length > 0) {
        action.status.queues.forEach((queue) => {
          if (queue.status === PhoneEnums.QueueStatus.added) {
            allQueuesById = {
              ...allQueuesById,
              [queue.id]: queue,
            };
            allQueuesIds = unionWith(allQueuesIds, [queue.id], isEqual);
          } else {
            delete allQueuesById[queue.id];
            allQueuesIds = pull(allQueuesIds, queue.id);
          }
        });
      }

      return {
        ...state,
        extensionsByUsername,
        fetchedUsers: true,
        allCallsById,
        allCallsIds,
        allParksById,
        allParksIds,
        allQueuesById,
        allQueuesIds,
      };
    }
    case FETCH_INTERLOCUTOR_DATA_SUCCESS: {
      const { context } = action.payload;
      switch (context) {
        case 'park': {
          let { allParksById } = state;
          const parkId = action.payload.data.id;
          allParksById = {
            ...allParksById,
            [parkId]: {
              ...allParksById[parkId],
              callingData: action.payload.data.callingData,
            },
          };
          return {
            ...state,
            allParksById,
          };
        }
        case 'queue': {
          let { allQueuesById } = state;
          const queueId = action.payload.data.id;
          allQueuesById = {
            ...allQueuesById,
            [queueId]: {
              ...allQueuesById[queueId],
              callingData: action.payload.data.callingData,
              calledData: action.payload.data.calledData,
            },
          };
          return {
            ...state,
            allQueuesById,
          };
        }
        case 'calls':
        default: {
          const callData = action.payload.data;
          const callId = addUsernameToCallid(
            callData.callid,
            callData.username
          );
          const { allCallsById } = state;
          let calls = {};
          if (callData) {
            Object.keys(allCallsById).forEach((id) => {
              // it was "if (id.indexOf(callData.callid) > -1) {"
              // changed on 12/08/2019 because it put always the same data in called and in calling
              if (id.indexOf(callId) > -1) {
                calls = {
                  ...calls,
                  [id]: {
                    ...allCallsById[id],
                    callingData: callData.callingData,
                    calledData: callData.calledData,
                  },
                };
              }
            });
          }
          return {
            ...state,
            allCallsById: {
              ...allCallsById,
              ...calls,
            },
          };
        }
      }
    }
    case FETCH_SEARCHED_CONTACTS_REQUEST:
      return {
        ...state,
        searchContacts: {
          ...state.searchContacts,
          data: [],
          loaded: false,
          error: null,
        },
      };
    case FETCH_SEARCHED_CONTACTS_SUCCESS:
      return {
        ...state,
        searchContacts: {
          ...state.searchContacts,
          data: action.contacts.data,
          loaded: true,
          error: null,
          [action.contacts.target]: {
            ...state.searchContacts[action.contacts.target],
            listOpen: action.contacts.data.length > 0,
          },
        },
      };
    case FETCH_SEARCHED_CONTACTS_FAILURE:
      return {
        ...state,
        searchContacts: {
          ...state.searchContacts,
          data: [],
          loaded: true,
          error: action.errors,
          /*filter: {
            ...state.searchContacts.filter,
            target: null
          }*/
        },
      };
    case SET_SEARCH_CONTACTS_FILTER:
      return {
        ...state,
        searchContacts: {
          ...state.searchContacts,
          [action.payload.target]: {
            number:
              action.payload.number !== undefined
                ? action.payload.number
                : state.searchContacts[action.payload.target].number,
            listOpen:
              action.payload.listOpen !== undefined
                ? action.payload.listOpen
                : state.searchContacts[action.payload.target].listOpen,
            selected:
              action.payload.selected !== undefined
                ? action.payload.selected
                : state.searchContacts[action.payload.target].selected,
          },
        },
      };
    case TOGGLE_MUTE_SUCCESS:
      return {
        ...state,
        muted: action.muted,
      };
    case RECORD_CALL_SUCCESS:
      return {
        ...state,
        recording: true,
      };
    case FETCH_DEFAULT_DEVICE_SUCCESS:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          active: action.deviceType === 'webrtc' ? 'ON' : 'OFF',
        },
      };
    case ACTIVATE_WEBRTC_REQUEST:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          using: true,
          error: false,
          incomingCallJsep: null,
          validJsep: false,
          noValidJsepCall: false,
        },
      };
    case ACTIVATE_WEBRTC_SUCCESS:
      return {
        ...state,
        sentStopUseWebphoneLoaded: true,
        webrtc: {
          ...state.webrtc,
          active: 'ON',
          using: true,
          error: false,
          incomingCallJsep: null,
          validJsep: false,
          detached: false,
          noValidJsepCall: false,
          registered: true,
        },
      };
    case ACTIVATE_WEBRTC_FAILURE:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          using: false,
          error: true,
          incomingCallJsep: null,
          validJsep: false,
          noValidJsepCall: false,
          registered: false,
        },
      };
    case DEACTIVATE_WEBRTC_REQUEST:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          error: false,
          incomingCallJsep: null,
          validJsep: false,
          noValidJsepCall: false,
        },
      };
    case DEACTIVATE_WEBRTC_SUCCESS:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          active: state.webrtc.using ? 'OFF' : state.webrtc.active,
          using: false,
          error: false,
          incomingCallJsep: null,
          validJsep: false,
          noValidJsepCall: false,
          registered: false,
        },
      };

    case DEACTIVATE_WEBRTC_FAILURE:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          error: true,
          incomingCallJsep: null,
          validJsep: false,
          noValidJsepCall: false,
        },
      };
    case SPY_CALL_REQUEST:
    case INTRUDE_CALL_REQUEST:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          isSpying: true,
        },
      };
    case SPY_CALL_FAILURE:
    case INTRUDE_CALL_FAILURE:
    case RESET_WEBRTC_SPY:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          isSpying: false,
        },
      };

    case INCOMING_WEBRTC_CALL: {
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          incomingCallJsep: action.data,
          validJsep: true,
          incomingCall: true,
          outgoingCall: false,
          activeCall: false,
          reactivating: false,
        },
      };
    }
    case ACCEPTED_WEBRTC_CALL: {
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          incomingCallJsep: null,
          noSdpCall: false,
          sdpCall: false,
          validJsep: true,
          incomingCall: false,
          outgoingCall: false,
          activeCall: true,
        },
      };
    }
    case OUTGOING_WEBRTC_CALL: {
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          incomingCallJsep: action.data,
          outgoingCall: true,
          incomingCall: false,
          activeCall: false,
        },
      };
    }
    case CHANGE_PHONE_STATUS: {
      let dnd = state.extensionsByUsername[action.mainUsername].dnd;
      if (
        action.pbxSettings &&
        action.pbxSettings.active &&
        action.pbxSettings.status === PhoneRulesEnums.SettingsActions.dnd
      ) {
        dnd = PhoneEnums.DndStatus.ON;
      }
      return {
        ...state,
        extensionsByUsername: {
          ...state.extensionsByUsername,
          [action.mainUsername]: {
            ...state.extensionsByUsername[action.mainUsername],
            dnd,
            pbxSettings: action.pbxSettings,
          },
        },
      };
    }
    case NETHESIS_WEBSOCKET_DISCONNECTED: {
      return {
        ...state,
        status: PhoneEnums.PhoneStatuses.offline,
      };
    }
    case BUSY_WEBRTC_CALL:
      return {
        ...state,
        playbusy: true,
        webrtc: {
          ...state.webrtc,
          noSdpCall: false,
          sdpCall: false,
          validJsep: false,
          incomingCall: false,
          outgoingCall: false,
          activeCall: false,
        },
      };
    case UNSET_CALLED_BUSY:
      return {
        ...state,
        playbusy: false,
      };
    case SEND_STOP_USE_WEBPHONE_REQUEST:
      return {
        ...state,
        sentStopUseWebphoneError: false,
        sentStopUseWebphoneLoaded: false,
      };
    case SEND_STOP_USE_WEBPHONE_SUCCESS:
      return {
        ...state,
        sentStopUseWebphoneError: false,
        // sentStopUseWebphoneLoaded: true,
      };
    case SEND_STOP_USE_WEBPHONE_FAILURE:
      return {
        ...state,
        sentStopUseWebphoneError: true,
        sentStopUseWebphoneLoaded: true,
      };
    case NO_SDP_WEBRTC_CALL:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          noSdpCall: true,
        },
      };
    case SDP_WEBRTC_CALL:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          sdpCall: true,
        },
      };
    case DECLINING_WEBRTC_CALL:
    case HANGUP_WEBRTC_CALL:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          noSdpCall: false,
          sdpCall: false,
          validJsep: false,
          incomingCall: false,
          outgoingCall: false,
          activeCall: false,
        },
      };
    case WEBPHONE_DETACHED:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          detached: true,
        },
      };
    case SET_NO_VALID_JSEP_CALL:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          noValidJsepCall: true,
        },
      };
    case REACTIVATE_WEBRTC_PHONE:
      return {
        ...state,
        webrtc: {
          ...state.webrtc,
          reactivating: true,
        },
      };
    case NETHESIS_ME_PHONE_STATUS_CHANGES_SUCCESS: {
      return {
        ...state,
        muted: action.status.user && action.status.user.status === PhoneEnums.UserStatuses.available ? false : state.muted,
        recording: action.status.user && action.status.user.status === PhoneEnums.UserStatuses.available ? false : state.recording,
        webrtc: {
          ...state.webrtc,
          reactivating: false,
        },
      };
    }
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}

const initialSettingsState = {
  extensions: {
    main: {},
    others: [],
  },
  pbx: {
    fetchPbxRoutesLoaded: true,
    fetchPbxRoutesError: null,
    savePbxRouteLoaded: true,
    savePbxRouteError: null,
    fetchPbxQueuesLoaded: true,
    fetchPbxQueuesError: null,
    savePbxQueueLoaded: true,
    savePbxQueueError: null,
  },
};

export function phoneSettings(state = initialSettingsState, action = {}) {
  switch (action.type) {
    case FETCH_PHONE_SETTINGS_SUCCESS:
      return {
        ...state,
        extensions: {
          ...state.extensions,
          ...action.phoneSettings.extensions,
        },
        pbx: {
          ...state.pbx,
          ...action.phoneSettings.pbx,
        },
      };
    case FETCH_PBX_ROUTES_REQUEST:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          fetchPbxRoutesLoaded: false,
          fetchPbxRoutesError: null,
        },
      };
    case FETCH_PBX_ROUTES_SUCCESS:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          routes: action.routes,
          fetchPbxRoutesLoaded: true,
          fetchPbxRoutesError: null,
        },
      };
    case FETCH_PBX_ROUTES_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          fetchPbxRoutesLoaded: true,
          fetchPbxRoutesError: action.errors,
        },
      };
    case SAVE_PBX_ROUTE_REQUEST:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          savePbxRouteLoaded: false,
          savePbxRouteError: null,
        },
      };
    case SAVE_PBX_ROUTE_SUCCESS:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          savePbxRouteLoaded: true,
          savePbxRouteError: null,
        },
      };
    case SAVE_PBX_ROUTE_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          savePbxRouteLoaded: true,
          savePbxRouteError: action.error,
        },
      };
    case FETCH_PBX_QUEUES_REQUEST:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          fetchPbxQueuesLoaded: false,
          fetchPbxQueuesError: null,
        },
      };
    case FETCH_PBX_QUEUES_SUCCESS:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          queues: action.queues,
          fetchPbxQueuesLoaded: true,
          fetchPbxQueuesError: null,
        },
      };
    case FETCH_PBX_QUEUES_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          fetchPbxQueuesLoaded: true,
          fetchPbxQueuesError: action.errors,
        },
      };
    case SAVE_PBX_QUEUE_REQUEST:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          savePbxQueueLoaded: false,
          savePbxQueueError: null,
        },
      };
    case SAVE_PBX_QUEUE_SUCCESS:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          savePbxQueueLoaded: true,
          savePbxQueueError: null,
        },
      };
    case SAVE_PBX_QUEUE_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          savePbxQueueLoaded: true,
          savePbxQueueError: action.error,
        },
      };
    case LOGOUT_PHONE_SUCCESS:
    case LOGOUT_PHONE_FAILURE:
      return initialSettingsState;
    default:
      return state;
  }
}
