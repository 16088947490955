import { LOCATION_CHANGE } from 'connected-react-router';
import pull from 'lodash.pull';
import {
  PUT_AVATAR_REQUEST,
  PUT_AVATAR_SUCCESS,
  PUT_AVATAR_FAILURE,
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_FAILURE,
  UPLOAD_FILES_SUCCESS,
  REMOVE_FILES_REQUEST,
  COPY_EXISTING_FILES,
  RESET_AVATAR_STATUS,
  UPLOAD_FILES_PROGRESS,
  UPLOAD_FILES_CANCEL,
  UPLOAD_PROGRESS_FILES_REQUEST,
  RESET_TICKETS_FILES,
  IMPORT_FILE_TO_FILEBOX_REQUEST,
  IMPORT_FILE_TO_FILEBOX_SUCCESS,
  IMPORT_FILE_TO_FILEBOX_FAILURE,
  RESET_FILEBOX_UPLOADED_FILES,
  CLEAR_FILEBOX_ERRORS,
  RESET_STATIC_NOTES_UPLOADED_FILES,
} from './types';
import { LOGOUT_SUCCESS } from '../auth/types';

import { FETCH_WIKI_DOCUMENT_SUCCESS } from '../wiki/types';
import {
  SAVE_OWN_GREETING_SUCCESS,
  LOAD_OWN_GREETING_REQUEST,
} from '../vbox/types';
import { DELETE_MAILOFFICE_RR_SUCCESS } from '../mailoffice/types';
import { REGISTER_CALENDAR_APPOINTMENT_SUCCESS } from '../calendar/types';

const initialState = {
  avatar: {
    stored: '',
    loaded: true,
    error: false,
  },
  events: {
    byId: {},
    allIds: [],
    loaded: true,
    error: null,
  },
  appointments: {
    byId: {},
    allIds: [],
    loaded: true,
    error: null,
  },
  wiki: {
    byId: {},
    allIds: [],
  },
  fax: {
    byId: {},
    allIds: [],
  },
  greetingAbilis: {
    byId: {},
    allIds: [],
  },
  greetingNethesis: {
    byId: {},
    allIds: [],
  },
  greetingNethesisPbx: {
    byId: {},
    allIds: [],
  },
  mailoffice: {
    byId: {},
    allIds: [],
  },
  mailofficeRR: {
    byId: {},
    allIds: [],
  },
  logo: {
    byId: {},
    allIds: [],
  },
  chat: {
    byId: {},
    allIds: [],
  },
  tickets: {
    byId: {},
    allIds: [],
  },
  ticketscustom: {
    byId: {},
    allIds: [],
  },
  filebox: {
    byId: {},
    allIds: [],
    loaded: true,
    error: false,
  },
  notes: {
    byId: {},
    allIds: [],
  },
};

export default function files(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case PUT_AVATAR_REQUEST:
      return {
        ...state,
        avatar: {
          stored: '',
          loaded: false,
          error: false,
        },
      };
    case PUT_AVATAR_SUCCESS: {
      return {
        ...state,
        avatar: {
          stored: action.avatar.stored,
          loaded: true,
          error: false,
        },
      };
    }
    case PUT_AVATAR_FAILURE:
      return {
        ...state,
        avatar: {
          stored: '',
          loaded: true,
          error: true,
        },
      };
    case RESET_AVATAR_STATUS:
      return {
        ...state,
        avatar: {
          stored: '',
          loaded: true,
          error: false,
        },
      };
    case UPLOAD_PROGRESS_FILES_REQUEST:
    case UPLOAD_FILES_REQUEST: {
      const oldScope = state[action.payload.scope] || {byId: {}, allIds: []}
      return {
        ...state,
        [action.payload.scope]: {
          ...oldScope,
          byId: {
            ...oldScope.byId,
            [action.payload.id]: {
              data: {
                id: action.payload.id,
                formData: action.payload.formData,
                file: action.payload.file,
                filename: action.payload.filename,
              },
              loaded: false,
              error: null,
              progress: null,
            },
          },
          allIds: [...oldScope.allIds, action.payload.id],
        },
      };
    }
    case UPLOAD_FILES_PROGRESS:
      return {
        ...state,
        [action.payload.scope]: {
          ...state[action.payload.scope],
          byId: {
            ...state[action.payload.scope].byId,
            [action.payload.id]: {
              ...state[action.payload.scope].byId[action.payload.id],
              progress: action.payload.progress,
            },
          },
        },
      };
    case UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        [action.payload.scope]: {
          ...state[action.payload.scope],
          byId: {
            ...state[action.payload.scope].byId,
            [action.payload.id]: {
              ...state[action.payload.scope].byId[action.payload.id],
              data: {
                ...state[action.payload.scope].byId[action.payload.id].data,
                ...action.payload.data,
                id: action.payload.id,
              },
              loaded: true,
              error: null,
              progress: 100,
            },
          },
        },
      };

    case UPLOAD_FILES_FAILURE:
    case UPLOAD_FILES_CANCEL: {
      return {
        ...state,
        [action.payload.scope]: {
          ...state[action.payload.scope],
          byId: {
            ...state[action.payload.scope].byId,
            [action.payload.id]: {
              ...state[action.payload.scope].byId[action.payload.id],
              loaded: true,
              error: action.payload.error,
              progress: -1,
            },
          },
        },
      };
    }
    case REMOVE_FILES_REQUEST: {
      const allIds = [...state[action.payload.scope].allIds];
      const byId = { ...state[action.payload.scope].byId };
      delete byId[action.payload.id];
      pull(allIds, action.payload.id);
      return {
        ...state,
        [action.payload.scope]: {
          byId,
          allIds,
        },
      };
    }
    case FETCH_WIKI_DOCUMENT_SUCCESS: {
      const byId = {};
      action.document.attachments.forEach((attachment) => {
        byId[attachment.stored] = {
          data: {
            ...attachment,
            filename: attachment.name,
            id: attachment.stored,
          },
          loaded: true,
          error: null,
        };
      });
      return {
        ...state,
        wiki: {
          byId,
          allIds: action.document.attachments
            ? action.document.attachments.map((item) => {
                return item.stored;
              })
            : [],
        },
      };
    }
    case COPY_EXISTING_FILES: {
      const byId = {};
      action.payload.files.forEach((file) => {
        byId[file.stored] = {
          data: {
            id: file.stored,
            filename: file.name || file.filename,
            stored: file.stored,
          },
          loaded: true,
          error: null,
        };
      });
      return {
        ...state,
        [action.payload.scope]: {
          byId,
          allIds: action.payload.files.map((file) => file.stored),
        },
      };
    }
    case RESET_TICKETS_FILES:
      return {
        ...state,
        tickets: {
          byId: {},
          allIds: [],
        },
        ticketscustom: {
          byId: {},
          allIds: [],
        },
      };
    case REGISTER_CALENDAR_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointments: {
          byId: {},
          allIds: [],
          loaded: true,
          error: null,
        },
      };
    case SAVE_OWN_GREETING_SUCCESS:
    case LOAD_OWN_GREETING_REQUEST:
      return {
        ...state,
        nethesis: {
          byId: {},
          allIds: [],
        },
        abilis: {
          byId: {},
          allIds: [],
        },
      };
    case LOCATION_CHANGE:
      return {
        ...initialState,
        chat: state.chat,
        filebox: state.filebox,
      };
    case IMPORT_FILE_TO_FILEBOX_REQUEST:
      return {
        ...state,
        filebox: {
          ...state.filebox,
          loaded: false,
          error: false,
        },
      };
    case IMPORT_FILE_TO_FILEBOX_SUCCESS:
      return {
        ...state,
        filebox: {
          ...state.filebox,
          loaded: true,
          error: false,
        },
      };
    case IMPORT_FILE_TO_FILEBOX_FAILURE:
      return {
        ...state,
        filebox: {
          ...state.filebox,
          loaded: true,
          error: action.errors,
        },
      };
    case RESET_FILEBOX_UPLOADED_FILES:
      return {
        ...state,
        filebox: {
          ...state.filebox,
          byId: {},
          allIds: [],
        },
      };
    case RESET_STATIC_NOTES_UPLOADED_FILES:
      return {
        ...state,
        notes: {
          ...state.notes,
          byId: {},
          allIds: [],
        },
      };
    case CLEAR_FILEBOX_ERRORS:
      return {
        ...state,
        filebox: { ...state.filebox, error: false },
      };
    case DELETE_MAILOFFICE_RR_SUCCESS: {
      return {
        ...state,
        mailofficeRR: {
          byId: {},
          allIds: [],
        },
      };
    }
    default:
      return state;
  }
}
