export const FETCH_PHONE_SETTINGS_REQUEST = 'FETCH_PHONE_SETTINGS_REQUEST';
export const FETCH_PHONE_SETTINGS_SUCCESS = 'FETCH_PHONE_SETTINGS_SUCCESS';
export const FETCH_PHONE_SETTINGS_FAILURE = 'FETCH_PHONE_SETTINGS_FAILURE';
export const LOGIN_PHONE_REQUEST = 'LOGIN_PHONE_REQUEST';
export const LOGIN_PHONE_SUCCESS = 'LOGIN_PHONE_SUCCESS';
export const LOGIN_PHONE_FAILURE = 'LOGIN_PHONE_FAILURE';
export const LOGOUT_PHONE_SUCCESS = 'LOGOUT_PHONE_SUCCESS';
export const LOGOUT_PHONE_FAILURE = 'LOGOUT_PHONE_FAILURE';
export const RELOGIN_PHONE_REQUEST = 'RELOGIN_PHONE_REQUEST';
export const TOGGLE_PHONE_DND_REQUEST = 'TOGGLE_PHONE_DND_REQUEST';
export const TOGGLE_PHONE_DND_SUCCESS = 'TOGGLE_PHONE_DND_SUCCESS';
export const TOGGLE_PHONE_DND_FAILURE = 'TOGGLE_PHONE_DND_FAILURE';
export const INITIAL_ABILIS_PHONE_STATUS_SUCCESS =
  'INITIAL_ABILIS_PHONE_STATUS_SUCCESS';
export const INITIAL_NETHESIS_PHONE_STATUS_SUCCESS =
  'INITIAL_NETHESIS_PHONE_STATUS_SUCCESS';
export const INITIAL_PHONE_STATUS_FAILURE = 'INITIAL_PHONE_STATUS_FAILURE';
export const ABILIS_PHONE_STATUS_CHANGES_SUCCESS =
  'ABILIS_PHONE_STATUS_CHANGES_SUCCESS';
export const NETHESIS_PHONE_STATUS_CHANGES_SUCCESS =
  'NETHESIS_PHONE_STATUS_CHANGES_SUCCESS';
export const PHONE_STATUS_CHANGES_FAILURE = 'PHONE_STATUS_CHANGES_FAILURE';
export const EXEC_CALL_REQUEST = 'EXEC_CALL_REQUEST';
export const EXEC_CALL_SUCCESS = 'EXEC_CALL_SUCCESS';
export const EXEC_CALL_FAILURE = 'EXEC_CALL_FAILURE';
export const FETCH_OWN_CALLS_REQUEST = 'FETCH_OWN_CALLS_REQUEST';
export const HANGUP_CALL_REQUEST = 'HANGUP_CALL_REQUEST';
export const HANGUP_CALL_SUCCESS = 'HANGUP_CALL_SUCCESS';
export const HANGUP_CALL_FAILURE = 'HANGUP_CALL_FAILURE';
export const HANGUP_HOLD_CALL_REQUEST = 'HANGUP_HOLD_CALL_REQUEST';
export const HANGUP_HOLD_CALL_SUCCESS = 'HANGUP_HOLD_CALL_SUCCESS';
export const HANGUP_HOLD_CALL_FAILURE = 'HANGUP_HOLD_CALL_FAILURE';
export const HANGUP_CHANNEL_REQUEST = 'HANGUP_CHANNEL_REQUEST';
export const HANGUP_CHANNEL_SUCCESS = 'HANGUP_CHANNEL_SUCCESS';
export const HANGUP_CHANNEL_FAILURE = 'HANGUP_CHANNEL_FAILURE';
export const FETCH_INTERLOCUTOR_DATA_REQUEST =
  'FETCH_INTERLOCUTOR_DATA_REQUEST';
export const FETCH_INTERLOCUTOR_DATA_FAILURE =
  'FETCH_INTERLOCUTOR_DATA_FAILURE';
export const FETCH_INTERLOCUTOR_DATA_SUCCESS =
  'FETCH_INTERLOCUTOR_DATA_SUCCESS';
export const BLIND_TRANSFER_REQUEST = 'BLIND_TRANSFER_REQUEST';
export const BLIND_TRANSFER_SUCCESS = 'BLIND_TRANSFER_SUCCESS';
export const BLIND_TRANSFER_FAILURE = 'BLIND_TRANSFER_FAILURE';
export const QUEUE_TRANSFER_REQUEST = 'QUEUE_TRANSFER_REQUEST';
export const QUEUE_TRANSFER_SUCCESS = 'QUEUE_TRANSFER_SUCCESS';
export const QUEUE_TRANSFER_FAILURE = 'QUEUE_TRANSFER_FAILURE';
export const FETCH_SEARCHED_CONTACTS_REQUEST =
  'FETCH_SEARCHED_CONTACTS_REQUEST';
export const FETCH_SEARCHED_CONTACTS_FAILURE =
  'FETCH_SEARCHED_CONTACTS_FAILURE';
export const FETCH_SEARCHED_CONTACTS_SUCCESS =
  'FETCH_SEARCHED_CONTACTS_SUCCESS';
export const HOLD_CALL_REQUEST = 'HOLD_CALL_REQUEST';
export const HOLD_CALL_SUCCESS = 'HOLD_CALL_SUCCESS';
export const HOLD_CALL_FAILURE = 'HOLD_CALL_FAILURE';
export const AID_TRANSFER_REQUEST = 'AID_TRANSFER_REQUEST';
export const AID_TRANSFER_SUCCESS = 'AID_TRANSFER_SUCCESS';
export const AID_TRANSFER_FAILURE = 'AID_TRANSFER_FAILURE';
export const UNHOLD_CALL_REQUEST = 'UNHOLD_CALL_REQUEST';
export const UNHOLD_CALL_SUCCESS = 'UNHOLD_CALL_SUCCESS';
export const UNHOLD_CALL_FAILURE = 'UNHOLD_CALL_FAILURE';
export const LINK_CALLS_REQUEST = 'LINK_CALLS_REQUEST';
export const LINK_CALLS_SUCCESS = 'LINK_CALLS_SUCCESS';
export const LINK_CALLS_FAILURE = 'LINK_CALLS_FAILURE';
export const PARK_CALL_REQUEST = 'PARK_CALL_REQUEST';
export const PARK_CALL_SUCCESS = 'PARK_CALL_SUCCESS';
export const PARK_CALL_FAILURE = 'PARK_CALL_FAILURE';
export const SWITCH_CALLS_REQUEST = 'SWITCH_CALLS_REQUEST';
export const SWITCH_CALLS_SUCCESS = 'SWITCH_CALLS_SUCCESS';
export const SWITCH_CALLS_FAILURE = 'SWITCH_CALLS_FAILURE';
export const PICKUP_CALL_REQUEST = 'PICKUP_CALL_REQUEST';
export const PICKUP_CALL_SUCCESS = 'PICKUP_CALL_SUCCESS';
export const PICKUP_CALL_FAILURE = 'PICKUP_CALL_FAILURE';
export const PICKUP_PARK_REQUEST = 'PICKUP_PARK_REQUEST';
export const PICKUP_PARK_SUCCESS = 'PICKUP_PARK_SUCCESS';
export const PICKUP_PARK_FAILURE = 'PICKUP_PARK_FAILURE';
export const PICKUP_QUEUE_REQUEST = 'PICKUP_QUEUE_REQUEST';
export const PICKUP_QUEUE_SUCCESS = 'PICKUP_QUEUE_SUCCESS';
export const PICKUP_QUEUE_FAILURE = 'PICKUP_QUEUE_FAILURE';
export const TOGGLE_MUTE_REQUEST = 'TOGGLE_MUTE_REQUEST';
export const TOGGLE_MUTE_SUCCESS = 'TOGGLE_MUTE_SUCCESS';
export const TOGGLE_MUTE_FAILURE = 'TOGGLE_MUTE_FAILURE';
export const ACTIVATE_WEBRTC_REQUEST = 'ACTIVATE_WEBRTC_REQUEST';
export const ACTIVATE_WEBRTC_SUCCESS = 'ACTIVATE_WEBRTC_SUCCESS';
export const ACTIVATE_WEBRTC_FAILURE = 'ACTIVATE_WEBRTC_FAILURE';
export const DEACTIVATE_WEBRTC_REQUEST = 'DEACTIVATE_WEBRTC_REQUEST';
export const DEACTIVATE_WEBRTC_SUCCESS = 'DEACTIVATE_WEBRTC_SUCCESS';
export const DEACTIVATE_WEBRTC_FAILURE = 'DEACTIVATE_WEBRTC_FAILURE';
export const ANSWER_CALL_REQUEST = 'ANSWER_CALL_REQUEST';
export const ANSWER_CALL_SUCCESS = 'ANSWER_CALL_SUCCESS';
export const ANSWER_CALL_FAILURE = 'ANSWER_CALL_FAILURE';
export const DECLINE_CALL_REQUEST = 'DECLINE_CALL_REQUEST';
export const DECLINE_CALL_SUCCESS = 'DECLINE_CALL_SUCCESS';
export const DECLINE_CALL_FAILURE = 'DECLINE_CALL_FAILURE';
export const INCOMING_WEBRTC_CALL = 'INCOMING_WEBRTC_CALL';
export const ACCEPTED_WEBRTC_CALL = 'ACCEPTED_WEBRTC_CALL';
export const RECORD_CALL_REQUEST = 'RECORD_CALL_REQUEST';
export const RECORD_CALL_SUCCESS = 'RECORD_CALL_SUCCESS';
export const RECORD_CALL_FAILURE = 'RECORD_CALL_FAILURE';
export const STOP_RECORD_CALL_REQUEST = 'STOP_RECORD_CALL_REQUEST';
export const STOP_RECORD_CALL_SUCCESS = 'STOP_RECORD_CALL_SUCCESS';
export const SET_SEARCH_CONTACTS_FILTER = 'SET_SEARCH_CONTACTS_FILTER';
export const SEND_DTMF_REQUEST = 'SEND_DTMF_REQUEST';
export const SEND_DTMF_SUCCESS = 'SEND_DTMF_SUCCESS';
export const SEND_DTMF_FAILURE = 'SEND_DTMF_FAILURE';
export const START_CONFERENCE_REQUEST = 'START_CONFERENCE_REQUEST';
export const START_CONFERENCE_SUCCESS = 'START_CONFERENCE_SUCCESS';
export const START_CONFERENCE_FAILURE = 'START_CONFERENCE_FAILURE';
export const END_CONFERENCE_REQUEST = 'END_CONFERENCE_REQUEST';
export const END_CONFERENCE_SUCCESS = 'END_CONFERENCE_SUCCESS';
export const END_CONFERENCE_FAILURE = 'END_CONFERENCE_FAILURE';
export const JOIN_CONFERENCE_REQUEST = 'JOIN_CONFERENCE_REQUEST';
export const JOIN_CONFERENCE_SUCCESS = 'JOIN_CONFERENCE_SUCCESS';
export const JOIN_CONFERENCE_FAILURE = 'JOIN_CONFERENCE_FAILURE';
export const CHANGE_PHONE_STATUS = 'CHANGE_PHONE_STATUS';
export const FETCH_CONFERENCE_REQUEST = 'FETCH_CONFERENCE_REQUEST';
export const FETCH_CONFERENCE_SUCCESS = 'FETCH_CONFERENCE_SUCCESS';
export const FETCH_CONFERENCE_FAILURE = 'FETCH_CONFERENCE_FAILURE';
export const FETCH_MEMBER_DATA_REQUEST = 'FETCH_MEMBER_DATA_REQUEST';
export const FETCH_MEMBER_DATA_SUCCESS = 'FETCH_MEMBER_DATA_SUCCESS';
export const FETCH_MEMBER_DATA_FAILURE = 'FETCH_MEMBER_DATA_FAILURE';
export const HANGUP_USER_CONFERENCE_REQUEST = 'HANGUP_USER_CONFERENCE_REQUEST';
export const HANGUP_USER_CONFERENCE_SUCCESS = 'HANGUP_USER_CONFERENCE_SUCCESS';
export const HANGUP_USER_CONFERENCE_FAILURE = 'HANGUP_USER_CONFERENCE_FAILURE';
export const SPY_CALL_REQUEST = 'SPY_CALL_REQUEST';
export const SPY_CALL_SUCCESS = 'SPY_CALL_SUCCESS';
export const SPY_CALL_FAILURE = 'SPY_CALL_FAILURE';
export const RESET_WEBRTC_SPY = 'RESET_WEBRTC_SPY';
export const INTRUDE_CALL_REQUEST = 'INTRUDE_CALL_REQUEST';
export const INTRUDE_CALL_SUCCESS = 'INTRUDE_CALL_SUCCESS';
export const INTRUDE_CALL_FAILURE = 'INTRUDE_CALL_FAILURE';
export const ENABLE_PHONE_DND = 'ENABLE_PHONE_DND';
export const DISABLE_PHONE_DND = 'DISABLE_PHONE_DND';
export const UNSET_CALLED_BUSY = 'UNSET_CALLED_BUSY';
export const SEND_STOP_USE_WEBPHONE_REQUEST = 'SEND_STOP_USE_WEBPHONE_REQUEST';
export const SEND_STOP_USE_WEBPHONE_SUCCESS = 'SEND_STOP_USE_WEBPHONE_SUCCESS';
export const SEND_STOP_USE_WEBPHONE_FAILURE = 'SEND_STOP_USE_WEBPHONE_FAILURE';
export const STOP_USE_WEBPHONE = 'STOP_USE_WEBPHONE';
export const FETCH_DEFAULT_DEVICE_SUCCESS = 'FETCH_DEFAULT_DEVICE_SUCCESS';
export const STOP_PLAY_WEBPHONE_RING = 'STOP_PLAY_WEBPHONE_RING';
export const DECLINING_WEBRTC_CALL = 'DECLINING_WEBRTC_CALL';
export const HANGUP_WEBRTC_CALL = 'HANGUP_WEBRTC_CALL';
export const BUSY_WEBRTC_CALL = 'BUSY_WEBRTC_CALL';
export const NO_SDP_WEBRTC_CALL = 'NO_SDP_WEBRTC_CALL';
export const SDP_WEBRTC_CALL = 'SDP_WEBRTC_CALL';
export const OUTGOING_WEBRTC_CALL = 'OUTGOING_WEBRTC_CALL';
export const NETHESIS_WEBSOCKET_DISCONNECTED =
  'NETHESIS_WEBSOCKET_DISCONNECTED';
export const WEBPHONE_DISCONNECTED = 'WEBPHONE_DISCONNECTED';
export const WEBPHONE_DETACHED = 'WEBPHONE_DETACHED';
export const REACTIVATE_WEBRTC_PHONE = 'REACTIVATE_WEBRTC_PHONE';
export const SET_NO_VALID_JSEP_CALL = 'SET_NO_VALID_JSEP_CALL';
export const FETCH_PBX_ROUTES_REQUEST = 'FETCH_PBX_ROUTES_REQUEST';
export const FETCH_PBX_ROUTES_SUCCESS = 'FETCH_PBX_ROUTES_SUCCESS';
export const FETCH_PBX_ROUTES_FAILURE = 'FETCH_PBX_ROUTES_FAILURE';
export const SAVE_PBX_ROUTE_REQUEST = 'SAVE_PBX_ROUTE_REQUEST';
export const SAVE_PBX_ROUTE_SUCCESS = 'SAVE_PBX_ROUTE_SUCCESS';
export const SAVE_PBX_ROUTE_FAILURE = 'SAVE_PBX_ROUTE_FAILURE';
export const FETCH_PBX_QUEUES_REQUEST = 'FETCH_PBX_QUEUES_REQUEST';
export const FETCH_PBX_QUEUES_SUCCESS = 'FETCH_PBX_QUEUES_SUCCESS';
export const FETCH_PBX_QUEUES_FAILURE = 'FETCH_PBX_QUEUES_FAILURE';
export const SAVE_PBX_QUEUE_REQUEST = 'SAVE_PBX_QUEUE_REQUEST';
export const SAVE_PBX_QUEUE_SUCCESS = 'SAVE_PBX_QUEUE_SUCCESS';
export const SAVE_PBX_QUEUE_FAILURE = 'SAVE_PBX_QUEUE_FAILURE';
export const RECEIVED_TRUNK_UPDATE = 'RECEIVED_TRUNK_UPDATE';
export const ADD_CONFERENCE_MEMBER_WEBRTC_REQUEST =
  'ADD_CONFERENCE_MEMBER_WEBRTC_REQUEST';
export const ADD_CONFERENCE_MEMBER_WEBRTC_SUCCESS =
  'ADD_CONFERENCE_MEMBER_WEBRTC_SUCCESS';
export const ADD_CONFERENCE_MEMBER_WEBRTC_FAILURE =
  'ADD_CONFERENCE_MEMBER_WEBRTC_FAILURE';
export const TRACE_WEBRTC_TROUBLE = 'TRACE_WEBRTC_TROUBLE';
