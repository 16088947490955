import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import Button from './formControls/Button';
import { saveHideIntroRequest } from '../js/settings/actions';
import ynConf from '../conf';
import LinkButton from './LinkButton';
// import LinkButton from './LinkButton';

const messages = defineMessages({
  title: {
    id: 'IntroModal.title',
    defaultMessage: 'YouNeed news',
  },
  next: {
    id: 'IntroModal.next',
    defaultMessage: 'Next',
  },
  close: {
    id: 'IntroModal.close',
    defaultMessage: 'Close',
  },
  showNoMore: {
    id: 'IntroModal.showNoMore',
    defaultMessage: "Don't show this again",
  },
  step0text0: {
    id: 'IntroModal.step0text0',
    defaultMessage: 'YouNeed never stops improving:',
  },
  step0text1: {
    id: 'IntroModal.step0text1',
    defaultMessage:
      'We have added a new kind of event with new related functionalities: the appointments.',
  },
  step0text2: {
    id: 'IntroModal.step0text2',
    defaultMessage:
      'Now you can customize types or outcomes of appointments, create a new one and register checkin and checkout',
  },
  step0text3: {
    id: 'IntroModal.step0text3',
    defaultMessage: 'Go discover everything!',
  },
  step1text0: {
    id: 'IntroModal.step1text0',
    defaultMessage: 'Also, we have improved other parts of calendar section',
  },
  step1text1: {
    id: 'IntroModal.step1text1',
    defaultMessage:
      "It's now possible to invite external participants or search tasks of other colleagues to find available slots",
  },
  step2text0: {
    id: 'IntroModal.step2text0',
    defaultMessage: 'And we also...',
  },
  step2text1: {
    id: 'IntroModal.step2text1',
    defaultMessage: 'added the sharing feature for adress book contacts',
  },
  step2text2: {
    id: 'IntroModal.step2text2',
    defaultMessage: 'improved chat performances',
  },
  step2text3: {
    id: 'IntroModal.step2text3',
    defaultMessage: 'fixed some bugs',
  },
});

const steps = [
  (strings) => (
    <div className="text-center">
      <div className="h5 font-weight-bolder mb-1 px-4">{strings[0]}</div>
      <img
        src={`${ynConf.publicFolderPath}/ambrosia-thumb-up.png`}
        alt="YouNeed Switchboard"
        height="400"
      />
      <div className="h3 font-weight-bolder mb-1 px-4">{strings[1]}</div>
      <div className="h5 font-weight-bolder">{strings[2]}</div>
    </div>
  ),
  /*(strings) => (
    <div className="text-center">
      <div className="h5 font-weight-bolder pt-4 pb-2">{strings[0]}</div>
      <img
        className="img-fluid"
        src={`${ynConf.publicFolderPath}/ambrosia-at-work.png`}
        alt="YouNeed Working Plans"
        height="400"
      />
      <div className="h5 font-weight-bolder mx-5 pt-4">{strings[1]}</div>
      <div className="h5 font-weight-bolder pb-3">{strings[2]}</div>
    </div>
  ),
  (strings) => (
    <div className="text-center">
      <div className="h5 font-weight-bolder pt-4 pb-2">{strings[0]}</div>
      <img
        className="img-fluid"
        src={`${ynConf.publicFolderPath}/ambrosia-thumb-up.png`}
        alt="YouNeed Working Plans"
        height="400"
      />
      <div className="h5 font-weight-bolder mx-5 pt-4">{strings[1]}</div>
      <div className="h5 font-weight-bolder pb-3">{strings[2]}</div>
    </div>
  ),*/
];

class IntroModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      showNoMore: false,
    };
  }

  nextStep = (e) => {
    e.stopPropagation();
    this.setState({ step: this.state.step + 1 });
  };

  close = (showNews) => {
    const { onClose, hideIntro } = this.props;
    const { showNoMore } = this.state;
    onClose(showNews);
    if (showNoMore) {
      hideIntro();
    }
  };

  handleShowNoMore = (e) => {
    this.setState({ showNoMore: e.target.checked });
  };

  render() {
    const {
      isOpen,
      intl: { formatMessage },
    } = this.props;

    const stepMessages = {
      0: [
        formatMessage(messages.step0text0),
        formatMessage(messages.step0text1),
        formatMessage(messages.step0text2),
        formatMessage(messages.step0text3),
      ],
      1: [
        formatMessage(messages.step1text0),
        formatMessage(messages.step1text1),
      ],
      2: [
        formatMessage(messages.step2text0),
        formatMessage(messages.step2text1),
        formatMessage(messages.step2text2),
        formatMessage(messages.step2text3),
      ],
    };

    return (
      <Modal className="modal-lg" isOpen={isOpen}>
        <ModalHeader>{formatMessage(messages.title)}</ModalHeader>
        <ModalBody>
          {steps[this.state.step](stepMessages[this.state.step])}
          {this.state.step === steps.length - 1 && (
            /*<div className="h5 font-weight-bolder pb-2 text-center">
              <LinkButton onClick={() => this.close(true)}>
                {formatMessage(messages.step0text3)}
              </LinkButton>
            </div>*/
            <div style={{textAlign: 'center', marginTop: '20px'}}>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {steps.length > this.state.step + 1 ? (
            <Button
              color="primary"
              onClick={(e) => this.nextStep(e)}
              text={formatMessage(messages.next)}
            />
          ) : (
            <>
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  id="showNoMore"
                  name="showNoMore"
                  className="custom-control-input"
                  onChange={this.handleShowNoMore}
                  checked={this.state.showNoMore}
                />
                <label className="custom-control-label" htmlFor="showNoMore">
                  {formatMessage(messages.showNoMore)}
                </label>
              </div>
              <Button
                color="primary"
                onClick={() => this.close(false)}
                text={formatMessage(messages.close)}
              />
            </>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

IntroModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default injectIntl(
  connect(null, { hideIntro: saveHideIntroRequest })(IntroModal)
);
