import keyBy from 'lodash.keyby';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  SET_TICKETS_FILTER,
  FETCH_TICKET_STATUS_REQUEST,
  FETCH_TICKET_STATUS_SUCCESS,
  FETCH_TICKET_STATUS_FAILURE,
  FETCH_TICKETS_REQUEST,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKETS_FAILURE,
  INSERT_TICKET_REQUEST,
  INSERT_TICKET_SUCCESS,
  INSERT_TICKET_FAILURE,
  FETCH_CUSTOMIZED_QUESTIONS_REQUEST,
  FETCH_CUSTOMIZED_QUESTIONS_SUCCESS,
  FETCH_CUSTOMIZED_QUESTIONS_FAILURE,
  FETCH_TICKET_DETAILS_REQUEST,
  FETCH_TICKET_DETAILS_SUCCESS,
  FETCH_TICKET_DETAILS_FAILURE,
  TOGGLE_TREE_EDIT_MODE,
  SAVE_TREE_QUESTION_REQUEST,
  SAVE_TREE_QUESTION_SUCCESS,
  SAVE_TREE_QUESTION_FAILURE,
  FETCH_TICKETS_TREE_REQUEST,
  FETCH_TICKETS_TREE_SUCCESS,
  FETCH_TICKETS_TREE_FAILURE,
  SAVE_TICKETS_TREE_REQUEST,
  SAVE_TICKETS_TREE_SUCCESS,
  SAVE_TICKETS_TREE_FAILURE,
  DELETE_TREE_QUESTION_REQUEST,
  DELETE_TREE_QUESTION_SUCCESS,
  DELETE_TREE_QUESTION_FAILURE,
  DELETE_TREE_CHOICE_SUCCESS,
  DELETE_TREE_CHOICE_FAILURE,
  DELETE_TREE_CHOICE_REQUEST,
  SAVE_TREE_CHOICE_FAILURE,
  SAVE_TREE_CHOICE_SUCCESS,
  SAVE_TREE_CHOICE_REQUEST,
  CLOSE_TICKET_REQUEST,
  CLOSE_TICKET_SUCCESS,
  CLOSE_TICKET_FAILURE,
  CLEAR_TICKET_ACTIONS_ERRORS,
  DELETE_TICKETS_TREE_REQUEST,
  DELETE_TICKETS_TREE_SUCCESS,
  DELETE_TICKETS_TREE_FAILURE,
  ASSIGN_TICKET_REQUEST,
  ASSIGN_TICKET_SUCCESS,
  ASSIGN_TICKET_FAILURE,
  TAKE_TICKET_REQUEST,
  TAKE_TICKET_SUCCESS,
  TAKE_TICKET_FAILURE,
  WORK_TICKET_REQUEST,
  WORK_TICKET_SUCCESS,
  WORK_TICKET_FAILURE,
  SUSPEND_TICKET_REQUEST,
  SUSPEND_TICKET_SUCCESS,
  SUSPEND_TICKET_FAILURE,
  SHARE_TICKET_REQUEST,
  SHARE_TICKET_SUCCESS,
  SHARE_TICKET_FAILURE,
  UNSHARE_TICKET_REQUEST,
  UNSHARE_TICKET_SUCCESS,
  UNSHARE_TICKET_FAILURE,
  DELETE_TICKET_REQUEST,
  DELETE_TICKET_SUCCESS,
  DELETE_TICKET_FAILURE,
  CHANGE_TICKET_STATUS_REQUEST,
  CHANGE_TICKET_STATUS_SUCCESS,
  CHANGE_TICKET_STATUS_FAILURE,
  INSERT_TICKET_STATUS_REQUEST,
  INSERT_TICKET_STATUS_SUCCESS,
  INSERT_TICKET_STATUS_FAILURE,
  EDIT_TICKET_STATUS_REQUEST,
  EDIT_TICKET_STATUS_SUCCESS,
  EDIT_TICKET_STATUS_FAILURE,
  DELETE_TICKET_STATUS_REQUEST,
  DELETE_TICKET_STATUS_SUCCESS,
  DELETE_TICKET_STATUS_FAILURE,
  CLEAR_TICKET_STATUS_ERRORS,
  FETCH_TICKET_CATEGORIES_REQUEST,
  FETCH_TICKET_CATEGORIES_SUCCESS,
  FETCH_TICKET_CATEGORIES_FAILURE,
  INSERT_TICKET_CATEGORY_REQUEST,
  INSERT_TICKET_CATEGORY_SUCCESS,
  INSERT_TICKET_CATEGORY_FAILURE,
  CLEAR_TICKET_CATEGORY_ERRORS,
  EDIT_TICKET_CATEGORY_REQUEST,
  EDIT_TICKET_CATEGORY_SUCCESS,
  EDIT_TICKET_CATEGORY_FAILURE,
  DELETE_TICKET_CATEGORY_REQUEST,
  DELETE_TICKET_CATEGORY_SUCCESS,
  DELETE_TICKET_CATEGORY_FAILURE,
  EDIT_TICKET_REQUEST,
  EDIT_TICKET_SUCCESS,
  EDIT_TICKET_FAILURE,
  CLEAR_EDIT_TICKET_ERRORS,
  FETCH_TICKET_SETTINGS_REQUEST,
  FETCH_TICKET_SETTINGS_SUCCESS,
  FETCH_TICKET_SETTINGS_FAILURE,
  UPDATE_TICKET_SETTINGS_REQUEST,
  UPDATE_TICKET_SETTINGS_SUCCESS,
  UPDATE_TICKET_SETTINGS_FAILURE,
  FETCH_TICKET_DASHBOARD_HEADER_REQUEST,
  FETCH_TICKET_DASHBOARD_HEADER_SUCCESS,
  FETCH_TICKET_DASHBOARD_HEADER_FAILURE,
  FETCH_TICKET_DASHBOARD_PROGRESS_BAR_REQUEST,
  FETCH_TICKET_DASHBOARD_PROGRESS_BAR_SUCCESS,
  FETCH_TICKET_DASHBOARD_PROGRESS_BAR_FAILURE,
  FETCH_TICKET_DASHBOARD_USERS_STATISTICS_REQUEST,
  FETCH_TICKET_DASHBOARD_USERS_STATISTICS_SUCCESS,
  FETCH_TICKET_DASHBOARD_USERS_STATISTICS_FAILURE,
  FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_REQUEST,
  FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_SUCCESS,
  FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_FAILURE,
  FETCH_TICKETS_LIST_SHORTCUT_REQUEST,
  FETCH_TICKETS_LIST_SHORTCUT_SUCCESS,
  FETCH_TICKETS_LIST_SHORTCUT_FAILURE,
  CHANGE_CATEGORY_REQUEST,
  CHANGE_CATEGORY_SUCCESS,
  CHANGE_CATEGORY_FAILURE,
  FETCH_TICKETS_FOR_EXCEL_REQUEST,
  FETCH_TICKETS_FOR_EXCEL_SUCCESS,
  FETCH_TICKETS_FOR_EXCEL_FAILURE,
  REOPEN_TICKET_REQUEST,
  REOPEN_TICKET_SUCCESS,
  REOPEN_TICKET_FAILURE,
  SET_TICKETS_LIST_ORDER,
  UNASSIGN_TICKET_REQUEST,
  FETCH_TICKETS_GROUP_REQUEST,
  FETCH_TICKETS_GROUP_SUCCESS,
  FETCH_TICKETS_GROUP_FAILURE,
  UNASSIGN_TICKET_SUCCESS,
  UNASSIGN_TICKET_FAILURE,
} from './types';
import { TicketEnums } from './TicketUtils';

const initialFilter = {
  status: [],
  openStart: null,
  openEnd: null,
  owner: null,
  assigned: null,
  abook: null,
  id: null,
  priority: [],
  expired: false,
  categories: [],
  showInternalTickets: true,
  showExternalTickets: true,
};

const initialstate = {
  fetchTicketStatusLoaded: false,
  fetchTicketStatusError: false,
  ticketStatusById: {},
  ticketStatusAllIds: [],
  filter: initialFilter,
  order: {
    type: TicketEnums.orderType.CREATION,
    direction: TicketEnums.orderDirection.DESC,
  },
  ids: [],
  byId: {},
  fetchTicketSearched: false,
  fetchTicketsLoaded: true,
  fetchTicketsError: false,
  fetchTicketsGroupLoaded: true,
  fetchTicketsGroupError: false,
  ticketsGroup: [],
  total: null,
  lastRoute: [],
  saveTicketTree: {
    loadingParent: null,
    error: false,
    questionsByChoiceParent: {},
    questionsById: {},
  },
  saveTicketLoaded: true,
  saveTicketError: false,
  fetchTicketDetailsLoaded: true,
  fetchTicketDetailsError: false,
  fetchTicketsTreeLoaded: true,
  fetchTicketsTreeError: false,
  saveTicketsTreeLoaded: true,
  saveTicketsTreeError: false,
  deleteTicketsTreeLoaded: true,
  deleteTicketsTreeError: false,
  saveTreeQuestionLoaded: true,
  saveTreeQuestionError: false,
  deleteTreeQuestionLoaded: true,
  deleteTreeQuestionError: false,
  saveTreeChoiceLoaded: true,
  saveTreeChoiceError: false,
  deleteTreeChoiceLoaded: true,
  deleteTreeChoiceError: false,
  customTree: {
    treeId: null,
    isDraft: false,
    isEditing: false,
    questionsById: {},
    questionsByChoiceParent: {
      0: [],
    },
  },
  loadedSaving: true,
  errorSaving: false,
  actionSaved: false,
  loadedDelete: null,
  errorDelete: false,
  insertTicketStatusLoaded: true,
  insertTicketStatusError: false,
  loadedDeleteStatus: null,
  errorDeleteStatus: false,
  fetchTicketCategoriesLoaded: true,
  fetchTicketCategoriesError: false,
  ticketCategoriesAllIds: [],
  ticketCategoriesById: {},
  insertTicketCategoryLoaded: true,
  insertTicketCategoryError: false,
  loadedDeleteCategory: null,
  errorDeleteCategory: false,
  ticketSettings: {},
  ticketSettingsLoaded: true,
  ticketSettingsError: false,
  updateTicketSettingsLoaded: true,
  updateTicketSettingsError: false,
  updateTicketSettingsSuccess: false,
  dashboardHeader: [],
  dashboardHeaderLoaded: true,
  dashboardHeaderError: false,
  dashboardProgressBar: {},
  dashboardProgressBarLoaded: true,
  dashboardProgressBarError: false,
  dashboardUsersNumbers: {},
  dashboardUsersProgressBar: {},
  dashboardUsersStatisticsLoaded: true,
  dashboardUsersStatisticsError: false,
  dashboardGraphStatistics: {},
  dashboardGraphStatisticsLoaded: true,
  dashboardGraphStatisticsError: false,
  ticketsListShortcut: {},
  ticketsListShortcutLoaded: true,
  ticketsListShortcutError: false,
  excelTicketsIds: [],
  fetchTicketForExcelLoaded: true,
  fetchTicketForExcelError: false,
  excelTicketsTotal: null,
};

export default function tickets(state = initialstate, action = {}) {
  switch (action.type) {
    case SET_TICKETS_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...initialFilter,
          ...action.payload,
          page: 0,
        },
      };
    case FETCH_TICKET_STATUS_REQUEST:
      return {
        ...state,
        fetchTicketStatusLoaded: false,
        fetchTicketStatusError: false,
      };
    case FETCH_TICKET_STATUS_SUCCESS:
      return {
        ...state,
        fetchTicketStatusLoaded: true,
        fetchTicketStatusError: false,
        ticketStatusById: keyBy(action.data, (status) => status.id),
        ticketStatusAllIds: action.data.map((status) => status.id),
        errorDeleteStatus: false,
      };
    case FETCH_TICKET_STATUS_FAILURE:
      return {
        ...state,
        fetchTicketStatusLoaded: true,
        fetchTicketStatusError: true,
      };
    case FETCH_TICKETS_REQUEST:
      return {
        ...state,
        fetchTicketsSearched: true,
        fetchTicketsLoaded: false,
        fetchTicketsError: false,
      };
    case FETCH_TICKETS_SUCCESS:
      return {
        ...state,
        fetchTicketsLoaded: true,
        fetchTicketsError: false,
        byId: keyBy(action.data.data, 'id'),
        ids: action.data.data.map((ticket) => ticket.id),
        total: action.data.total,
        actionSaved: false,
        filter: {
          ...state.filter,
          page: action.data.page,
        },
      };
    case FETCH_TICKETS_FAILURE:
      return {
        ...state,
        fetchTicketsLoaded: true,
        fetchTicketsError: true,
      };
    case FETCH_TICKETS_GROUP_REQUEST:
      return {
        ...state,
        fetchTicketsGroupLoaded: false,
        fetchTicketsGroupError: false,
      };
    case FETCH_TICKETS_GROUP_SUCCESS:
      return {
        ...state,
        fetchTicketsGroupLoaded: true,
        fetchTicketsGroupError: false,
        ticketsGroup: action.data,
      };
    case FETCH_TICKETS_GROUP_FAILURE:
      return {
        ...state,
        fetchTicketsGroupLoaded: true,
        fetchTicketsGroupError: true,
      };
    case INSERT_TICKET_REQUEST:
      return {
        ...state,
        saveTicketLoaded: false,
        saveTicketError: false,
      };
    case INSERT_TICKET_SUCCESS:
      return {
        ...state,
        saveTicketLoaded: true,
        saveTicketError: false,
      };
    case INSERT_TICKET_FAILURE:
      return {
        ...state,
        saveTicketLoaded: true,
        saveTicketError: action.error,
      };

    case FETCH_CUSTOMIZED_QUESTIONS_REQUEST:
      return {
        ...state,
        saveTicketTree: {
          ...state.saveTicketTree,
          loadingParent: action.params.choiceParent || 0,
          error: false,
        },
      };
    case FETCH_CUSTOMIZED_QUESTIONS_SUCCESS: {
      return {
        ...state,
        saveTicketTree: {
          ...state.saveTicketTree,
          loadingParent: null,
          error: false,
          questionsByChoiceParent: {
            ...state.saveTicketTree.questionsByChoiceParent,
            [action.params.choiceParent || 0]: action.data.map(
              (question) => question.questionId
            ),
          },
          questionsById: {
            ...state.saveTicketTree.questionsById,
            ...keyBy(action.data, 'questionId'),
          },
        },
      };
    }
    case FETCH_CUSTOMIZED_QUESTIONS_FAILURE:
      return {
        ...state,
        saveTicketTree: {
          ...state.saveTicketTree,
          loadingParent: null,
          error: true,
        },
      };
    case FETCH_TICKET_DETAILS_REQUEST:
      return {
        ...state,
        fetchTicketDetailsLoaded: false,
        fetchTicketDetailsError: false,
      };
    case FETCH_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        fetchTicketDetailsLoaded: true,
        fetchTicketDetailsError: false,
        byId: {
          ...state.byId,
          [action.data.id]: {
            ...state.byId[action.data.id],
            ...action.data,
          },
        },
      };
    }
    case FETCH_TICKET_DETAILS_FAILURE:
      return {
        ...state,
        fetchTicketDetailsLoaded: true,
        fetchTicketDetailsError: action.error,
      };

    case FETCH_TICKETS_TREE_REQUEST:
      return {
        ...state,
        fetchTicketsTreeLoaded: false,
        fetchTicketsTreeError: false,
      };
    case FETCH_TICKETS_TREE_SUCCESS:
      return {
        ...state,
        fetchTicketsTreeLoaded: true,
        fetchTicketsTreeError: false,
        saveTicketsTreeLoaded: true,
        customTree: {
          ...state.customTree,
          treeId: action.data.treeId || state.customTree.treeId,
          isDraft: action.data.draft,
          questionsById: {
            ...state.customTree.questionsById,
            ...keyBy(action.data.questions, 'questionId'),
          },
          questionsByChoiceParent: {
            ...state.customTree.questionsByChoiceParent,
            [action.data.choiceParent || 0]: action.data.questions
              ? action.data.questions.map((obj) => obj.questionId)
              : [],
          },
        },
      };
    case FETCH_TICKETS_TREE_FAILURE:
      return {
        ...state,
        fetchTicketsTreeLoaded: true,
        fetchTicketsTreeError: action.errors,
      };
    case SAVE_TICKETS_TREE_REQUEST:
      return {
        ...state,
        saveTicketsTreeError: false,
        saveTicketsTreeLoaded: false,
      };
    case SAVE_TICKETS_TREE_SUCCESS:
      return {
        ...state,
        saveTicketsTreeLoaded: false,
        saveTicketsTreeError: false,
        customTree: {
          ...state.customTree,
          treeId: action.data.id,
          isDraft: action.data.isDraft,
          //isEditing: action.data.isDraft,
        },
      };
    case SAVE_TICKETS_TREE_FAILURE:
      return {
        ...state,
        saveTicketsTreeLoaded: true,
        saveTicketsTreeError: action.errors,
      };
    case DELETE_TICKETS_TREE_REQUEST:
      return {
        ...state,
        deleteTicketsTreeLoaded: false,
        deleteTicketsTreeError: false,
      };
    case DELETE_TICKETS_TREE_SUCCESS:
      return {
        ...state,
        deleteTicketsTreeLoaded: true,
        deleteTicketsTreeError: false,
        customTree: {
          ...state.customTree,
          isEditing: false,
        },
      };
    case DELETE_TICKETS_TREE_FAILURE:
      return {
        ...state,
        deleteTicketsTreeLoaded: true,
        deleteTicketsTreeError: action.errors,
      };
    case TOGGLE_TREE_EDIT_MODE:
      return {
        ...state,
        customTree: {
          ...state.customTree,
          isEditing: action.editing,
        },
      };
    case SAVE_TREE_QUESTION_REQUEST:
      return {
        ...state,
        saveTreeQuestionLoaded: false,
        saveTreeQuestionError: false,
      };
    case SAVE_TREE_QUESTION_SUCCESS:
      return {
        ...state,
        saveTreeQuestionLoaded: true,
        saveTreeQuestionError: false,
      };
    case SAVE_TREE_QUESTION_FAILURE:
      return {
        ...state,
        saveTreeQuestionLoaded: true,
        saveTreeQuestionError: action.errors,
      };
    case DELETE_TREE_QUESTION_REQUEST:
      return {
        ...state,
        deleteTreeQuestionLoaded: false,
        deleteTreeQuestionError: false,
      };
    case DELETE_TREE_QUESTION_SUCCESS:
      return {
        ...state,
        deleteTreeQuestionLoaded: true,
        deleteTreeQuestionError: false,
      };
    case DELETE_TREE_QUESTION_FAILURE:
      return {
        ...state,
        deleteTreeQuestionLoaded: true,
        deleteTreeQuestionError: action.errors,
      };
    case SAVE_TREE_CHOICE_REQUEST:
      return {
        ...state,
        saveTreeChoiceLoaded: false,
        saveTreeChoiceError: false,
      };
    case SAVE_TREE_CHOICE_SUCCESS:
      return {
        ...state,
        saveTreeChoiceLoaded: true,
        saveTreeChoiceError: false,
      };
    case SAVE_TREE_CHOICE_FAILURE:
      return {
        ...state,
        saveTreeChoiceLoaded: true,
        saveTreeChoiceError: action.error,
      };
    case DELETE_TREE_CHOICE_REQUEST:
      return {
        ...state,
        deleteTreeChoiceLoaded: false,
        deleteTreeQuestionError: false,
      };
    case DELETE_TREE_CHOICE_SUCCESS:
      return {
        ...state,
        deleteTreeChoiceLoaded: true,
        deleteTreeChoiceError: false,
      };
    case DELETE_TREE_CHOICE_FAILURE:
      return {
        ...state,
        deleteTreeChoiceLoaded: true,
        deleteTreeChoiceError: action.error,
      };
    case CLOSE_TICKET_REQUEST:
    case ASSIGN_TICKET_REQUEST:
    case UNASSIGN_TICKET_REQUEST:
    case TAKE_TICKET_REQUEST:
    case SUSPEND_TICKET_REQUEST:
    case CHANGE_TICKET_STATUS_REQUEST:
    case CHANGE_CATEGORY_REQUEST:
    case REOPEN_TICKET_REQUEST:
      return {
        ...state,
        loadedSaving: false,
        errorSaving: false,
      };
    case CLOSE_TICKET_SUCCESS:
    case ASSIGN_TICKET_SUCCESS:
    case TAKE_TICKET_SUCCESS:
    case SUSPEND_TICKET_SUCCESS:
    case CHANGE_TICKET_STATUS_SUCCESS:
    case CHANGE_CATEGORY_SUCCESS:
    case REOPEN_TICKET_SUCCESS:
    case UNASSIGN_TICKET_SUCCESS:
      return {
        ...state,
        loadedSaving: true,
        errorSaving: false,
        actionSaved: true,
      };
    case CLOSE_TICKET_FAILURE:
    case ASSIGN_TICKET_FAILURE:
    case TAKE_TICKET_FAILURE:
    case SUSPEND_TICKET_FAILURE:
    case CHANGE_TICKET_STATUS_FAILURE:
    case CHANGE_CATEGORY_FAILURE:
    case REOPEN_TICKET_FAILURE:
    case UNASSIGN_TICKET_FAILURE:
      return {
        ...state,
        loadedSaving: true,
        errorSaving: action.error,
      };
    case WORK_TICKET_REQUEST:
    case SHARE_TICKET_REQUEST:
    case UNSHARE_TICKET_REQUEST:
      return {
        ...state,
        loadedSaving: false,
        errorSaving: false,
      };
    case WORK_TICKET_SUCCESS:
    case SHARE_TICKET_SUCCESS:
    case UNSHARE_TICKET_SUCCESS:
      return {
        ...state,
        loadedSaving: true,
        errorSaving: false,
        // actionSaved: true,  /* To be checked later if the tickets list needs refreshing even after WORK, SHARE or UNSHARE action */
      };
    case WORK_TICKET_FAILURE:
    case SHARE_TICKET_FAILURE:
    case UNSHARE_TICKET_FAILURE:
      return {
        ...state,
        loadedSaving: true,
        errorSaving: action.error,
      };
    case DELETE_TICKET_REQUEST:
      return {
        ...state,
        loadedDelete: action.payload.id,
        errorDelete: false,
      };
    case DELETE_TICKET_SUCCESS:
      return {
        ...state,
        loadedDelete: null,
        errorDelete: false,
      };
    case DELETE_TICKET_FAILURE:
      return {
        ...state,
        loadedDelete: null,
        errorDelete: action.error,
      };
    case CLEAR_TICKET_ACTIONS_ERRORS:
      return {
        ...state,
        errorSaving: false,
      };
    case EDIT_TICKET_STATUS_REQUEST:
    case INSERT_TICKET_STATUS_REQUEST:
      return {
        ...state,
        insertTicketStatusLoaded: false,
        insertTicketStatusError: false,
      };
    case EDIT_TICKET_STATUS_SUCCESS:
    case INSERT_TICKET_STATUS_SUCCESS:
      return {
        ...state,
        insertTicketStatusLoaded: true,
        insertTicketStatusError: false,
      };
    case EDIT_TICKET_STATUS_FAILURE:
    case INSERT_TICKET_STATUS_FAILURE:
      return {
        ...state,
        insertTicketStatusLoaded: true,
        insertTicketStatusError: action.error,
      };
    case DELETE_TICKET_STATUS_REQUEST:
      return {
        ...state,
        loadedDeleteStatus: action.payload.id,
        errorDeleteStatus: false,
      };
    case DELETE_TICKET_STATUS_SUCCESS:
      return {
        ...state,
        loadedDeleteStatus: null,
        errorDeleteStatus: false,
      };
    case DELETE_TICKET_STATUS_FAILURE:
      return {
        ...state,
        loadedDeleteStatus: null,
        errorDeleteStatus: action.error,
      };
    case CLEAR_TICKET_STATUS_ERRORS:
      return {
        ...state,
        insertTicketStatusLoaded: true,
        insertTicketStatusError: false,
      };
    case FETCH_TICKET_CATEGORIES_REQUEST:
      return {
        ...state,
        fetchTicketCategoriesLoaded: false,
        fetchTicketCategoriesError: false,
      };
    case FETCH_TICKET_CATEGORIES_SUCCESS:
      return {
        ...state,
        fetchTicketCategoriesLoaded: true,
        fetchTicketCategoriesError: false,
        ticketCategoriesById: keyBy(action.data, (status) => status.id),
        ticketCategoriesAllIds: action.data.map((status) => status.id),
      };
    case FETCH_TICKET_CATEGORIES_FAILURE:
      return {
        ...state,
        fetchTicketCategoriesLoaded: true,
        fetchTicketCategoriesError: true,
      };
    case INSERT_TICKET_CATEGORY_REQUEST:
    case EDIT_TICKET_CATEGORY_REQUEST:
      return {
        ...state,
        insertTicketCategoryLoaded: false,
        insertTicketCategoryError: false,
      };
    case INSERT_TICKET_CATEGORY_SUCCESS:
    case EDIT_TICKET_CATEGORY_SUCCESS:
      return {
        ...state,
        insertTicketCategoryLoaded: true,
        insertTicketCategoryError: false,
      };
    case INSERT_TICKET_CATEGORY_FAILURE:
    case EDIT_TICKET_CATEGORY_FAILURE:
      return {
        ...state,
        insertTicketCategoryLoaded: true,
        insertTicketCategoryError: action.error,
      };
    case DELETE_TICKET_CATEGORY_REQUEST:
      return {
        ...state,
        loadedDeleteCategory: action.payload.id,
        errorDeleteCategory: false,
      };
    case DELETE_TICKET_CATEGORY_SUCCESS:
      return {
        ...state,
        loadedDeleteCategory: null,
        errorDeleteCategory: false,
      };
    case DELETE_TICKET_CATEGORY_FAILURE:
      return {
        ...state,
        loadedDeleteCategory: null,
        errorDeleteCategory: action.error,
      };
    case CLEAR_TICKET_CATEGORY_ERRORS:
      return {
        ...state,
        insertTicketCategoryLoaded: true,
        insertTicketCategoryError: false,
        errorDeleteCategory: false,
      };
    case EDIT_TICKET_REQUEST:
      return {
        ...state,
        saveTicketLoaded: false,
        saveTicketError: false,
      };
    case EDIT_TICKET_SUCCESS:
      return {
        ...state,
        saveTicketLoaded: true,
        saveTicketError: false,
      };
    case EDIT_TICKET_FAILURE:
      return {
        ...state,
        saveTicketLoaded: true,
        saveTicketError: action.error,
      };
    case CLEAR_EDIT_TICKET_ERRORS:
      return {
        ...state,
        saveTicketError: false,
        fetchTicketDetailsError: false,
      };
    case FETCH_TICKET_SETTINGS_REQUEST:
      return {
        ...state,
        ticketSettingsLoaded: false,
        ticketSettingsError: false,
        updateTicketSettingsSuccess: false,
        updateTicketSettingsError: false,
      };
    case FETCH_TICKET_SETTINGS_SUCCESS:
      return {
        ...state,
        ticketSettingsLoaded: true,
        ticketSettingsError: false,
        ticketSettings: action.data,
      };
    case FETCH_TICKET_SETTINGS_FAILURE:
      return {
        ...state,
        ticketSettingsLoaded: true,
        ticketSettingsError: action.error,
      };
    case UPDATE_TICKET_SETTINGS_REQUEST:
      return {
        ...state,
        updateTicketSettingsLoaded: false,
        updateTicketSettingsSuccess: false,
        updateTicketSettingsError: false,
      };
    case UPDATE_TICKET_SETTINGS_SUCCESS:
      return {
        ...state,
        updateTicketSettingsLoaded: true,
        updateTicketSettingsSuccess: true,
        updateTicketSettingsError: false,
      };
    case UPDATE_TICKET_SETTINGS_FAILURE:
      return {
        ...state,
        updateTicketSettingsLoaded: true,
        updateTicketSettingsSuccess: false,
        updateTicketSettingsError: action.error,
      };
    case FETCH_TICKET_DASHBOARD_HEADER_REQUEST:
      return {
        ...state,
        dashboardHeaderLoaded: false,
        dashboardHeaderError: false,
      };
    case FETCH_TICKET_DASHBOARD_HEADER_SUCCESS:
      return {
        ...state,
        dashboardHeader: action.data,
        dashboardHeaderLoaded: true,
        dashboardHeaderError: false,
      };
    case FETCH_TICKET_DASHBOARD_HEADER_FAILURE:
      return {
        ...state,
        dashboardHeaderLoaded: true,
        dashboardHeaderError: action.error,
      };
    case FETCH_TICKET_DASHBOARD_PROGRESS_BAR_REQUEST:
      return {
        ...state,
        dashboardProgressBarLoaded: false,
        dashboardProgressBarError: false,
      };
    case FETCH_TICKET_DASHBOARD_PROGRESS_BAR_SUCCESS:
      return {
        ...state,
        dashboardProgressBar: action.data,
        dashboardProgressBarLoaded: true,
        dashboardProgressBarError: false,
      };
    case FETCH_TICKET_DASHBOARD_PROGRESS_BAR_FAILURE:
      return {
        ...state,
        dashboardProgressBarLoaded: true,
        dashboardProgressBarError: action.error,
      };
    case FETCH_TICKET_DASHBOARD_USERS_STATISTICS_REQUEST:
      return {
        ...state,
        dashboardUsersStatisticsLoaded: false,
        dashboardUsersStatisticsError: false,
      };
    case FETCH_TICKET_DASHBOARD_USERS_STATISTICS_SUCCESS: {
      const { totalClosed, totalAssigned, ...usersNumbers } = action.data;
      return {
        ...state,
        dashboardUsersNumbers: usersNumbers,
        dashboardUsersProgressBar: {
          totalClosed,
          totalAssigned,
        },
        dashboardUsersStatisticsLoaded: true,
        dashboardUsersStatisticsError: false,
      };
    }
    case FETCH_TICKET_DASHBOARD_USERS_STATISTICS_FAILURE:
      return {
        ...state,
        dashboardUsersStatisticsLoaded: true,
        dashboardUsersStatisticsError: action.error,
      };
    case FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_REQUEST:
      return {
        ...state,
        dashboardGraphStatisticsLoaded: true,
        dashboardGraphStatisticsError: false,
      };
    case FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_SUCCESS: {
      return {
        ...state,
        dashboardGraphStatistics: action.data,
        dashboardGraphStatisticsLoaded: true,
        dashboardGraphStatisticsError: false,
      };
    }
    case FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_FAILURE:
      return {
        ...state,
        dashboardGraphStatisticsLoaded: true,
        dashboardGraphStatisticsError: false,
      };

    case FETCH_TICKETS_LIST_SHORTCUT_REQUEST:
      return {
        ...state,
        ticketsListShortcutLoaded: true,
        ticketsListShortcutError: false,
      };
    case FETCH_TICKETS_LIST_SHORTCUT_SUCCESS: {
      return {
        ...state,
        ticketsListShortcut: action.data,
        ticketsListShortcutLoaded: true,
        ticketsListShortcutError: false,
      };
    }
    case FETCH_TICKETS_LIST_SHORTCUT_FAILURE:
      return {
        ...state,
        ticketsListShortcutLoaded: true,
        ticketsListShortcutError: action.error,
      };
    case FETCH_TICKETS_FOR_EXCEL_REQUEST:
      return {
        ...state,
        fetchTicketForExcelLoaded: false,
        fetchTicketForExcelError: false,
      };
    case FETCH_TICKETS_FOR_EXCEL_SUCCESS:
      return {
        ...state,
        fetchTicketForExcelLoaded: true,
        fetchTicketForExcelError: false,
        byId: {
          ...state.byId,
          ...keyBy(action.data.data, 'id'),
        },
        excelTicketsIds: action.data.data.map((ticket) => ticket.id),
        excelTicketsTotal: action.data.total,
      };
    case FETCH_TICKETS_FOR_EXCEL_FAILURE:
      return {
        ...state,
        fetchTicketForExcelLoaded: true,
        fetchTicketForExcelError: true,
      };
    case SET_TICKETS_LIST_ORDER:
      return {
        ...state,
        order: action.order,
      };
    case LOGOUT_SUCCESS:
      return initialstate;
    case LOCATION_CHANGE: {
      const newRoute = action.payload.location.pathname.substr(1).split('/');
      const { lastRoute } = state;
      if (lastRoute.length > 0 && lastRoute[0] !== newRoute[0]) {
        return {
          ...state,
          fetchTicketSearched: false,
          filter: initialFilter,
          lastRoute: newRoute,
        };
      }
      return {
        ...state,
        lastRoute: newRoute,
      };
    }
    default:
      return state;
  }
}
