import {
  call,
  put,
  select,
  take,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import api from '../api';
import {
  fetchCitiesSuccess,
  fetchCitiesFailure,
  fetchNationsSuccess,
  fetchNationsFailure,
  saveAbookContactSuccess,
  saveAbookContactFailure,
  fetchContactsSuccess,
  fetchContactsFailure,
  fetchAbookInitialsSuccess,
  fetchAbookInitialsFailure,
  fetchAbookTagsSuccess,
  fetchAbookTagsFailure,
  fetchAbookContactSuccess,
  fetchAbookContactFailure,
  deleteAbookContactSuccess,
  deleteAbookContactFailure,
  deleteMassiveContactsSuccess,
  deleteMassiveContactsFailure,
  importAbookContactSuccess,
  importAbookContactFailure,
  cancelImportAbookContactSuccess,
  cancelImportAbookContactFailure,
  confirmImportAbookContactSuccess,
  confirmImportAbookContactFailure,
  fetchCompaniesSuccess,
  fetchCompaniesFailure,
  fetchImportStatusSuccess,
  fetchImportStatusFailure,
  fetchAtecoCodesSuccess,
  fetchAtecoCodesFailure,
  fetchAbookListsSuccess,
  fetchAbookListsFailure,
  fetchAbookListFailure,
  saveAbookListSuccess,
  saveAbookListFailure,
  deleteAbookListSuccess,
  deleteAbookListFailure,
  fetchAbookListsRequest,
  fetchAbookListSuccess,
  fetchAbookContactInfoCallsSuccess,
  fetchAbookContactInfoCallsFailure,
  fetchAbookContactInfoTicketsSuccess,
  fetchAbookContactInfoTicketsFailure,
  fetchAbookContactInfoEventsSuccess,
  fetchAbookContactInfoEventsFailure,
  fetchAbookContactInfoFaxesSuccess,
  fetchAbookContactInfoFaxesFailure,
  fetchContactsRequest,
  fetchAbookNumberSuccess,
  fetchAbookNumberFailure,
} from './actions';

import {
  SAVE_ABOOK_CONTACT_REQUEST,
  FETCH_CITIES_REQUEST,
  FETCH_NATIONS_REQUEST,
  FETCH_ABOOK_CONTACTS_REQUEST,
  FETCH_ABOOK_INITIALS_REQUEST,
  FETCH_ABOOK_TAGS_REQUEST,
  FETCH_ABOOK_CONTACT_REQUEST,
  DELETE_ABOOK_CONTACT_REQUEST,
  DELETE_MASSIVE_CONTACTS_REQUEST,
  IMPORT_ABOOK_CONTACT_REQUEST,
  CANCEL_IMPORT_ABOOK_CONTACT_REQUEST,
  CONFIRM_IMPORT_ABOOK_CONTACT_REQUEST,
  FETCH_COMPANIES_REQUEST,
  FETCH_IMPORT_STATUS_REQUEST,
  FETCH_ATECO_CODES_REQUEST,
  CANCEL_IMPORT_ABOOK_ERROR_CONTACTS_REQUEST,
  FETCH_ABOOK_LISTS_REQUEST,
  FETCH_ABOOK_LIST_REQUEST,
  SAVE_ABOOK_LIST_REQUEST,
  DELETE_ABOOK_LIST_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_CALLS_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_TICKETS_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_EVENTS_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_FAXES_REQUEST,
  FETCH_ABOOK_NUMBER_REQUEST,
} from './types';
import { checkApiResponse, checkApiError } from '../rootSaga';
import history from '../../history';
import { getExtensionsLoaded, isPhoneLogged } from '../phone/selectors';
import { PbxManager } from '../phone/PbxManager';
import { PhoneEnums } from '../phone/PhoneUtils';
import { getInterlocutorData } from '../phone/sagas';
import {
  getContactsCurrentPage,
  getContactsFilter,
  getContactsCurrentInitial,
} from './selectors';
import { ABOOK_LIST_CONTACTS_PAGE_SIZE } from './ContactsUtils';
import { getCustomerId } from '../me/selectors';

export function* fetchAbookTags(action) {
  try {
    const res = yield call(api.contacts.getAbookTags, action.tag);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchAbookTagsSuccess([]));
    } else {
      yield put(fetchAbookTagsSuccess(res.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAbookTagsFailure(error));
  }
}

export function* fetchAbookInitials(action) {
  try {
    const res = yield call(api.contacts.getAbookInitials, action.initials);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchAbookInitialsSuccess([]));
    } else {
      yield put(fetchAbookInitialsSuccess(res.data, action.initials));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAbookInitialsFailure(error));
  }
}

export function* fetchContacts(action) {
  try {
    const res = yield call(api.contacts.getContacts, action.params);
    yield call(checkApiResponse, res);
    yield put(
      fetchContactsSuccess({
        data: res.status === 200 ? res.data : [],
        page: action.params.page,
        pageSize: action.params.pageSize,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchContactsFailure(error));
  }
}

export function* fetchContact(action) {
  try {
    const res = yield call(api.contacts.getContact, action.contactId);
    yield call(checkApiResponse, res);
    yield put(fetchAbookContactSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAbookContactFailure(error));
  }
}

export function* fetchCities(action) {
  try {
    const res = yield call(api.contacts.getCities, action.name);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchCitiesSuccess([]));
    } else {
      yield put(fetchCitiesSuccess(res.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCitiesFailure(error));
  }
}

export function* fetchNations(action) {
  try {
    const res = yield call(api.contacts.getNations, action.name);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchNationsSuccess([]));
    } else {
      yield put(fetchNationsSuccess(res.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchNationsFailure(error));
  }
}

export function* saveContact(action) {
  try {
    const res = yield call(
      action.payload.contactData.id
        ? api.contacts.updateContact
        : api.contacts.createContact,
      action.payload.contactData
    );
    yield call(checkApiResponse, res);
    const contactData = {
      ...action.payload.contactData,
      id: action.payload.contactData.id || res.data.id,
    };
    yield put(saveAbookContactSuccess(contactData));
    if (!action.payload.fromModal) {
      history.goBack();
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (
      error &&
      err.data &&
      err.data !== '' &&
      Array.isArray(err.data) &&
      err.data.length > 0
    ) {
      yield put(saveAbookContactFailure(err.data[0]));
    } else {
      if (error) yield put(saveAbookContactFailure(error));
    }
  }
}

export function* deleteContact(action) {
  try {
    const res = yield call(api.contacts.deleteContact, action.contactId);
    yield call(checkApiResponse, res);
    yield put(deleteAbookContactSuccess(action.contactId));
    // yield put(push('/abook/contacts'));
    const filter = yield select(getContactsFilter);
    const page = yield select(getContactsCurrentPage);
    const initial = yield select(getContactsCurrentInitial);
    yield put(
      fetchContactsRequest({
        ...filter,
        page,
        pageSize: ABOOK_LIST_CONTACTS_PAGE_SIZE,
        initial,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteAbookContactFailure(error));
  }
}
export function* deleteMassiveContacts(action) {
  try {
    const res = yield call(
      api.contacts.deleteMassiveContacts,
      action.contactData
    );

    yield call(checkApiResponse, res);
    yield put(deleteMassiveContactsSuccess(res));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteMassiveContactsFailure(error));
  }
}

export function* importAbookContacts(action) {
  try {
    const res = yield call(api.contacts.importAbookContacts, action.data);

    yield call(checkApiResponse, res);
    yield put(importAbookContactSuccess(res));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(importAbookContactFailure(error));
  }
}
export function* confirmImportAbookContacts(action) {
  try {
    const res = yield call(api.contacts.importAbookContacts, action.data);

    yield call(checkApiResponse, res);
    yield put(confirmImportAbookContactSuccess(res));
    const filter = yield select(getContactsFilter);
    yield put(fetchContactsRequest(filter));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(confirmImportAbookContactFailure(error));
  }
}

export function* cancelImportAbookContact(action) {
  try {
    const res = yield call(
      api.contacts.cancelImportAbookContacts,
      action.status
    );
    yield call(checkApiResponse, res);
    yield put(cancelImportAbookContactSuccess(res));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(cancelImportAbookContactFailure(error));
  }
}

export function* cancelImportAbookErrorContacts(action) {
  try {
    const res = yield call(
      api.contacts.cancelImportAbookContacts,
      action.status
    );
    yield call(checkApiResponse, res);
  } catch (err) {
    yield call(checkApiError, err);
  }
}

export function* fetchCompanies(action) {
  try {
    if (action.name.length === 0) {
      yield put(fetchCompaniesSuccess([]));
    } else {
      const res = yield call(api.contacts.getCompanies, action.name);
      yield call(checkApiResponse, res);
      if (res.status === 204) {
        yield put(fetchCompaniesSuccess([]));
      } else {
        yield put(fetchCompaniesSuccess(res.data));
      }
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCompaniesFailure(error));
  }
}

export function* fetchImportStatus() {
  try {
    const res = yield call(api.contacts.getImportStatus);
    yield call(checkApiResponse, res);
    yield put(fetchImportStatusSuccess(res));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchImportStatusFailure(error));
  }
}

export function* fetchAtecoCodes(action) {
  try {
    const res = yield call(api.contacts.getAtecoCodes, action.data);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchAtecoCodesSuccess([]));
    } else {
      yield put(fetchAtecoCodesSuccess(res.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAtecoCodesFailure(error));
  }
}

export function* fetchAbookLists(action) {
  try {
    const res = yield call(api.contacts.getAbookLists, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      fetchAbookListsSuccess({
        data: res.status === 204 ? [] : res.data.data,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAbookListsFailure({ error }));
  }
}

export function* fetchAbookList(action) {
  try {
    const res = yield call(api.contacts.getAbookList, action.payload.id);
    yield call(checkApiResponse, res);
    yield put(fetchAbookListSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAbookListFailure({ error }));
  }
}

export function* saveAbookList(action) {
  try {
    const res = yield call(
      action.payload.params.id
        ? api.contacts.updateAbookList
        : api.contacts.createAbookList,
      action.payload.params
    );
    yield call(checkApiResponse, res);
    yield put(saveAbookListSuccess(res.data.id));
    if (!action.payload.fromModal) {
      history.push('/abook/lists');
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(saveAbookListFailure({ error: err.data.error || error }));
  }
}

export function* deleteAbookList(action) {
  try {
    const res = yield call(api.contacts.deleteAbookList, action.payload.id);
    yield call(checkApiResponse, res);
    yield put(deleteAbookListSuccess());
    yield put(fetchAbookListsRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(deleteAbookListFailure({ error: err.data.error || error }));
  }
}

export function* fetchAbookContactInfoCalls(action) {
  try {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const respDeletedCalls = yield call(api.cdr.retrieveOtherDeletedCalls);
    yield call(checkApiResponse, respDeletedCalls);
    const respCallsNotes = yield call(api.cdr.retrieveCallsNotes);
    const customerId = yield select(getCustomerId);
    const res = yield call(
      PbxManager.retrieveOthersCdr.bind(PbxManager, action.params, {
        deletedCalls: respDeletedCalls.data,
        callsNotes: respCallsNotes.data,
        customerId,
      })
    );
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    // wait user extensions are loaded to retrieve interlocutor data
    let usersExtensionsLoaded = yield select(getExtensionsLoaded);

    while (!usersExtensionsLoaded) {
      yield take();
      usersExtensionsLoaded = yield select(getExtensionsLoaded);
    }
    for (let i = 0; i < res.data.length; i += 1) {
      const responseCalled = yield call(
        getInterlocutorData,
        res.data[i].called,
        false
      );
      const responseCalling = yield call(
        getInterlocutorData,
        res.data[i].calling,
        false
      );
      res.data[i].calledInterlocutor = responseCalled;
      res.data[i].callingInterlocutor = responseCalling;
      if (responseCalling && responseCalling.id) {
        delete res.data[i].callingCode;
      }
    }
    yield put(fetchAbookContactInfoCallsSuccess(res.data));
  } catch (err) {
    yield put(fetchAbookContactInfoCallsFailure(err));
  }
}

export function* fetchAbookContactInfoTickets(action) {
  try {
    const res = yield call(api.tickets.getTickets, action.params);
    yield call(checkApiResponse, res);
    yield put(
      fetchAbookContactInfoTicketsSuccess(
        res.status === 200 ? res.data.data : []
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAbookContactInfoTicketsFailure(error));
  }
}

export function* fetchAbookContactInfoEvents(action) {
  try {
    const res = yield call(api.calendar.getEvents, action.params);
    yield call(checkApiResponse, res);
    yield put(
      fetchAbookContactInfoEventsSuccess(res.status === 200 ? res.data : [])
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAbookContactInfoEventsFailure(error));
  }
}

export function* fetchAbookContactInfoFaxes(action) {
  try {
    const res = yield call(api.fax.getFaxesAbookList, action.params.id);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchAbookContactInfoFaxesSuccess([]));
    } else {
      for (var faxData of res.data.data) {
        const senderInterlocutor = yield call(
          getInterlocutorData,
          faxData.numberSender,
          false
        );
        const receiverInterlocutor = yield call(
          getInterlocutorData,
          faxData.numberReceiver,
          false
        );
        faxData.senderInterlocutor = senderInterlocutor;
        faxData.receiverInterlocutor = receiverInterlocutor;
      }
      yield put(fetchAbookContactInfoFaxesSuccess(res.data.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAbookContactInfoFaxesFailure(error));
  }
}

export function* fetchAbookNumber(action) {
  try {
    const {
      payload: { number },
    } = action;
    const res = yield call(api.contacts.getContactFromNumber, { number });
    yield call(checkApiResponse, res);
    yield put(
      fetchAbookNumberSuccess(
        res.status === 200
          ? {
              id: res.data.id,
              number,
              fullname: res.data.fullname,
              avatar: res.data.avatar,
              interlocutorType: res.data.contactType,
              numbers: res.data.numbers,
            }
          : { number }
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAbookNumberFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(SAVE_ABOOK_CONTACT_REQUEST, saveContact);
  yield takeLatest(FETCH_CITIES_REQUEST, fetchCities);
  yield takeLatest(FETCH_NATIONS_REQUEST, fetchNations);
  yield takeLatest(FETCH_ABOOK_CONTACTS_REQUEST, fetchContacts);
  yield takeLatest(FETCH_ABOOK_INITIALS_REQUEST, fetchAbookInitials);
  yield takeLatest(FETCH_ABOOK_TAGS_REQUEST, fetchAbookTags);
  yield takeEvery(FETCH_ABOOK_CONTACT_REQUEST, fetchContact);
  yield takeLatest(DELETE_ABOOK_CONTACT_REQUEST, deleteContact);
  yield takeLatest(DELETE_MASSIVE_CONTACTS_REQUEST, deleteMassiveContacts);
  yield takeLatest(IMPORT_ABOOK_CONTACT_REQUEST, importAbookContacts);
  yield takeLatest(
    CONFIRM_IMPORT_ABOOK_CONTACT_REQUEST,
    confirmImportAbookContacts
  );
  yield takeLatest(
    CANCEL_IMPORT_ABOOK_CONTACT_REQUEST,
    cancelImportAbookContact
  );
  yield takeLatest(
    CANCEL_IMPORT_ABOOK_ERROR_CONTACTS_REQUEST,
    cancelImportAbookErrorContacts
  );
  yield takeLatest(FETCH_COMPANIES_REQUEST, fetchCompanies);
  yield takeLatest(FETCH_IMPORT_STATUS_REQUEST, fetchImportStatus);
  yield takeLatest(FETCH_ATECO_CODES_REQUEST, fetchAtecoCodes);
  yield takeLatest(FETCH_ABOOK_LISTS_REQUEST, fetchAbookLists);
  yield takeLatest(FETCH_ABOOK_LIST_REQUEST, fetchAbookList);
  yield takeLatest(SAVE_ABOOK_LIST_REQUEST, saveAbookList);
  yield takeLatest(DELETE_ABOOK_LIST_REQUEST, deleteAbookList);
  yield takeLatest(
    FETCH_ABOOK_CONTACT_INFO_CALLS_REQUEST,
    fetchAbookContactInfoCalls
  );
  yield takeLatest(
    FETCH_ABOOK_CONTACT_INFO_TICKETS_REQUEST,
    fetchAbookContactInfoTickets
  );
  yield takeLatest(
    FETCH_ABOOK_CONTACT_INFO_EVENTS_REQUEST,
    fetchAbookContactInfoEvents
  );
  yield takeLatest(
    FETCH_ABOOK_CONTACT_INFO_FAXES_REQUEST,
    fetchAbookContactInfoFaxes
  );
  yield takeEvery(FETCH_ABOOK_NUMBER_REQUEST, fetchAbookNumber);
}
