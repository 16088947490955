import { createSelector } from 'reselect';

export const getCreateWorkingHoursPlanLoaded = (state) =>
  state.workingPlans.createWorkingHoursPlanLoaded;
export const getCreateWorkingHoursPlanError = (state) =>
  state.workingPlans.createWorkingHoursPlanError;
export const getUpdateWorkingHoursPlanLoaded = (state) =>
  state.workingPlans.updateWorkingHoursPlanLoaded;
export const getUpdateWorkingHoursPlanError = (state) =>
  state.workingPlans.updateWorkingHoursPlanError;
export const getWorkingHoursPlanLoaded = (state) =>
  state.workingPlans.getWorkingHoursPlanLoaded;
export const getWorkingHoursPlanError = (state) =>
  state.workingPlans.getWorkingHoursPlanError;
export const getWorkingPlanById = (state, id) => state.workingPlans.byId[id];
export const getWorkingPlansById = (state) => state.workingPlans.byId;
export const getWorkingPlansIds = (state) => state.workingPlans.allIds;
export const getWorkingPlans = createSelector(
  [getWorkingPlansById, getWorkingPlansIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getWorkingPlansTotal = (state) => state.workingPlans.total;
export const getWorkingPlansCurrentPage = (state) =>
  state.workingPlans.currentPage;
export const getWorkingPlansLoaded = (state) =>
  state.workingPlans.getWorkingHoursPlansLoaded;
export const getWorkingPlansError = (state) =>
  state.workingPlans.getWorkingHoursPlansError;
export const getAssignWorkingHoursPlanToUserLoaded = (state) =>
  state.workingPlans.assignWorkingHoursPlanToUserLoaded;
export const getAssignWorkingHoursPlanToUserError = (state) =>
  state.workingPlans.assignWorkingHoursPlanToUserError;
export const getAssignWorkingHoursPlanToUserSuccess = (state) =>
  state.workingPlans.assignWorkingHoursPlanToUserSuccess;
export const getDeleteWorkingHoursPlanLoaded = (state) =>
  state.workingPlans.deleteWorkingHoursPlanLoaded;
export const getDeleteWorkingHoursPlanError = (state) =>
  state.workingPlans.deleteWorkingHoursPlanError;
export const getAssignWorkingHoursPlanToAllUsersLoaded = (state) =>
  state.workingPlans.assignWorkingHoursPlanToAllUsersLoaded;
export const getAssignWorkingHoursPlanToAllUsersSuccess = (state) =>
  state.workingPlans.assignWorkingHoursPlanToAllUsersSuccess;
export const getAssignWorkingHoursPlanToAllUsersError = (state) =>
  state.workingPlans.assignWorkingHoursPlanToAllUsersError;
export const getAssignWorkingHoursPlanLoaded = (state) =>
  state.workingPlans.assignWorkingHoursPlanLoaded;
export const getAssignWorkingHoursPlanError = (state) =>
  state.workingPlans.assignWorkingHoursPlanError;
export const getAssignWorkingHoursPlanSuccess = (state) =>
  state.workingPlans.assignWorkingHoursPlanSuccess;
export const getRoundWorkingHours = (state) => ({
  roundIn: state.workingPlans.roundIn,
  roundOut: state.workingPlans.roundOut,
  roundUsers: state.workingPlans.roundUsers,
});
export const getRoundWorkingHoursLoading = (state) => state.workingPlans.roundLoading;
export const getRoundWorkingHoursError = (state) => state.workingPlans.roundError;
export const getRoundWorkingHoursSuccess = (state) => state.workingPlans.roundSuccess;
export const getBreakWorkingHoursLoading = (state) => state.workingPlans.breakLoading;
export const getBreakWorkingHoursError = (state) => state.workingPlans.breakError;
export const getBreakWorkingHoursSuccess = (state) => state.workingPlans.breakSuccess;
export const getBreakWorkingHours = (state) => (state.workingPlans.workingBreak);