export const SET_TICKETS_FILTER = 'SET_TICKETS_FILTER';
export const FETCH_TICKET_STATUS_REQUEST = 'FETCH_TICKET_STATUS_REQUEST';
export const FETCH_TICKET_STATUS_SUCCESS = 'FETCH_TICKET_STATUS_SUCCESS';
export const FETCH_TICKET_STATUS_FAILURE = 'FETCH_TICKET_STATUS_FAILURE';
export const FETCH_TICKETS_REQUEST = 'FETCH_TICKETS_REQUEST';
export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
export const FETCH_TICKETS_FAILURE = 'FETCH_TICKETS_FAILURE';
export const FETCH_TICKETS_GROUP_REQUEST = 'FETCH_TICKETS_GROUP_REQUEST';
export const FETCH_TICKETS_GROUP_SUCCESS = 'FETCH_TICKETS_GROUP_SUCCESS';
export const FETCH_TICKETS_GROUP_FAILURE = 'FETCH_TICKETS_GROUP_FAILURE';
export const INSERT_TICKET_REQUEST = 'INSERT_TICKET_REQUEST';
export const INSERT_TICKET_SUCCESS = 'INSERT_TICKET_SUCCESS';
export const INSERT_TICKET_FAILURE = 'INSERT_TICKET_FAILURE';
export const FETCH_CUSTOMIZED_QUESTIONS_REQUEST =
  'FETCH_CUSTOMIZED_QUESTIONS_REQUEST';
export const FETCH_CUSTOMIZED_QUESTIONS_SUCCESS =
  'FETCH_CUSTOMIZED_QUESTIONS_SUCCESS';
export const FETCH_CUSTOMIZED_QUESTIONS_FAILURE =
  'FETCH_CUSTOMIZED_QUESTIONS_FAILURE';
export const FETCH_TICKET_DETAILS_REQUEST = 'FETCH_TICKET_DETAILS_REQUEST';
export const FETCH_TICKET_DETAILS_SUCCESS = 'FETCH_TICKET_DETAILS_SUCCESS';
export const FETCH_TICKET_DETAILS_FAILURE = 'FETCH_TICKET_DETAILS_FAILURE';
export const FETCH_TICKETS_TREE_REQUEST = 'FETCH_TICKETS_TREE_REQUEST';
export const FETCH_TICKETS_TREE_SUCCESS = 'FETCH_TICKETS_TREE_SUCCESS';
export const FETCH_TICKETS_TREE_FAILURE = 'FETCH_TICKETS_TREE_FAILURE';
export const SAVE_TICKETS_TREE_REQUEST = 'SAVE_TICKETS_TREE_REQUEST';
export const SAVE_TICKETS_TREE_SUCCESS = 'SAVE_TICKETS_TREE_SUCCESS';
export const SAVE_TICKETS_TREE_FAILURE = 'SAVE_TICKETS_TREE_FAILURE';
export const DELETE_TICKETS_TREE_REQUEST = 'DELETE_TICKETS_TREE_REQUEST';
export const DELETE_TICKETS_TREE_SUCCESS = 'DELETE_TICKETS_TREE_SUCCESS';
export const DELETE_TICKETS_TREE_FAILURE = 'DELETE_TICKETS_TREE_FAILURE';
export const TOGGLE_TREE_EDIT_MODE = 'TOGGLE_TREE_EDIT_MODE';
export const SAVE_TREE_QUESTION_REQUEST = 'SAVE_TREE_QUESTION_REQUEST';
export const SAVE_TREE_QUESTION_FAILURE = 'SAVE_TREE_QUESTION_FAILURE';
export const SAVE_TREE_QUESTION_SUCCESS = 'SAVE_TREE_QUESTION_SUCCESS';
export const DELETE_TREE_QUESTION_REQUEST = 'DELETE_TREE_QUESTION_REQUEST';
export const DELETE_TREE_QUESTION_FAILURE = 'DELETE_TREE_QUESTION_FAILURE';
export const DELETE_TREE_QUESTION_SUCCESS = 'DELETE_TREE_QUESTION_SUCCESS';
export const SAVE_TREE_CHOICE_REQUEST = 'SAVE_TREE_CHOICE_REQUEST';
export const SAVE_TREE_CHOICE_FAILURE = 'SAVE_TREE_CHOICE_FAILURE';
export const SAVE_TREE_CHOICE_SUCCESS = 'SAVE_TREE_CHOICE_SUCCESS';
export const DELETE_TREE_CHOICE_REQUEST = 'DELETE_TREE_CHOICE_REQUEST';
export const DELETE_TREE_CHOICE_FAILURE = 'DELETE_TREE_CHOICE_FAILURE';
export const DELETE_TREE_CHOICE_SUCCESS = 'DELETE_TREE_CHOICE_SUCCESS';
export const INSERT_TICKET_NOTIFICATION = 'INSERT_TICKET_NOTIFICATION';
export const INSERT_ASSIGNING_TICKET_NOTIFICATION =
  'INSERT_ASSIGNING_TICKET_NOTIFICATION';
export const EDIT_TICKET_NOTIFICATION = 'EDIT_TICKET_NOTIFICATION';
export const DELETE_TICKET_NOTIFICATION = 'DELETE_TICKET_NOTIFICATION';
export const CLOSE_TICKET_NOTIFICATION = 'CLOSE_TICKET_NOTIFICATION';
export const ASSIGN_TICKET_NOTIFICATION = 'ASSIGN_TICKET_NOTIFICATION';
export const UNASSIGN_TICKET_NOTIFICATION = 'UNASSIGN_TICKET_NOTIFICATION';
export const TAKE_TICKET_NOTIFICATION = 'TAKE_TICKET_NOTIFICATION';
export const WORK_TICKET_NOTIFICATION = 'WORK_TICKET_NOTIFICATION';
export const CHANGE_STATUS_TICKET_NOTIFICATION =
  'CHANGE_STATUS_TICKET_NOTIFICATION';
export const SUSPEND_TICKET_NOTIFICATION = 'SUSPEND_TICKET_NOTIFICATION';
export const SHARE_TICKET_NOTIFICATION = 'SHARE_TICKET_NOTIFICATION';
export const UNSHARE_TICKET_NOTIFICATION = 'UNSHARE_TICKET_NOTIFICATION';
export const DEADLINE_TICKET_NOTIFICATION = 'DEADLINE_TICKET_NOTIFICATION';
export const REOPEN_TICKET_NOTIFICATION = 'REOPEN_TICKET_NOTIFICATION';
export const CLOSE_TICKET_REQUEST = 'CLOSE_TICKET_REQUEST';
export const CLOSE_TICKET_SUCCESS = 'CLOSE_TICKET_SUCCESS';
export const CLOSE_TICKET_FAILURE = 'CLOSE_TICKET_FAILURE';
export const CLEAR_TICKET_ACTIONS_ERRORS = 'CLEAR_TICKET_ACTIONS_ERRORS';
export const ASSIGN_TICKET_REQUEST = 'ASSIGN_TICKET_REQUEST';
export const ASSIGN_TICKET_SUCCESS = 'ASSIGN_TICKET_SUCCESS';
export const ASSIGN_TICKET_FAILURE = 'ASSIGN_TICKET_FAILURE';
export const UNASSIGN_TICKET_REQUEST = 'UNASSIGN_TICKET_REQUEST';
export const UNASSIGN_TICKET_SUCCESS = 'UNASSIGN_TICKET_SUCCESS';
export const UNASSIGN_TICKET_FAILURE = 'UNASSIGN_TICKET_FAILURE';
export const TAKE_TICKET_REQUEST = 'TAKE_TICKET_REQUEST';
export const TAKE_TICKET_SUCCESS = 'TAKE_TICKET_SUCCESS';
export const TAKE_TICKET_FAILURE = 'TAKE_TICKET_FAILURE';
export const WORK_TICKET_REQUEST = 'WORK_TICKET_REQUEST';
export const WORK_TICKET_SUCCESS = 'WORK_TICKET_SUCCESS';
export const WORK_TICKET_FAILURE = 'WORK_TICKET_FAILURE';
export const SUSPEND_TICKET_REQUEST = 'SUSPEND_TICKET_REQUEST';
export const SUSPEND_TICKET_SUCCESS = 'SUSPEND_TICKET_SUCCESS';
export const SUSPEND_TICKET_FAILURE = 'SUSPEND_TICKET_FAILURE';
export const SHARE_TICKET_REQUEST = 'SHARE_TICKET_REQUEST';
export const SHARE_TICKET_SUCCESS = 'SHARE_TICKET_SUCCESS';
export const SHARE_TICKET_FAILURE = 'SHARE_TICKET_FAILURE';
export const UNSHARE_TICKET_REQUEST = 'UNSHARE_TICKET_REQUEST';
export const UNSHARE_TICKET_SUCCESS = 'UNSHARE_TICKET_SUCCESS';
export const UNSHARE_TICKET_FAILURE = 'UNSHARE_TICKET_FAILURE';
export const DELETE_TICKET_REQUEST = 'DELETE_TICKET_REQUEST';
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS';
export const DELETE_TICKET_FAILURE = 'DELETE_TICKET_FAILURE';
export const CHANGE_TICKET_STATUS_REQUEST = 'CHANGE_TICKET_STATUS_REQUEST';
export const CHANGE_TICKET_STATUS_SUCCESS = 'CHANGE_TICKET_STATUS_SUCCESS';
export const CHANGE_TICKET_STATUS_FAILURE = 'CHANGE_TICKET_STATUS_FAILURE';
export const CHANGE_CATEGORY_REQUEST = 'CHANGE_CATEGORY_REQUEST';
export const CHANGE_CATEGORY_SUCCESS = 'CHANGE_CATEGORY_SUCCESS';
export const CHANGE_CATEGORY_FAILURE = 'CHANGE_CATEGORY_FAILURE';
export const INSERT_TICKET_STATUS_REQUEST = 'INSERT_TICKET_STATUS_REQUEST';
export const INSERT_TICKET_STATUS_SUCCESS = 'INSERT_TICKET_STATUS_SUCCESS';
export const INSERT_TICKET_STATUS_FAILURE = 'INSERT_TICKET_STATUS_FAILURE';
export const EDIT_TICKET_STATUS_REQUEST = 'EDIT_TICKET_STATUS_REQUEST';
export const EDIT_TICKET_STATUS_SUCCESS = 'EDIT_TICKET_STATUS_SUCCESS';
export const EDIT_TICKET_STATUS_FAILURE = 'EDIT_TICKET_STATUS_FAILURE';
export const DELETE_TICKET_STATUS_REQUEST = 'DELETE_TICKET_STATUS_REQUEST';
export const DELETE_TICKET_STATUS_SUCCESS = 'DELETE_TICKET_STATUS_SUCCESS';
export const DELETE_TICKET_STATUS_FAILURE = 'DELETE_TICKET_STATUS_FAILURE';
export const CLEAR_TICKET_STATUS_ERRORS = 'CLEAR_TICKET_STATUS_ERRORS';
export const ARCHIVE_TICKET_REQUEST = 'ARCHIVE_TICKET_REQUEST';
export const ARCHIVE_TICKET_SUCCESS = 'ARCHIVE_TICKET_SUCCESS';
export const ARCHIVE_TICKET_FAILURE = 'ARCHIVE_TICKET_FAILURE';

export const FETCH_TICKET_CATEGORIES_REQUEST =
  'FETCH_TICKET_CATEGORIES_REQUEST';
export const FETCH_TICKET_CATEGORIES_SUCCESS =
  'FETCH_TICKET_CATEGORIES_SUCCESS';
export const FETCH_TICKET_CATEGORIES_FAILURE =
  'FETCH_TICKET_CATEGORIES_FAILURE';
export const INSERT_TICKET_CATEGORY_REQUEST = 'INSERT_TICKET_CATEGORY_REQUEST';
export const INSERT_TICKET_CATEGORY_SUCCESS = 'INSERT_TICKET_CATEGORY_SUCCESS';
export const INSERT_TICKET_CATEGORY_FAILURE = 'INSERT_TICKET_CATEGORY_FAILURE';
export const CLEAR_TICKET_CATEGORY_ERRORS = 'CLEAR_TICKET_CATEGORY_ERRORS';
export const EDIT_TICKET_CATEGORY_REQUEST = 'EDIT_TICKET_CATEGORY_REQUEST';
export const EDIT_TICKET_CATEGORY_SUCCESS = 'EDIT_TICKET_CATEGORY_SUCCESS';
export const EDIT_TICKET_CATEGORY_FAILURE = 'EDIT_TICKET_CATEGORY_FAILURE';
export const DELETE_TICKET_CATEGORY_REQUEST = 'DELETE_TICKET_CATEGORY_REQUEST';
export const DELETE_TICKET_CATEGORY_SUCCESS = 'DELETE_TICKET_CATEGORY_SUCCESS';
export const DELETE_TICKET_CATEGORY_FAILURE = 'DELETE_TICKET_CATEGORY_FAILURE';
export const EDIT_TICKET_REQUEST = 'EDIT_TICKET_REQUEST';
export const EDIT_TICKET_SUCCESS = 'EDIT_TICKET_SUCCESS';
export const EDIT_TICKET_FAILURE = 'EDIT_TICKET_FAILURE';
export const CLEAR_EDIT_TICKET_ERRORS = 'CLEAR_EDIT_TICKET_ERRORS';
export const FETCH_TICKET_SETTINGS_REQUEST = 'FETCH_TICKET_SETTINGS_REQUEST';
export const FETCH_TICKET_SETTINGS_SUCCESS = 'FETCH_TICKET_SETTINGS_SUCCESS';
export const FETCH_TICKET_SETTINGS_FAILURE = 'FETCH_TICKET_SETTINGS_FAILURE';
export const UPDATE_TICKET_SETTINGS_REQUEST = 'UPDATE_TICKET_SETTINGS_REQUEST';
export const UPDATE_TICKET_SETTINGS_SUCCESS = 'UPDATE_TICKET_SETTINGS_SUCCESS';
export const UPDATE_TICKET_SETTINGS_FAILURE = 'UPDATE_TICKET_SETTINGS_FAILURE';
export const FETCH_TICKET_DASHBOARD_HEADER_REQUEST =
  'FETCH_TICKET_DASHBOARD_HEADER_REQUEST';
export const FETCH_TICKET_DASHBOARD_HEADER_SUCCESS =
  'FETCH_TICKET_DASHBOARD_HEADER_SUCCESS';
export const FETCH_TICKET_DASHBOARD_HEADER_FAILURE =
  'FETCH_TICKET_DASHBOARD_HEADER_FAILURE';
export const FETCH_TICKET_DASHBOARD_PROGRESS_BAR_REQUEST =
  'FETCH_TICKET_DASHBOARD_PROGRESS_BAR_REQUEST';
export const FETCH_TICKET_DASHBOARD_PROGRESS_BAR_SUCCESS =
  'FETCH_TICKET_DASHBOARD_PROGRESS_BAR_SUCCESS';
export const FETCH_TICKET_DASHBOARD_PROGRESS_BAR_FAILURE =
  'FETCH_TICKET_DASHBOARD_PROGRESS_BAR_FAILURE';
export const FETCH_TICKET_DASHBOARD_USERS_STATISTICS_REQUEST =
  'FETCH_TICKET_DASHBOARD_USERS_STATISTICS_REQUEST';
export const FETCH_TICKET_DASHBOARD_USERS_STATISTICS_SUCCESS =
  'FETCH_TICKET_DASHBOARD_USERS_STATISTICS_SUCCESS';
export const FETCH_TICKET_DASHBOARD_USERS_STATISTICS_FAILURE =
  'FETCH_TICKET_DASHBOARD_USERS_STATISTICS_FAILURE';

export const FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_REQUEST =
  'FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_REQUEST';
export const FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_SUCCESS =
  'FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_SUCCESS';
export const FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_FAILURE =
  'FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_FAILURE';

export const FETCH_TICKETS_LIST_SHORTCUT_REQUEST =
  'FETCH_TICKETS_LIST_SHORTCUT_REQUEST';
export const FETCH_TICKETS_LIST_SHORTCUT_SUCCESS =
  'FETCH_TICKETS_LIST_SHORTCUT_SUCCESS';
export const FETCH_TICKETS_LIST_SHORTCUT_FAILURE =
  'FETCH_TICKETS_LIST_SHORTCUT_FAILURE';
export const FETCH_TICKETS_FOR_EXCEL_REQUEST =
  'FETCH_TICKETS_FOR_EXCEL_REQUEST';
export const FETCH_TICKETS_FOR_EXCEL_SUCCESS =
  'FETCH_TICKETS_FOR_EXCEL_SUCCESS';
export const FETCH_TICKETS_FOR_EXCEL_FAILURE =
  'FETCH_TICKETS_FOR_EXCEL_FAILURE';
export const REOPEN_TICKET_REQUEST = 'REOPEN_TICKET_REQUEST';
export const REOPEN_TICKET_SUCCESS = 'REOPEN_TICKET_SUCCESS';
export const REOPEN_TICKET_FAILURE = 'REOPEN_TICKET_FAILURE';
export const SET_TICKETS_LIST_ORDER = 'SET_TICKETS_LIST_ORDER';
