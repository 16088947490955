import {
  FETCH_PHONE_SETTINGS_REQUEST,
  FETCH_PHONE_SETTINGS_SUCCESS,
  FETCH_PHONE_SETTINGS_FAILURE,
  LOGIN_PHONE_REQUEST,
  LOGIN_PHONE_SUCCESS,
  LOGIN_PHONE_FAILURE,
  LOGOUT_PHONE_SUCCESS,
  LOGOUT_PHONE_FAILURE,
  RELOGIN_PHONE_REQUEST,
  INITIAL_ABILIS_PHONE_STATUS_SUCCESS,
  INITIAL_NETHESIS_PHONE_STATUS_SUCCESS,
  INITIAL_PHONE_STATUS_FAILURE,
  ABILIS_PHONE_STATUS_CHANGES_SUCCESS,
  NETHESIS_PHONE_STATUS_CHANGES_SUCCESS,
  PHONE_STATUS_CHANGES_FAILURE,
  TOGGLE_PHONE_DND_REQUEST,
  TOGGLE_PHONE_DND_SUCCESS,
  TOGGLE_PHONE_DND_FAILURE,
  EXEC_CALL_REQUEST,
  EXEC_CALL_SUCCESS,
  EXEC_CALL_FAILURE,
  HANGUP_CALL_REQUEST,
  HANGUP_CALL_SUCCESS,
  HANGUP_CALL_FAILURE,
  HANGUP_HOLD_CALL_REQUEST,
  HANGUP_HOLD_CALL_SUCCESS,
  HANGUP_HOLD_CALL_FAILURE,
  FETCH_INTERLOCUTOR_DATA_REQUEST,
  FETCH_INTERLOCUTOR_DATA_SUCCESS,
  FETCH_INTERLOCUTOR_DATA_FAILURE,
  BLIND_TRANSFER_REQUEST,
  BLIND_TRANSFER_SUCCESS,
  BLIND_TRANSFER_FAILURE,
  FETCH_SEARCHED_CONTACTS_REQUEST,
  FETCH_SEARCHED_CONTACTS_SUCCESS,
  FETCH_SEARCHED_CONTACTS_FAILURE,
  HOLD_CALL_REQUEST,
  HOLD_CALL_SUCCESS,
  HOLD_CALL_FAILURE,
  UNHOLD_CALL_REQUEST,
  UNHOLD_CALL_SUCCESS,
  UNHOLD_CALL_FAILURE,
  AID_TRANSFER_REQUEST,
  AID_TRANSFER_SUCCESS,
  AID_TRANSFER_FAILURE,
  LINK_CALLS_REQUEST,
  LINK_CALLS_SUCCESS,
  LINK_CALLS_FAILURE,
  PARK_CALL_REQUEST,
  PARK_CALL_SUCCESS,
  PARK_CALL_FAILURE,
  SWITCH_CALLS_REQUEST,
  SWITCH_CALLS_SUCCESS,
  SWITCH_CALLS_FAILURE,
  PICKUP_CALL_REQUEST,
  PICKUP_CALL_SUCCESS,
  PICKUP_CALL_FAILURE,
  PICKUP_PARK_REQUEST,
  PICKUP_PARK_SUCCESS,
  PICKUP_PARK_FAILURE,
  PICKUP_QUEUE_REQUEST,
  PICKUP_QUEUE_SUCCESS,
  PICKUP_QUEUE_FAILURE,
  TOGGLE_MUTE_REQUEST,
  TOGGLE_MUTE_SUCCESS,
  TOGGLE_MUTE_FAILURE,
  ACTIVATE_WEBRTC_REQUEST,
  ACTIVATE_WEBRTC_SUCCESS,
  ACTIVATE_WEBRTC_FAILURE,
  DEACTIVATE_WEBRTC_REQUEST,
  DEACTIVATE_WEBRTC_SUCCESS,
  DEACTIVATE_WEBRTC_FAILURE,
  ANSWER_CALL_REQUEST,
  ANSWER_CALL_SUCCESS,
  ANSWER_CALL_FAILURE,
  DECLINE_CALL_REQUEST,
  DECLINE_CALL_SUCCESS,
  DECLINE_CALL_FAILURE,
  INCOMING_WEBRTC_CALL,
  ACCEPTED_WEBRTC_CALL,
  RECORD_CALL_REQUEST,
  RECORD_CALL_SUCCESS,
  RECORD_CALL_FAILURE,
  SET_SEARCH_CONTACTS_FILTER,
  SEND_DTMF_REQUEST,
  SEND_DTMF_SUCCESS,
  SEND_DTMF_FAILURE,
  CHANGE_PHONE_STATUS,
  START_CONFERENCE_REQUEST,
  START_CONFERENCE_SUCCESS,
  START_CONFERENCE_FAILURE,
  END_CONFERENCE_REQUEST,
  END_CONFERENCE_SUCCESS,
  END_CONFERENCE_FAILURE,
  JOIN_CONFERENCE_REQUEST,
  JOIN_CONFERENCE_SUCCESS,
  JOIN_CONFERENCE_FAILURE,
  FETCH_CONFERENCE_REQUEST,
  FETCH_CONFERENCE_SUCCESS,
  FETCH_CONFERENCE_FAILURE,
  FETCH_MEMBER_DATA_REQUEST,
  FETCH_MEMBER_DATA_SUCCESS,
  FETCH_MEMBER_DATA_FAILURE,
  HANGUP_USER_CONFERENCE_REQUEST,
  HANGUP_USER_CONFERENCE_SUCCESS,
  HANGUP_USER_CONFERENCE_FAILURE,
  SPY_CALL_REQUEST,
  SPY_CALL_SUCCESS,
  SPY_CALL_FAILURE,
  INTRUDE_CALL_REQUEST,
  INTRUDE_CALL_SUCCESS,
  INTRUDE_CALL_FAILURE,
  ENABLE_PHONE_DND,
  DISABLE_PHONE_DND,
  NETHESIS_WEBSOCKET_DISCONNECTED,
  QUEUE_TRANSFER_REQUEST,
  QUEUE_TRANSFER_SUCCESS,
  QUEUE_TRANSFER_FAILURE,
  HANGUP_CHANNEL_REQUEST,
  HANGUP_CHANNEL_SUCCESS,
  HANGUP_CHANNEL_FAILURE,
  UNSET_CALLED_BUSY,
  RESET_WEBRTC_SPY,
  SEND_STOP_USE_WEBPHONE_SUCCESS,
  SEND_STOP_USE_WEBPHONE_REQUEST,
  SEND_STOP_USE_WEBPHONE_FAILURE,
  STOP_USE_WEBPHONE,
  FETCH_DEFAULT_DEVICE_SUCCESS,
  STOP_PLAY_WEBPHONE_RING,
  DECLINING_WEBRTC_CALL,
  HANGUP_WEBRTC_CALL,
  BUSY_WEBRTC_CALL,
  NO_SDP_WEBRTC_CALL,
  OUTGOING_WEBRTC_CALL,
  WEBPHONE_DISCONNECTED,
  WEBPHONE_DETACHED,
  REACTIVATE_WEBRTC_PHONE,
  SET_NO_VALID_JSEP_CALL,
  SDP_WEBRTC_CALL,
  FETCH_PBX_ROUTES_REQUEST,
  FETCH_PBX_ROUTES_SUCCESS,
  FETCH_PBX_ROUTES_FAILURE,
  SAVE_PBX_ROUTE_SUCCESS,
  SAVE_PBX_ROUTE_FAILURE,
  SAVE_PBX_ROUTE_REQUEST,
  STOP_RECORD_CALL_REQUEST,
  STOP_RECORD_CALL_SUCCESS,
  RECEIVED_TRUNK_UPDATE,
  ADD_CONFERENCE_MEMBER_WEBRTC_REQUEST,
  ADD_CONFERENCE_MEMBER_WEBRTC_SUCCESS,
  ADD_CONFERENCE_MEMBER_WEBRTC_FAILURE,
  FETCH_PBX_QUEUES_REQUEST,
  FETCH_PBX_QUEUES_SUCCESS,
  FETCH_PBX_QUEUES_FAILURE,
  SAVE_PBX_QUEUE_REQUEST,
  SAVE_PBX_QUEUE_SUCCESS,
  SAVE_PBX_QUEUE_FAILURE,
  TRACE_WEBRTC_TROUBLE,
} from './types';
import { PhoneEnums } from './PhoneUtils';

export const fetchPhoneSettingsRequest = () => ({
  type: FETCH_PHONE_SETTINGS_REQUEST,
});
export const fetchPhoneSettingsSuccess = (phoneSettings) => ({
  type: FETCH_PHONE_SETTINGS_SUCCESS,
  phoneSettings,
});
export const fetchPhoneSettingsFailure = (errors) => ({
  type: FETCH_PHONE_SETTINGS_FAILURE,
  errors,
});
export const loginPhoneRequest = () => ({
  type: LOGIN_PHONE_REQUEST,
});
export const loginPhoneSuccess = () => ({
  type: LOGIN_PHONE_SUCCESS,
});
export const loginPhoneFailure = (errors) => ({
  type: LOGIN_PHONE_FAILURE,
  errors,
});
export const logoutPhoneSuccess = () => ({
  type: LOGOUT_PHONE_SUCCESS,
});
export const logoutPhoneFailure = (errors) => ({
  type: LOGOUT_PHONE_FAILURE,
  errors,
});
export const reloginPhoneRequest = () => ({
  type: RELOGIN_PHONE_REQUEST,
});
export const initialAbilisPhonesStatusSuccess = (status) => ({
  type: INITIAL_ABILIS_PHONE_STATUS_SUCCESS,
  status,
});
export const initialNethesisPhonesStatusSuccess = (status) => ({
  type: INITIAL_NETHESIS_PHONE_STATUS_SUCCESS,
  status,
});

export const initialPhonesStatusFailure = (errors) => ({
  type: INITIAL_PHONE_STATUS_FAILURE,
  errors,
});
export const getAbilisPhonesStatusChangesSuccess = (status) => ({
  type: ABILIS_PHONE_STATUS_CHANGES_SUCCESS,
  status,
});
export const getNethesisPhonesStatusChangesSuccess = (status) => ({
  type: NETHESIS_PHONE_STATUS_CHANGES_SUCCESS,
  status,
});
export const getPhonesStatusChangesFailure = (errors) => ({
  type: PHONE_STATUS_CHANGES_FAILURE,
  errors,
});
export const toggleDNDRequest = (status) => ({
  type: TOGGLE_PHONE_DND_REQUEST,
  status,
});
export const toggleDNDSuccess = () => ({
  type: TOGGLE_PHONE_DND_SUCCESS,
});
export const toggleDNDFailure = () => ({
  type: TOGGLE_PHONE_DND_FAILURE,
});
export const execCallRequest = ({ number, addExitCode, outOfPanel }) => ({
  type: EXEC_CALL_REQUEST,
  number,
  addExitCode,
  outOfPanel,
});
export const execCallSuccess = () => ({
  type: EXEC_CALL_SUCCESS,
});
export const execCallFailure = () => ({
  type: EXEC_CALL_FAILURE,
});
export const hangupCallRequest = (callid) => ({
  type: HANGUP_CALL_REQUEST,
  callid,
});
export const hangupCallSuccess = () => ({
  type: HANGUP_CALL_SUCCESS,
});
export const hangupCallFailure = () => ({
  type: HANGUP_CALL_FAILURE,
});
export const hangupHoldCallRequest = (callid) => ({
  type: HANGUP_HOLD_CALL_REQUEST,
  callid,
});
export const hangupHoldCallSuccess = () => ({
  type: HANGUP_HOLD_CALL_SUCCESS,
});
export const hangupHoldCallFailure = () => ({
  type: HANGUP_HOLD_CALL_FAILURE,
});
export const hangupChannelRequest = (channel) => ({
  type: HANGUP_CHANNEL_REQUEST,
  channel,
});
export const hangupChannelSuccess = () => ({
  type: HANGUP_CHANNEL_SUCCESS,
});
export const hangupChannelFailure = () => ({
  type: HANGUP_CHANNEL_FAILURE,
});
export const fetchInterlocutorDataRequest = (payload) => ({
  type: FETCH_INTERLOCUTOR_DATA_REQUEST,
  payload,
});
export const fetchInterlocutorDataSuccess = (payload) => ({
  type: FETCH_INTERLOCUTOR_DATA_SUCCESS,
  payload,
});
export const fetchInterlocutorDataFailure = (error) => ({
  type: FETCH_INTERLOCUTOR_DATA_FAILURE,
  error,
});
export const fetchMemberDataRequest = (number) => ({
  type: FETCH_MEMBER_DATA_REQUEST,
  number,
});
export const fetchMemberDataSuccess = (member) => ({
  type: FETCH_MEMBER_DATA_SUCCESS,
  member,
});
export const fetchMemberDataFailure = (error) => ({
  type: FETCH_MEMBER_DATA_FAILURE,
  error,
});
export const blindTransferRequest = (payload) => ({
  type: BLIND_TRANSFER_REQUEST,
  payload,
});
export const blindTransferSuccess = (callId) => ({
  type: BLIND_TRANSFER_SUCCESS,
  callId,
});
export const blindTransferFailure = () => ({
  type: BLIND_TRANSFER_FAILURE,
});
export const queueTransferRequest = (payload) => ({
  type: QUEUE_TRANSFER_REQUEST,
  payload,
});
export const queueTransferSuccess = (callId) => ({
  type: QUEUE_TRANSFER_SUCCESS,
  callId,
});
export const queueTransferFailure = () => ({
  type: QUEUE_TRANSFER_FAILURE,
});
export const fetchSearchedContactsRequest = (params) => ({
  type: FETCH_SEARCHED_CONTACTS_REQUEST,
  params,
});
export const fetchSearchedContactsSuccess = (contacts) => ({
  type: FETCH_SEARCHED_CONTACTS_SUCCESS,
  contacts,
});
export const fetchSearchedContactsFailure = (error) => ({
  type: FETCH_SEARCHED_CONTACTS_FAILURE,
  error,
});
export const holdCallRequest = (callid) => ({
  type: HOLD_CALL_REQUEST,
  callid,
});
export const holdCallSuccess = () => ({
  type: HOLD_CALL_SUCCESS,
});
export const holdCallFailure = () => ({
  type: HOLD_CALL_FAILURE,
});
export const aidTransferRequest = (payload) => ({
  type: AID_TRANSFER_REQUEST,
  payload,
});
export const aidTransferSuccess = () => ({
  type: AID_TRANSFER_SUCCESS,
});
export const aidTransferFailure = () => ({
  type: AID_TRANSFER_FAILURE,
});
export const unholdCallRequest = (callid) => ({
  type: UNHOLD_CALL_REQUEST,
  callid,
});
export const unholdCallSuccess = () => ({
  type: UNHOLD_CALL_SUCCESS,
});
export const unholdCallFailure = () => ({
  type: UNHOLD_CALL_FAILURE,
});
export const linkCallsRequest = () => ({
  type: LINK_CALLS_REQUEST,
});
export const linkCallsSuccess = () => ({
  type: LINK_CALLS_SUCCESS,
});
export const linkCallsFailure = () => ({
  type: LINK_CALLS_FAILURE,
});
export const parkCallRequest = (payload) => ({
  type: PARK_CALL_REQUEST,
  payload,
});
export const parkCallSuccess = () => ({
  type: PARK_CALL_SUCCESS,
});
export const parkCallFailure = () => ({
  type: PARK_CALL_FAILURE,
});
export const switchCallsRequest = () => ({
  type: SWITCH_CALLS_REQUEST,
});
export const switchCallsSuccess = () => ({
  type: SWITCH_CALLS_SUCCESS,
});
export const switchCallsFailure = () => ({
  type: SWITCH_CALLS_FAILURE,
});
export const pickupCallRequest = (payload) => ({
  type: PICKUP_CALL_REQUEST,
  payload,
});
export const pickupCallSuccess = () => ({
  type: PICKUP_CALL_SUCCESS,
});
export const pickupCallFailure = () => ({
  type: PICKUP_CALL_FAILURE,
});
export const pickupParkRequest = (parkId) => ({
  type: PICKUP_PARK_REQUEST,
  parkId,
});
export const pickupParkSuccess = () => ({
  type: PICKUP_PARK_SUCCESS,
});
export const pickupParkFailure = () => ({
  type: PICKUP_PARK_FAILURE,
});
export const pickupQueueRequest = (payload) => ({
  type: PICKUP_QUEUE_REQUEST,
  payload,
});
export const pickupQueueSuccess = () => ({
  type: PICKUP_QUEUE_SUCCESS,
});
export const pickupQueueFailure = () => ({
  type: PICKUP_QUEUE_FAILURE,
});
export const toggleMuteRequest = (payload) => ({
  type: TOGGLE_MUTE_REQUEST,
  payload,
});
export const toggleMuteSuccess = (muted) => ({
  type: TOGGLE_MUTE_SUCCESS,
  muted,
});
export const toggleMuteFailure = () => ({
  type: TOGGLE_MUTE_FAILURE,
});
export const activateWebrtcPhoneRequest = () => ({
  type: ACTIVATE_WEBRTC_REQUEST,
});
export const activateWebrtcPhoneSuccess = () => ({
  type: ACTIVATE_WEBRTC_SUCCESS,
});
export const activateWebrtcPhoneFailure = () => ({
  type: ACTIVATE_WEBRTC_FAILURE,
});
export const deactivateWebrtcPhoneRequest = (payload) => ({
  type: DEACTIVATE_WEBRTC_REQUEST,
  payload,
});
export const deactivateWebrtcPhoneSuccess = (payload) => ({
  type: DEACTIVATE_WEBRTC_SUCCESS,
  payload,
});
export const deactivateWebrtcPhoneFailure = (payload) => ({
  type: DEACTIVATE_WEBRTC_FAILURE,
  payload,
});
export const answerCallRequest = (callid) => ({
  type: ANSWER_CALL_REQUEST,
  callid,
});
export const answerCallSuccess = () => ({
  type: ANSWER_CALL_SUCCESS,
});
export const answerCallFailure = () => ({
  type: ANSWER_CALL_FAILURE,
});
export const declineCallRequest = (callid) => ({
  type: DECLINE_CALL_REQUEST,
  callid,
});
export const declineCallSuccess = () => ({
  type: DECLINE_CALL_SUCCESS,
});
export const declineCallFailure = () => ({
  type: DECLINE_CALL_FAILURE,
});
export const incomingWebrtcCall = (data) => ({
  type: INCOMING_WEBRTC_CALL,
  data,
});
export const acceptedWebrtcCall = (data) => ({
  type: ACCEPTED_WEBRTC_CALL,
  data,
});
export const recordCallRequest = (payload) => ({
  type: RECORD_CALL_REQUEST,
  payload,
});
export const recordCallSuccess = () => ({
  type: RECORD_CALL_SUCCESS,
});
export const recordCallFailure = () => ({
  type: RECORD_CALL_FAILURE,
});
export const stopRecordCallRequest = (payload) => ({
  type: STOP_RECORD_CALL_REQUEST,
  payload,
});
export const stopRecordCallSuccess = () => ({
  type: STOP_RECORD_CALL_SUCCESS,
});
export const setSearchContactsFilter = (payload) => ({
  type: SET_SEARCH_CONTACTS_FILTER,
  payload,
});
export const sendDtmfRequest = (key) => ({
  type: SEND_DTMF_REQUEST,
  key,
});
export const sendDtmfSuccess = () => ({
  type: SEND_DTMF_SUCCESS,
});
export const sendDtmfFailure = () => ({
  type: SEND_DTMF_FAILURE,
});
export const startConferenceRequest = (payload) => ({
  type: START_CONFERENCE_REQUEST,
  payload,
});
export const startConferenceSuccess = () => ({
  type: START_CONFERENCE_SUCCESS,
});
export const startConferenceFailure = () => ({
  type: START_CONFERENCE_FAILURE,
});
export const endConferenceRequest = (conferenceId) => ({
  type: END_CONFERENCE_REQUEST,
  conferenceId,
});
export const endConferenceSuccess = () => ({
  type: END_CONFERENCE_SUCCESS,
});
export const endConferenceFailure = () => ({
  type: END_CONFERENCE_FAILURE,
});
export const changePhoneStatus = (pbxSettings, mainUsername) => ({
  type: CHANGE_PHONE_STATUS,
  pbxSettings,
  mainUsername,
});
export const fetchConferenceRequest = () => ({
  type: FETCH_CONFERENCE_REQUEST,
});
export const fetchConferenceSuccess = (conference) => ({
  type: FETCH_CONFERENCE_SUCCESS,
  conference,
});
export const fetchConferenceFailure = () => ({
  type: FETCH_CONFERENCE_FAILURE,
});
export const joinConferenceRequest = () => ({
  type: JOIN_CONFERENCE_REQUEST,
});
export const joinConferenceSuccess = () => ({
  type: JOIN_CONFERENCE_SUCCESS,
});
export const joinConferenceFailure = () => ({
  type: JOIN_CONFERENCE_FAILURE,
});
export const hangupUserConferenceRequest = (payload) => ({
  type: HANGUP_USER_CONFERENCE_REQUEST,
  payload,
});
export const hangupUserConferenceSuccess = () => ({
  type: HANGUP_USER_CONFERENCE_SUCCESS,
});
export const hangupUserConferenceFailure = () => ({
  type: HANGUP_USER_CONFERENCE_FAILURE,
});
export const spyCallRequest = (payload) => ({
  type: SPY_CALL_REQUEST,
  payload,
});
export const spyCallSuccess = () => ({
  type: SPY_CALL_SUCCESS,
});
export const spyCallFailure = () => ({
  type: SPY_CALL_FAILURE,
});
export const resetWebrtcSpy = () => ({
  type: RESET_WEBRTC_SPY,
});
export const intrudeCallRequest = (payload) => ({
  type: INTRUDE_CALL_REQUEST,
  payload,
});
export const intrudeCallSuccess = () => ({
  type: INTRUDE_CALL_SUCCESS,
});
export const intrudeCallFailure = () => ({
  type: INTRUDE_CALL_FAILURE,
});
export const enablePhoneDnd = () => ({
  type: ENABLE_PHONE_DND,
  status: PhoneEnums.DndStatus.ON,
});
export const disablePhoneDnd = () => ({
  type: DISABLE_PHONE_DND,
  status: PhoneEnums.DndStatus.OFF,
});
export const nethesisWebsocketDisconnected = () => ({
  type: NETHESIS_WEBSOCKET_DISCONNECTED,
});
export const unsetCalledBusy = () => ({
  type: UNSET_CALLED_BUSY,
});
export const sendStopUseWebphoneRequest = () => ({
  type: SEND_STOP_USE_WEBPHONE_REQUEST,
});
export const sendStopUseWebphoneSuccess = () => ({
  type: SEND_STOP_USE_WEBPHONE_SUCCESS,
});
export const sendStopUseWebphoneFailure = () => ({
  type: SEND_STOP_USE_WEBPHONE_FAILURE,
});
export const stopUseWebphone = (payload) => ({
  type: STOP_USE_WEBPHONE,
  payload,
});
export const fetchDefaultDeviceSuccess = (deviceType) => ({
  type: FETCH_DEFAULT_DEVICE_SUCCESS,
  deviceType,
});
export const stopPlayWebphoneRing = () => ({
  type: STOP_PLAY_WEBPHONE_RING,
});
export const decliningWebrtcCall = (data) => ({
  type: DECLINING_WEBRTC_CALL,
  data,
});
export const hangupWebrtcCall = (data) => ({
  type: HANGUP_WEBRTC_CALL,
  data,
});
export const busyWebrtcCall = (data) => ({
  type: BUSY_WEBRTC_CALL,
  data,
});
export const noSdpWebrtcCall = (data) => ({
  type: NO_SDP_WEBRTC_CALL,
  data,
});
export const sdpWebrtcCall = (data) => ({
  type: SDP_WEBRTC_CALL,
  data,
});
export const outgoingWebrtcCall = (data) => ({
  type: OUTGOING_WEBRTC_CALL,
  data,
});
export const webphoneDisconnected = () => ({
  type: WEBPHONE_DISCONNECTED,
});
export const webphoneDetached = () => ({
  type: WEBPHONE_DETACHED,
});
export const reactivateWebrtcPhone = () => ({
  type: REACTIVATE_WEBRTC_PHONE,
});
export const setNoValidJsepCall = () => ({
  type: SET_NO_VALID_JSEP_CALL,
});
export const fetchPbxRoutesRequest = (params) => ({
  type: FETCH_PBX_ROUTES_REQUEST,
  params,
});
export const fetchPbxRoutesSuccess = (routes) => ({
  type: FETCH_PBX_ROUTES_SUCCESS,
  routes,
});
export const fetchPbxRoutesFailure = (error) => ({
  type: FETCH_PBX_ROUTES_FAILURE,
  error,
});
export const savePbxRouteRequest = (params) => ({
  type: SAVE_PBX_ROUTE_REQUEST,
  params,
});
export const savePbxRouteSuccess = () => ({
  type: SAVE_PBX_ROUTE_SUCCESS,
});
export const savePbxRouteFailure = (error) => ({
  type: SAVE_PBX_ROUTE_FAILURE,
  error,
});
export const fetchPbxQueuesRequest = (params) => ({
  type: FETCH_PBX_QUEUES_REQUEST,
  params,
});
export const fetchPbxQueuesSuccess = (queues) => ({
  type: FETCH_PBX_QUEUES_SUCCESS,
  queues,
});
export const fetchPbxQueuesFailure = (error) => ({
  type: FETCH_PBX_QUEUES_FAILURE,
  error,
});
export const savePbxQueueRequest = (params) => ({
  type: SAVE_PBX_QUEUE_REQUEST,
  params,
});
export const savePbxQueueSuccess = () => ({
  type: SAVE_PBX_QUEUE_SUCCESS,
});
export const savePbxQueueFailure = (error) => ({
  type: SAVE_PBX_QUEUE_FAILURE,
  error,
});
export const receiveTrunkUpdate = (payload) => ({
  type: RECEIVED_TRUNK_UPDATE,
  payload,
});
export const addConferenceMemberWebrtcRequest = (payload) => ({
  type: ADD_CONFERENCE_MEMBER_WEBRTC_REQUEST,
  payload,
});
export const addConferenceMemberWebrtcSuccess = () => ({
  type: ADD_CONFERENCE_MEMBER_WEBRTC_SUCCESS,
});
export const addConferenceMemberWebrtcFailure = () => ({
  type: ADD_CONFERENCE_MEMBER_WEBRTC_FAILURE,
});
export const traceWebrtcTrouble = (payload) => ({
  type: TRACE_WEBRTC_TROUBLE,
  payload,
});
