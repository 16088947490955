import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { defineMessages } from 'react-intl';
import {
  fetchTicketStatusSuccess,
  fetchTicketsSuccess,
  fetchTicketsFailure,
  insertTicketSuccess,
  insertTicketFailure,
  fetchCustomizedQuestionsSuccess,
  fetchCustomizedQuestionsFailure,
  fetchTicketDetailsSuccess,
  fetchTicketDetailsFailure,
  saveTreeQuestionSuccess,
  saveTreeQuestionFailure,
  saveTicketsTreeSuccess,
  saveTicketsTreeFailure,
  fetchTicketsTreeSuccess,
  fetchTicketsTreeFailure,
  fetchTicketsTreeRequest,
  deleteTreeQuestionSuccess,
  deleteTreeQuestionFailure,
  deleteTreeChoiceSuccess,
  saveTreeChoiceFailure,
  deleteTreeChoiceFailure,
  saveTreeChoiceSuccess,
  closeTicketSuccess,
  closeTicketFailure,
  fetchTicketDetailsRequest,
  deleteTicketsTreeFailure,
  deleteTicketsTreeSuccess,
  assignTicketSuccess,
  assignTicketFailure,
  takeTicketSuccess,
  takeTicketFailure,
  workTicketSuccess,
  workTicketFailure,
  suspendTicketSuccess,
  suspendTicketFailure,
  shareTicketSuccess,
  shareTicketFailure,
  unshareTicketSuccess,
  unshareTicketFailure,
  fetchTicketsRequest,
  deleteTicketSuccess,
  deleteTicketFailure,
  changeTicketStatusSuccess,
  changeTicketStatusFailure,
  fetchTicketStatusFailure,
  insertTicketStatusSuccess,
  insertTicketStatusFailure,
  fetchTicketStatusRequest,
  editTicketStatusSuccess,
  editTicketStatusFailure,
  deleteTicketStatusSuccess,
  deleteTicketStatusFailure,
  fetchTicketCategoriesSuccess,
  fetchTicketCategoriesFailure,
  insertTicketCategorySuccess,
  insertTicketCategoryFailure,
  fetchTicketCategoriesRequest,
  editTicketCategorySuccess,
  editTicketCategoryFailure,
  deleteTicketCategorySuccess,
  deleteTicketCategoryFailure,
  editTicketSuccess,
  editTicketFailure,
  fetchTicketSettingsSuccess,
  fetchTicketSettingsFailure,
  updateTicketSettingsSuccess,
  updateTicketSettingsFailure,
  fetchTicketDashboardHeaderSuccess,
  fetchTicketDashboardHeaderFailure,
  fetchTicketDashboardProgressBarSuccess,
  fetchTicketDashboardProgressBarFailure,
  fetchTicketDashboardUsersStatisticsSuccess,
  fetchTicketDashboardUsersStatisticsFailure,
  fetchTicketDashboardGraphStatisticsSuccess,
  fetchTicketDashboardGraphStatisticsFailure,
  toggleTreeEditMode,
  fetchTicketsListShortcutSuccess,
  fetchTicketsListShortcutFailure,
  changeCategorySuccess,
  changeCategoryFailure,
  fetchTicketsForExcelSuccess,
  fetchTicketsForExcelFailure,
  reopenTicketSuccess,
  reopenTicketFailure,
  archiveTicketSuccess,
  archiveTicketFailure,
  unassignTicketSuccess,
  unassignTicketFailure,
  fetchTicketsGroupSuccess,
  fetchTicketsGroupFailure,
} from './actions';
import {
  FETCH_TICKET_STATUS_REQUEST,
  FETCH_TICKETS_REQUEST,
  INSERT_TICKET_REQUEST,
  FETCH_CUSTOMIZED_QUESTIONS_REQUEST,
  FETCH_TICKET_DETAILS_REQUEST,
  SAVE_TREE_QUESTION_REQUEST,
  SAVE_TICKETS_TREE_REQUEST,
  FETCH_TICKETS_TREE_REQUEST,
  DELETE_TREE_QUESTION_REQUEST,
  SAVE_TREE_CHOICE_REQUEST,
  DELETE_TREE_CHOICE_REQUEST,
  ASSIGN_TICKET_NOTIFICATION,
  CLOSE_TICKET_NOTIFICATION,
  CLOSE_TICKET_REQUEST,
  DELETE_TICKETS_TREE_REQUEST,
  ASSIGN_TICKET_REQUEST,
  TAKE_TICKET_REQUEST,
  WORK_TICKET_REQUEST,
  SUSPEND_TICKET_REQUEST,
  SHARE_TICKET_REQUEST,
  UNSHARE_TICKET_REQUEST,
  DELETE_TICKET_REQUEST,
  CHANGE_TICKET_STATUS_REQUEST,
  INSERT_TICKET_STATUS_REQUEST,
  EDIT_TICKET_STATUS_REQUEST,
  DELETE_TICKET_STATUS_REQUEST,
  FETCH_TICKET_CATEGORIES_REQUEST,
  INSERT_TICKET_CATEGORY_REQUEST,
  EDIT_TICKET_CATEGORY_REQUEST,
  DELETE_TICKET_CATEGORY_REQUEST,
  EDIT_TICKET_REQUEST,
  FETCH_TICKET_SETTINGS_REQUEST,
  UPDATE_TICKET_SETTINGS_REQUEST,
  FETCH_TICKET_DASHBOARD_HEADER_REQUEST,
  FETCH_TICKET_DASHBOARD_PROGRESS_BAR_REQUEST,
  FETCH_TICKET_DASHBOARD_USERS_STATISTICS_REQUEST,
  FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_REQUEST,
  FETCH_TICKETS_LIST_SHORTCUT_REQUEST,
  CHANGE_CATEGORY_REQUEST,
  FETCH_TICKETS_FOR_EXCEL_REQUEST,
  REOPEN_TICKET_REQUEST,
  INSERT_ASSIGNING_TICKET_NOTIFICATION,
  ARCHIVE_TICKET_REQUEST,
  UNASSIGN_TICKET_REQUEST,
  FETCH_TICKETS_GROUP_REQUEST,
} from './types';
import api from '../api';
import history from '../../history';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';
import { NotificationEnums } from '../notifications/NotificationUtils';
import Utils from '../lib/utils';
import { getMeId } from '../me/selectors';
import { resetTicketsFiles } from '../files/actions';
import { getTicketsFilter } from './selectors';

const intlStrings = defineMessages({
  assignedTicket: {
    id: 'assignedTicket',
    defaultMessage: 'A ticket has been assigned to you',
  },
  closeOwnTicket: {
    id: 'closeOwnTicket',
    defaultMessage: 'One of your tickets has been closed',
  },
  closeAssignedTicket: {
    id: 'closeAssignedTicket',
    defaultMessage: 'A ticket assigned to you has been closed',
  },
});

export function* fetchTicketStatus() {
  try {
    const res = yield call(api.tickets.getTicketStatus);
    yield call(checkApiResponse, res);

    yield put(fetchTicketStatusSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketStatusFailure(error));
  }
}

export function* fetchTickets(action) {
  try {
    const res = yield call(api.tickets.getTickets, {
      ...action.params,
      showInternalTickets: null,
      showExternalTickets: null,
      hideInternalTickets:
        action.params.showInternalTickets !== undefined
          ? !action.params.showInternalTickets
          : null,
      hideExternalTickets:
        action.params.showExternalTickets !== undefined
          ? !action.params.showExternalTickets
          : null,
    });
    yield call(checkApiResponse, res);
    yield put(
      fetchTicketsSuccess({
        data: res.status === 200 ? res.data.data : [],
        page: action.params.page ? action.params.page : 0,
        total: res.data.total,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketsFailure(error));
  }
}

export function* fetchTicketsGroup(action) {
  try {
    const res = yield call(api.tickets.getTickets, action.params);
    yield call(checkApiResponse, res);
    yield put(
      fetchTicketsGroupSuccess(res.status === 200 ? res.data.data : [])
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketsGroupFailure(error));
  }
}

export function* insertTicket(action) {
  try {
    const res = yield call(api.tickets.insertTicket, action.payload);
    yield call(checkApiResponse, res);
    yield put(insertTicketSuccess(res.data));
    const searchFilters = yield select(getTicketsFilter);
    yield put(fetchTicketsRequest(searchFilters));
    yield put(push('/tickets/tickets-list'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(insertTicketFailure(error));
  }
}

export function* fetchTicketDetails(action) {
  try {
    const res = yield call(api.tickets.getTicketDetails, action.id);
    yield call(checkApiResponse, res);
    yield put(fetchTicketDetailsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketDetailsFailure(error));
  }
}

export function* fetchTicketsTree(action) {
  try {
    const res = yield call(api.tickets.getTicketsTree, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      if (action.payload.draft) {
        const result = yield call(api.tickets.getTicketsTree, {
          ...action.payload,
          draft: false,
        });
        yield call(checkApiResponse, result);
        if (result.status === 204) {
          yield put(
            fetchTicketsTreeSuccess({
              questions: [],
              choiceParent: action.payload.choiceParent,
              draft: false,
            })
          );
        } else {
          yield put(
            fetchTicketsTreeSuccess({
              ...result.data,
              draft: false,
              choiceParent: action.payload.choiceParent,
            })
          );
        }
      } else {
        yield put(
          fetchTicketsTreeSuccess({
            questions: [],
            choiceParent: action.payload.choiceParent,
            draft: action.payload.draft,
          })
        );
      }
    } else {
      yield put(
        fetchTicketsTreeSuccess({
          ...res.data,
          draft: action.payload.draft,
          choiceParent: action.payload.choiceParent,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketsTreeFailure(error));
  }
}

export function* saveTicketsTree(action) {
  try {
    const res = yield call(api.tickets.saveTicketsTree, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      saveTicketsTreeSuccess({ ...res.data, isDraft: action.payload.isDraft })
    );
    yield put(
      fetchTicketsTreeRequest({
        draft: action.payload.isDraft,
        choiceParent: null,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveTicketsTreeFailure(error));
  }
}

export function* deleteTicketsTree(action) {
  try {
    const res = yield call(api.tickets.deleteTicketsTree, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      fetchTicketsTreeRequest({
        draft: false,
      })
    );
    yield put(deleteTicketsTreeSuccess());
    yield put(toggleTreeEditMode(false));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteTicketsTreeFailure(error));
  }
}

export function* saveTreeQuestion(action) {
  try {
    const res = yield call(
      action.data.questionId
        ? api.tickets.updateQuestion
        : api.tickets.createQuestion,
      action.data
    );

    yield call(checkApiResponse, res);
    yield put(saveTreeQuestionSuccess());
    yield put(
      fetchTicketsTreeRequest({
        draft: true,
        choiceParent: action.data.choiceParent,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveTreeQuestionFailure(error));
  }
}

export function* deleteTreeQuestion(action) {
  try {
    const res = yield call(api.tickets.deleteQuestion, action.params);
    yield call(checkApiResponse, res);
    yield put(deleteTreeQuestionSuccess(action.params.questionId));
    yield put(
      fetchTicketsTreeRequest({
        draft: true,
        choiceParent: action.params.choiceParent,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteTreeQuestionFailure(error));
  }
}

export function* fetchCustomizedQuestions(action) {
  try {
    const res = yield call(api.tickets.fetchCustomizedQuestions, action.params);
    yield call(checkApiResponse, res);
    yield put(
      fetchCustomizedQuestionsSuccess(res.data.questions || [], action.params)
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCustomizedQuestionsFailure(error));
  }
}

export function* saveTreeChoice(action) {
  try {
    const res = yield call(
      action.data.choiceId
        ? api.tickets.updateChoice
        : api.tickets.createChoice,
      action.data
    );

    yield call(checkApiResponse, res);
    yield put(saveTreeChoiceSuccess());
    yield put(
      fetchTicketsTreeRequest({
        draft: true,
        choiceParent: action.data.choiceParent,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveTreeChoiceFailure(error));
  }
}

export function* deleteTreeChoice(action) {
  try {
    const res = yield call(api.tickets.deleteChoice, action.params);
    yield call(checkApiResponse, res);
    yield put(deleteTreeChoiceSuccess(action.params.choiceId));
    yield put(
      fetchTicketsTreeRequest({
        draft: true,
        choiceParent: action.params.choiceParent,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteTreeChoiceFailure(error));
  }
}

export function* notifyTicketAssignment(action) {
  const intl = yield call(Utils.retrieveIntl);
  const meId = yield select(getMeId);
  if (
    !action.payload.archived &&
    action.payload.idUserAction !== meId &&
    action.payload.assignedUser === meId
  ) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.ASSIGN_TICKET}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.assignedTicket),
      group: NotificationEnums.NotificationGroups.ASSIGN_TICKET,
      image: NotificationEnums.NotificationImages.TICKET,
      onclick: () => {
        history.push('/tickets/notifications');
      },
    });
  }
}

export function* notifyTicketUnassignment(action) {
  const intl = yield call(Utils.retrieveIntl);
  const meId = yield select(getMeId);
  if (
    !action.payload.archived &&
    action.payload.idUserAction !== meId &&
    action.payload.assignedUser === meId
  ) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.ASSIGN_TICKET}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.assignedTicket),
      group: NotificationEnums.NotificationGroups.ASSIGN_TICKET,
      image: NotificationEnums.NotificationImages.TICKET,
      onclick: () => {
        history.push('/tickets/notifications');
      },
    });
  }
}

export function* notifyTicketClosing(action) {
  const intl = yield call(Utils.retrieveIntl);
  const meId = yield select(getMeId);
  if (
    !action.payload.archived &&
    action.payload.idUserAction !== meId &&
    (action.payload.assignedUser === meId || action.payload.owner === meId)
  ) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.CLOSE_TICKET}-${action.payload.idEntity}`,
      body:
        action.payload.assignedUser === meId
          ? intl.formatMessage(intlStrings.closeAssignedTicket)
          : intl.formatMessage(intlStrings.closeOwnTicket),
      group: NotificationEnums.NotificationGroups.CLOSE_TICKET,
      image: NotificationEnums.NotificationImages.TICKET,
      onclick: () => {
        history.push('/tickets/notifications');
      },
    });
  }
}

export function* closeTicket(action) {
  try {
    const res = yield call(api.tickets.closeTicket, action.payload);
    yield call(checkApiResponse, res);
    yield put(closeTicketSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketDetailsRequest(action.payload.id));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(closeTicketFailure(error));
  }
}

export function* assignTicket(action) {
  try {
    const res = yield call(api.tickets.assignTicket, action.payload);
    yield call(checkApiResponse, res);
    yield put(assignTicketSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketDetailsRequest(action.payload.id));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(assignTicketFailure(error));
  }
}

export function* unassignTicket(action) {
  try {
    const res = yield call(api.tickets.unassignTicket, action.payload);
    yield call(checkApiResponse, res);
    yield put(unassignTicketSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketDetailsRequest(action.payload.id));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(unassignTicketFailure(error));
  }
}

export function* takeTicket(action) {
  try {
    const res = yield call(api.tickets.takeTicket, action.payload);
    yield call(checkApiResponse, res);
    yield put(takeTicketSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketDetailsRequest(action.payload.id));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(takeTicketFailure(error));
  }
}

export function* workTicket(action) {
  try {
    const res = yield call(api.tickets.workTicket, action.payload);
    yield call(checkApiResponse, res);
    yield put(workTicketSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketDetailsRequest(action.payload.id));
      yield put(resetTicketsFiles());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(workTicketFailure(error));
  }
}

export function* suspendTicket(action) {
  try {
    const res = yield call(api.tickets.suspendTicket, action.payload);
    yield call(checkApiResponse, res);
    yield put(suspendTicketSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketDetailsRequest(action.payload.id));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(suspendTicketFailure(error));
  }
}

export function* shareTicket(action) {
  try {
    const res = yield call(api.tickets.shareTicket, action.payload);
    yield call(checkApiResponse, res);
    yield put(shareTicketSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketDetailsRequest(action.payload.id));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(shareTicketFailure(error));
  }
}

export function* unshareTicket(action) {
  try {
    const res = yield call(api.tickets.unshareTicket, action.payload);
    yield call(checkApiResponse, res);
    yield put(unshareTicketSuccess(res.data));
    if (res.status === 200) {
      const meId = yield select(getMeId);
      if (meId === action.payload.data.user) {
        const searchFilters = yield select(getTicketsFilter);
        yield put(fetchTicketsRequest(searchFilters));
      } else {
        yield put(fetchTicketDetailsRequest(action.payload.id));
      }
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(unshareTicketFailure(error));
  }
}

export function* changeTicketStatus(action) {
  try {
    const res = yield call(api.tickets.changeTicketStatus, action.payload);
    yield call(checkApiResponse, res);
    yield put(changeTicketStatusSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketDetailsRequest(action.payload.id));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(changeTicketStatusFailure(error));
  }
}

export function* changeCategory(action) {
  try {
    const res = yield call(api.tickets.changeCategory, action.payload);
    yield call(checkApiResponse, res);
    yield put(changeCategorySuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketDetailsRequest(action.payload.id));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(changeCategoryFailure(error));
  }
}

export function* deleteTicket(action) {
  try {
    const res = yield call(
      action.payload.group
        ? api.tickets.deleteTicketGroup
        : api.tickets.deleteTicket,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(deleteTicketSuccess(res.data));
    if (res.status === 200) {
      const searchFilters = yield select(getTicketsFilter);
      yield put(fetchTicketsRequest(searchFilters));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteTicketFailure(error));
  }
}

export function* insertTicketStatus(action) {
  try {
    const res = yield call(api.tickets.insertTicketStatus, action.payload);
    yield call(checkApiResponse, res);
    yield put(insertTicketStatusSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketStatusRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(insertTicketStatusFailure(error));
  }
}

export function* editTicketStatus(action) {
  try {
    const res = yield call(api.tickets.editTicketStatus, action.payload);
    yield call(checkApiResponse, res);
    yield put(editTicketStatusSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketStatusRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(editTicketStatusFailure(error));
  }
}

export function* deleteTicketStatus(action) {
  try {
    const res = yield call(api.tickets.deleteTicketStatus, action.payload);
    yield call(checkApiResponse, res);
    yield put(deleteTicketStatusSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketStatusRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteTicketStatusFailure(error));
  }
}

export function* fetchTicketCategories() {
  try {
    const res = yield call(api.tickets.getTicketCategories);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchTicketCategoriesSuccess([]));
    } else {
      yield put(fetchTicketCategoriesSuccess(res.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketCategoriesFailure(error));
  }
}

export function* insertTicketCategory(action) {
  try {
    const res = yield call(api.tickets.insertTicketCategory, action.payload);
    yield call(checkApiResponse, res);
    yield put(insertTicketCategorySuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketCategoriesRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(insertTicketCategoryFailure(error));
  }
}

export function* editTicketCategory(action) {
  try {
    const res = yield call(api.tickets.editTicketCategory, action.payload);
    yield call(checkApiResponse, res);
    yield put(editTicketCategorySuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketCategoriesRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(editTicketCategoryFailure(error));
  }
}

export function* deleteTicketCategory(action) {
  try {
    const res = yield call(api.tickets.deleteTicketCategory, action.payload);
    yield call(checkApiResponse, res);
    yield put(deleteTicketCategorySuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketCategoriesRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteTicketCategoryFailure(error));
  }
}

export function* editTicket(action) {
  try {
    const res = yield call(
      action.payload.group
        ? api.tickets.editTicketGroup
        : api.tickets.editTicket,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(editTicketSuccess(res.data));
    const searchFilters = yield select(getTicketsFilter);
    yield put(fetchTicketsRequest(searchFilters));
    yield put(push('/tickets/tickets-list'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(editTicketFailure(error));
  }
}

export function* fetchTicketSettings() {
  try {
    const res = yield call(api.tickets.getTicketSettings);
    yield call(checkApiResponse, res);

    yield put(fetchTicketSettingsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketSettingsFailure(error));
  }
}

export function* updateTicketSettings(action) {
  try {
    const res = yield call(api.tickets.updateTicketSettings, action.payload);
    yield call(checkApiResponse, res);

    yield put(updateTicketSettingsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updateTicketSettingsFailure(error));
  }
}

export function* fetchDashboardHeader() {
  try {
    const res = yield call(api.tickets.getTicketDashboardHeader);
    yield call(checkApiResponse, res);

    yield put(fetchTicketDashboardHeaderSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketDashboardHeaderFailure(error));
  }
}

export function* getTicketDashboardProgressBar() {
  try {
    const res = yield call(api.tickets.getTicketDashboardProgressBar);
    yield call(checkApiResponse, res);

    yield put(fetchTicketDashboardProgressBarSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketDashboardProgressBarFailure(error));
  }
}

export function* getTicketDashboardUsersStatistics() {
  try {
    const res = yield call(api.tickets.getTicketDashboardUsersStatistics);
    yield call(checkApiResponse, res);

    yield put(fetchTicketDashboardUsersStatisticsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketDashboardUsersStatisticsFailure(error));
  }
}

export function* getTicketDashboardGraphStatistics(action) {
  try {
    const res = yield call(
      api.tickets.getTicketDashboardGraphStatistics,
      action.params
    );
    yield call(checkApiResponse, res);

    yield put(fetchTicketDashboardGraphStatisticsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketDashboardGraphStatisticsFailure(error));
  }
}

export function* getTicketsListShortcut() {
  try {
    const res = yield call(api.tickets.getTicketsListShortcut);
    yield call(checkApiResponse, res);

    yield put(fetchTicketsListShortcutSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketsListShortcutFailure(error));
  }
}

export function* fetchTicketsForExcel(action) {
  try {
    const res = yield call(api.tickets.getTickets, action.params);
    yield call(checkApiResponse, res);
    yield put(
      fetchTicketsForExcelSuccess({
        data: res.status === 200 ? res.data.data : [],
        total: res.data.total,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTicketsForExcelFailure(error));
  }
}

export function* reopenTicket(action) {
  try {
    const res = yield call(api.tickets.reopenTicket, action.payload);
    yield call(checkApiResponse, res);
    yield put(reopenTicketSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchTicketDetailsRequest(action.payload.id));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(reopenTicketFailure(error));
  }
}

export function* archiveTicket(action) {
  try {
    const res = yield call(api.tickets.archiveTicket, action.payload);
    yield call(checkApiResponse, res);
    yield put(archiveTicketSuccess());
    if (res.status === 200) {
      const searchFilters = yield select(getTicketsFilter);
      yield put(fetchTicketsRequest(searchFilters));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(archiveTicketFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_TICKET_STATUS_REQUEST, fetchTicketStatus);
  yield takeLatest(FETCH_TICKETS_REQUEST, fetchTickets);
  yield takeLatest(FETCH_TICKETS_GROUP_REQUEST, fetchTicketsGroup);
  yield takeLatest(INSERT_TICKET_REQUEST, insertTicket);
  yield takeEvery(FETCH_CUSTOMIZED_QUESTIONS_REQUEST, fetchCustomizedQuestions);
  yield takeLatest(FETCH_TICKET_DETAILS_REQUEST, fetchTicketDetails);
  yield takeLatest(FETCH_TICKETS_TREE_REQUEST, fetchTicketsTree);
  yield takeLatest(SAVE_TICKETS_TREE_REQUEST, saveTicketsTree);
  yield takeLatest(DELETE_TICKETS_TREE_REQUEST, deleteTicketsTree);
  yield takeLatest(SAVE_TREE_QUESTION_REQUEST, saveTreeQuestion);
  yield takeLatest(DELETE_TREE_QUESTION_REQUEST, deleteTreeQuestion);
  yield takeLatest(SAVE_TREE_CHOICE_REQUEST, saveTreeChoice);
  yield takeLatest(DELETE_TREE_CHOICE_REQUEST, deleteTreeChoice);
  yield takeEvery(INSERT_ASSIGNING_TICKET_NOTIFICATION, notifyTicketAssignment);
  yield takeEvery(ASSIGN_TICKET_NOTIFICATION, notifyTicketAssignment);
  yield takeEvery(CLOSE_TICKET_NOTIFICATION, notifyTicketClosing);
  yield takeLatest(CLOSE_TICKET_REQUEST, closeTicket);
  yield takeLatest(ASSIGN_TICKET_REQUEST, assignTicket);
  yield takeLatest(UNASSIGN_TICKET_REQUEST, unassignTicket);
  yield takeLatest(TAKE_TICKET_REQUEST, takeTicket);
  yield takeLatest(WORK_TICKET_REQUEST, workTicket);
  yield takeLatest(SUSPEND_TICKET_REQUEST, suspendTicket);
  yield takeLatest(SHARE_TICKET_REQUEST, shareTicket);
  yield takeLatest(UNSHARE_TICKET_REQUEST, unshareTicket);
  yield takeLatest(DELETE_TICKET_REQUEST, deleteTicket);
  yield takeLatest(CHANGE_TICKET_STATUS_REQUEST, changeTicketStatus);
  yield takeLatest(CHANGE_CATEGORY_REQUEST, changeCategory);
  yield takeLatest(INSERT_TICKET_STATUS_REQUEST, insertTicketStatus);
  yield takeLatest(EDIT_TICKET_STATUS_REQUEST, editTicketStatus);
  yield takeLatest(DELETE_TICKET_STATUS_REQUEST, deleteTicketStatus);
  yield takeLatest(FETCH_TICKET_CATEGORIES_REQUEST, fetchTicketCategories);
  yield takeLatest(INSERT_TICKET_CATEGORY_REQUEST, insertTicketCategory);
  yield takeLatest(EDIT_TICKET_CATEGORY_REQUEST, editTicketCategory);
  yield takeLatest(DELETE_TICKET_CATEGORY_REQUEST, deleteTicketCategory);
  yield takeLatest(EDIT_TICKET_REQUEST, editTicket);
  yield takeLatest(FETCH_TICKET_SETTINGS_REQUEST, fetchTicketSettings);
  yield takeLatest(UPDATE_TICKET_SETTINGS_REQUEST, updateTicketSettings);
  yield takeLatest(FETCH_TICKET_DASHBOARD_HEADER_REQUEST, fetchDashboardHeader);
  yield takeLatest(
    FETCH_TICKET_DASHBOARD_PROGRESS_BAR_REQUEST,
    getTicketDashboardProgressBar
  );
  yield takeLatest(
    FETCH_TICKET_DASHBOARD_USERS_STATISTICS_REQUEST,
    getTicketDashboardUsersStatistics
  );
  yield takeLatest(
    FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_REQUEST,
    getTicketDashboardGraphStatistics
  );
  yield takeLatest(FETCH_TICKETS_LIST_SHORTCUT_REQUEST, getTicketsListShortcut);
  yield takeLatest(FETCH_TICKETS_FOR_EXCEL_REQUEST, fetchTicketsForExcel);
  yield takeLatest(REOPEN_TICKET_REQUEST, reopenTicket);
  yield takeLatest(ARCHIVE_TICKET_REQUEST, archiveTicket);
}
