import {
  SET_TICKETS_FILTER,
  FETCH_TICKET_STATUS_REQUEST,
  FETCH_TICKET_STATUS_SUCCESS,
  FETCH_TICKET_STATUS_FAILURE,
  FETCH_TICKETS_REQUEST,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKETS_FAILURE,
  INSERT_TICKET_REQUEST,
  INSERT_TICKET_SUCCESS,
  INSERT_TICKET_FAILURE,
  FETCH_CUSTOMIZED_QUESTIONS_REQUEST,
  FETCH_CUSTOMIZED_QUESTIONS_SUCCESS,
  FETCH_CUSTOMIZED_QUESTIONS_FAILURE,
  FETCH_TICKET_DETAILS_REQUEST,
  FETCH_TICKET_DETAILS_SUCCESS,
  FETCH_TICKET_DETAILS_FAILURE,
  FETCH_TICKETS_TREE_REQUEST,
  FETCH_TICKETS_TREE_SUCCESS,
  FETCH_TICKETS_TREE_FAILURE,
  TOGGLE_TREE_EDIT_MODE,
  SAVE_TREE_QUESTION_REQUEST,
  SAVE_TREE_QUESTION_SUCCESS,
  SAVE_TREE_QUESTION_FAILURE,
  SAVE_TICKETS_TREE_REQUEST,
  SAVE_TICKETS_TREE_SUCCESS,
  SAVE_TICKETS_TREE_FAILURE,
  DELETE_TREE_QUESTION_REQUEST,
  DELETE_TREE_QUESTION_SUCCESS,
  DELETE_TREE_QUESTION_FAILURE,
  DELETE_TREE_CHOICE_SUCCESS,
  DELETE_TREE_CHOICE_FAILURE,
  DELETE_TREE_CHOICE_REQUEST,
  SAVE_TREE_CHOICE_FAILURE,
  SAVE_TREE_CHOICE_SUCCESS,
  SAVE_TREE_CHOICE_REQUEST,
  INSERT_TICKET_NOTIFICATION,
  DELETE_TICKET_NOTIFICATION,
  CLOSE_TICKET_NOTIFICATION,
  ASSIGN_TICKET_NOTIFICATION,
  TAKE_TICKET_NOTIFICATION,
  WORK_TICKET_NOTIFICATION,
  CHANGE_STATUS_TICKET_NOTIFICATION,
  SUSPEND_TICKET_NOTIFICATION,
  SHARE_TICKET_NOTIFICATION,
  UNSHARE_TICKET_NOTIFICATION,
  DEADLINE_TICKET_NOTIFICATION,
  REOPEN_TICKET_NOTIFICATION,
  CLOSE_TICKET_REQUEST,
  CLOSE_TICKET_SUCCESS,
  CLOSE_TICKET_FAILURE,
  CLEAR_TICKET_ACTIONS_ERRORS,
  DELETE_TICKETS_TREE_REQUEST,
  DELETE_TICKETS_TREE_SUCCESS,
  DELETE_TICKETS_TREE_FAILURE,
  ASSIGN_TICKET_REQUEST,
  ASSIGN_TICKET_SUCCESS,
  ASSIGN_TICKET_FAILURE,
  TAKE_TICKET_REQUEST,
  TAKE_TICKET_SUCCESS,
  TAKE_TICKET_FAILURE,
  WORK_TICKET_REQUEST,
  WORK_TICKET_SUCCESS,
  WORK_TICKET_FAILURE,
  SUSPEND_TICKET_REQUEST,
  SUSPEND_TICKET_SUCCESS,
  SUSPEND_TICKET_FAILURE,
  SHARE_TICKET_REQUEST,
  SHARE_TICKET_SUCCESS,
  SHARE_TICKET_FAILURE,
  UNSHARE_TICKET_REQUEST,
  UNSHARE_TICKET_SUCCESS,
  UNSHARE_TICKET_FAILURE,
  DELETE_TICKET_REQUEST,
  DELETE_TICKET_SUCCESS,
  DELETE_TICKET_FAILURE,
  CHANGE_TICKET_STATUS_REQUEST,
  CHANGE_TICKET_STATUS_SUCCESS,
  CHANGE_TICKET_STATUS_FAILURE,
  INSERT_TICKET_STATUS_REQUEST,
  INSERT_TICKET_STATUS_SUCCESS,
  INSERT_TICKET_STATUS_FAILURE,
  EDIT_TICKET_STATUS_REQUEST,
  EDIT_TICKET_STATUS_SUCCESS,
  EDIT_TICKET_STATUS_FAILURE,
  DELETE_TICKET_STATUS_REQUEST,
  DELETE_TICKET_STATUS_SUCCESS,
  DELETE_TICKET_STATUS_FAILURE,
  CLEAR_TICKET_STATUS_ERRORS,
  FETCH_TICKET_CATEGORIES_REQUEST,
  FETCH_TICKET_CATEGORIES_SUCCESS,
  FETCH_TICKET_CATEGORIES_FAILURE,
  INSERT_TICKET_CATEGORY_REQUEST,
  INSERT_TICKET_CATEGORY_SUCCESS,
  INSERT_TICKET_CATEGORY_FAILURE,
  CLEAR_TICKET_CATEGORY_ERRORS,
  EDIT_TICKET_CATEGORY_REQUEST,
  EDIT_TICKET_CATEGORY_SUCCESS,
  EDIT_TICKET_CATEGORY_FAILURE,
  DELETE_TICKET_CATEGORY_REQUEST,
  DELETE_TICKET_CATEGORY_SUCCESS,
  DELETE_TICKET_CATEGORY_FAILURE,
  EDIT_TICKET_REQUEST,
  EDIT_TICKET_SUCCESS,
  EDIT_TICKET_FAILURE,
  CLEAR_EDIT_TICKET_ERRORS,
  FETCH_TICKET_SETTINGS_REQUEST,
  FETCH_TICKET_SETTINGS_SUCCESS,
  FETCH_TICKET_SETTINGS_FAILURE,
  UPDATE_TICKET_SETTINGS_REQUEST,
  UPDATE_TICKET_SETTINGS_SUCCESS,
  UPDATE_TICKET_SETTINGS_FAILURE,
  FETCH_TICKET_DASHBOARD_HEADER_REQUEST,
  FETCH_TICKET_DASHBOARD_HEADER_SUCCESS,
  FETCH_TICKET_DASHBOARD_HEADER_FAILURE,
  FETCH_TICKET_DASHBOARD_PROGRESS_BAR_REQUEST,
  FETCH_TICKET_DASHBOARD_PROGRESS_BAR_SUCCESS,
  FETCH_TICKET_DASHBOARD_PROGRESS_BAR_FAILURE,
  FETCH_TICKET_DASHBOARD_USERS_STATISTICS_REQUEST,
  FETCH_TICKET_DASHBOARD_USERS_STATISTICS_SUCCESS,
  FETCH_TICKET_DASHBOARD_USERS_STATISTICS_FAILURE,
  FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_REQUEST,
  FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_SUCCESS,
  FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_FAILURE,
  FETCH_TICKETS_LIST_SHORTCUT_REQUEST,
  FETCH_TICKETS_LIST_SHORTCUT_SUCCESS,
  FETCH_TICKETS_LIST_SHORTCUT_FAILURE,
  CHANGE_CATEGORY_REQUEST,
  CHANGE_CATEGORY_SUCCESS,
  CHANGE_CATEGORY_FAILURE,
  FETCH_TICKETS_FOR_EXCEL_REQUEST,
  FETCH_TICKETS_FOR_EXCEL_SUCCESS,
  FETCH_TICKETS_FOR_EXCEL_FAILURE,
  REOPEN_TICKET_REQUEST,
  REOPEN_TICKET_SUCCESS,
  REOPEN_TICKET_FAILURE,
  SET_TICKETS_LIST_ORDER,
  INSERT_ASSIGNING_TICKET_NOTIFICATION,
  ARCHIVE_TICKET_REQUEST,
  ARCHIVE_TICKET_SUCCESS,
  ARCHIVE_TICKET_FAILURE,
  EDIT_TICKET_NOTIFICATION,
  UNASSIGN_TICKET_REQUEST,
  UNASSIGN_TICKET_SUCCESS,
  UNASSIGN_TICKET_FAILURE,
  UNASSIGN_TICKET_NOTIFICATION,
  FETCH_TICKETS_GROUP_REQUEST,
  FETCH_TICKETS_GROUP_SUCCESS,
  FETCH_TICKETS_GROUP_FAILURE,
} from './types';

export const setTicketsFilter = (payload) => ({
  type: SET_TICKETS_FILTER,
  payload,
});
export const fetchTicketStatusRequest = () => ({
  type: FETCH_TICKET_STATUS_REQUEST,
});
export const fetchTicketStatusSuccess = (data) => ({
  type: FETCH_TICKET_STATUS_SUCCESS,
  data,
});
export const fetchTicketStatusFailure = () => ({
  type: FETCH_TICKET_STATUS_FAILURE,
});
export const fetchTicketsRequest = (params) => ({
  type: FETCH_TICKETS_REQUEST,
  params,
});
export const fetchTicketsSuccess = (data) => ({
  type: FETCH_TICKETS_SUCCESS,
  data,
});
export const fetchTicketsFailure = () => ({
  type: FETCH_TICKETS_FAILURE,
});
export const fetchTicketsGroupRequest = (params) => ({
  type: FETCH_TICKETS_GROUP_REQUEST,
  params,
});
export const fetchTicketsGroupSuccess = (data) => ({
  type: FETCH_TICKETS_GROUP_SUCCESS,
  data,
});
export const fetchTicketsGroupFailure = () => ({
  type: FETCH_TICKETS_GROUP_FAILURE,
});
export const insertTicketRequest = (payload) => ({
  type: INSERT_TICKET_REQUEST,
  payload,
});
export const insertTicketSuccess = (data) => ({
  type: INSERT_TICKET_SUCCESS,
  data,
});
export const insertTicketFailure = (error) => ({
  type: INSERT_TICKET_FAILURE,
  error,
});
export const fetchCustomizedQuestionsRequest = (params) => ({
  type: FETCH_CUSTOMIZED_QUESTIONS_REQUEST,
  params,
});
export const fetchCustomizedQuestionsSuccess = (data, params) => ({
  type: FETCH_CUSTOMIZED_QUESTIONS_SUCCESS,
  data,
  params,
});
export const fetchCustomizedQuestionsFailure = (error) => ({
  type: FETCH_CUSTOMIZED_QUESTIONS_FAILURE,
  error,
});
export const fetchTicketDetailsRequest = (id) => ({
  type: FETCH_TICKET_DETAILS_REQUEST,
  id,
});
export const fetchTicketDetailsSuccess = (data) => ({
  type: FETCH_TICKET_DETAILS_SUCCESS,
  data,
});
export const fetchTicketDetailsFailure = (error) => ({
  type: FETCH_TICKET_DETAILS_FAILURE,
  error,
});
export const fetchTicketsTreeRequest = (payload) => ({
  type: FETCH_TICKETS_TREE_REQUEST,
  payload,
});
export const fetchTicketsTreeSuccess = (data) => ({
  type: FETCH_TICKETS_TREE_SUCCESS,
  data,
});
export const fetchTicketsTreeFailure = (error) => ({
  type: FETCH_TICKETS_TREE_FAILURE,
  error,
});
export const toggleTreeEditMode = (editing) => ({
  type: TOGGLE_TREE_EDIT_MODE,
  editing,
});
export const saveTicketsTreeRequest = (payload) => ({
  type: SAVE_TICKETS_TREE_REQUEST,
  payload,
});
export const saveTicketsTreeSuccess = (data) => ({
  type: SAVE_TICKETS_TREE_SUCCESS,
  data,
});
export const saveTicketsTreeFailure = (error) => ({
  type: SAVE_TICKETS_TREE_FAILURE,
  error,
});
export const deleteTicketsTreeRequest = (payload) => ({
  type: DELETE_TICKETS_TREE_REQUEST,
  payload,
});
export const deleteTicketsTreeSuccess = (data) => ({
  type: DELETE_TICKETS_TREE_SUCCESS,
  data,
});
export const deleteTicketsTreeFailure = (error) => ({
  type: DELETE_TICKETS_TREE_FAILURE,
  error,
});
export const saveTreeQuestionRequest = (data) => ({
  type: SAVE_TREE_QUESTION_REQUEST,
  data,
});
export const saveTreeQuestionSuccess = (id) => ({
  type: SAVE_TREE_QUESTION_SUCCESS,
  id,
});
export const saveTreeQuestionFailure = (errors) => ({
  type: SAVE_TREE_QUESTION_FAILURE,
  errors,
});
export const deleteTreeQuestionRequest = (params) => ({
  type: DELETE_TREE_QUESTION_REQUEST,
  params,
});
export const deleteTreeQuestionSuccess = (id) => ({
  type: DELETE_TREE_QUESTION_SUCCESS,
  id,
});
export const deleteTreeQuestionFailure = (errors) => ({
  type: DELETE_TREE_QUESTION_FAILURE,
  errors,
});
export const saveTreeChoiceRequest = (data) => ({
  type: SAVE_TREE_CHOICE_REQUEST,
  data,
});
export const saveTreeChoiceSuccess = (id) => ({
  type: SAVE_TREE_CHOICE_SUCCESS,
  id,
});
export const saveTreeChoiceFailure = (errors) => ({
  type: SAVE_TREE_CHOICE_FAILURE,
  errors,
});
export const deleteTreeChoiceRequest = (params) => ({
  type: DELETE_TREE_CHOICE_REQUEST,
  params,
});
export const deleteTreeChoiceSuccess = (id) => ({
  type: DELETE_TREE_CHOICE_SUCCESS,
  id,
});
export const deleteTreeChoiceFailure = (errors) => ({
  type: DELETE_TREE_CHOICE_FAILURE,
  errors,
});

export const insertTicketNotification = (payload) => ({
  type: INSERT_TICKET_NOTIFICATION,
  payload,
});
export const insertAssigningTicketNotification = (payload) => ({
  type: INSERT_ASSIGNING_TICKET_NOTIFICATION,
  payload,
});
export const editTicketNotification = (payload) => ({
  type: EDIT_TICKET_NOTIFICATION,
  payload,
});
export const deleteTicketNotification = (payload) => ({
  type: DELETE_TICKET_NOTIFICATION,
  payload,
});
export const closeTicketNotification = (payload) => ({
  type: CLOSE_TICKET_NOTIFICATION,
  payload,
});
export const assignTicketNotification = (payload) => ({
  type: ASSIGN_TICKET_NOTIFICATION,
  payload,
});
export const unassignTicketNotification = (payload) => ({
  type: UNASSIGN_TICKET_NOTIFICATION,
  payload,
});
export const takeTicketNotification = (payload) => ({
  type: TAKE_TICKET_NOTIFICATION,
  payload,
});
export const workTicketNotification = (payload) => ({
  type: WORK_TICKET_NOTIFICATION,
  payload,
});
export const changeStatusTicketNotification = (payload) => ({
  type: CHANGE_STATUS_TICKET_NOTIFICATION,
  payload,
});
export const suspendTicketNotification = (payload) => ({
  type: SUSPEND_TICKET_NOTIFICATION,
  payload,
});
export const shareTicketNotification = (payload) => ({
  type: SHARE_TICKET_NOTIFICATION,
  payload,
});
export const unshareTicketNotification = (payload) => ({
  type: UNSHARE_TICKET_NOTIFICATION,
  payload,
});
export const deadlineTicketNotification = (payload) => ({
  type: DEADLINE_TICKET_NOTIFICATION,
  payload,
});
export const reopenTicketNotification = (payload) => ({
  type: REOPEN_TICKET_NOTIFICATION,
  payload,
});
export const closeTicketRequest = (payload) => ({
  type: CLOSE_TICKET_REQUEST,
  payload,
});
export const closeTicketSuccess = () => ({
  type: CLOSE_TICKET_SUCCESS,
});
export const closeTicketFailure = (error) => ({
  type: CLOSE_TICKET_FAILURE,
  error,
});
export const clearTicketActionsErrors = () => ({
  type: CLEAR_TICKET_ACTIONS_ERRORS,
});
export const assignTicketRequest = (payload) => ({
  type: ASSIGN_TICKET_REQUEST,
  payload,
});
export const assignTicketSuccess = () => ({
  type: ASSIGN_TICKET_SUCCESS,
});
export const assignTicketFailure = (error) => ({
  type: ASSIGN_TICKET_FAILURE,
  error,
});
export const unassignTicketRequest = (payload) => ({
  type: UNASSIGN_TICKET_REQUEST,
  payload,
});
export const unassignTicketSuccess = () => ({
  type: UNASSIGN_TICKET_SUCCESS,
});
export const unassignTicketFailure = (error) => ({
  type: UNASSIGN_TICKET_FAILURE,
  error,
});
export const takeTicketRequest = (payload) => ({
  type: TAKE_TICKET_REQUEST,
  payload,
});
export const takeTicketSuccess = () => ({
  type: TAKE_TICKET_SUCCESS,
});
export const takeTicketFailure = (error) => ({
  type: TAKE_TICKET_FAILURE,
  error,
});
export const workTicketRequest = (payload) => ({
  type: WORK_TICKET_REQUEST,
  payload,
});
export const workTicketSuccess = () => ({
  type: WORK_TICKET_SUCCESS,
});
export const workTicketFailure = (error) => ({
  type: WORK_TICKET_FAILURE,
  error,
});
export const suspendTicketRequest = (payload) => ({
  type: SUSPEND_TICKET_REQUEST,
  payload,
});
export const suspendTicketSuccess = () => ({
  type: SUSPEND_TICKET_SUCCESS,
});
export const suspendTicketFailure = (error) => ({
  type: SUSPEND_TICKET_FAILURE,
  error,
});
export const shareTicketRequest = (payload) => ({
  type: SHARE_TICKET_REQUEST,
  payload,
});
export const shareTicketSuccess = () => ({
  type: SHARE_TICKET_SUCCESS,
});
export const shareTicketFailure = (error) => ({
  type: SHARE_TICKET_FAILURE,
  error,
});
export const unshareTicketRequest = (payload) => ({
  type: UNSHARE_TICKET_REQUEST,
  payload,
});
export const unshareTicketSuccess = () => ({
  type: UNSHARE_TICKET_SUCCESS,
});
export const unshareTicketFailure = (error) => ({
  type: UNSHARE_TICKET_FAILURE,
  error,
});
export const deleteTicketRequest = (payload) => ({
  type: DELETE_TICKET_REQUEST,
  payload,
});
export const deleteTicketSuccess = () => ({
  type: DELETE_TICKET_SUCCESS,
});
export const deleteTicketFailure = (error) => ({
  type: DELETE_TICKET_FAILURE,
  error,
});
export const changeTicketStatusRequest = (payload) => ({
  type: CHANGE_TICKET_STATUS_REQUEST,
  payload,
});
export const changeTicketStatusSuccess = () => ({
  type: CHANGE_TICKET_STATUS_SUCCESS,
});
export const changeTicketStatusFailure = (error) => ({
  type: CHANGE_TICKET_STATUS_FAILURE,
  error,
});
export const changeCategoryRequest = (payload) => ({
  type: CHANGE_CATEGORY_REQUEST,
  payload,
});
export const changeCategorySuccess = () => ({
  type: CHANGE_CATEGORY_SUCCESS,
});
export const changeCategoryFailure = (error) => ({
  type: CHANGE_CATEGORY_FAILURE,
  error,
});
export const insertTicketStatusRequest = (payload) => ({
  type: INSERT_TICKET_STATUS_REQUEST,
  payload,
});
export const insertTicketStatusSuccess = () => ({
  type: INSERT_TICKET_STATUS_SUCCESS,
});
export const insertTicketStatusFailure = (error) => ({
  type: INSERT_TICKET_STATUS_FAILURE,
  error,
});
export const editTicketStatusRequest = (payload) => ({
  type: EDIT_TICKET_STATUS_REQUEST,
  payload,
});
export const editTicketStatusSuccess = () => ({
  type: EDIT_TICKET_STATUS_SUCCESS,
});
export const editTicketStatusFailure = (error) => ({
  type: EDIT_TICKET_STATUS_FAILURE,
  error,
});
export const deleteTicketStatusRequest = (payload) => ({
  type: DELETE_TICKET_STATUS_REQUEST,
  payload,
});
export const deleteTicketStatusSuccess = () => ({
  type: DELETE_TICKET_STATUS_SUCCESS,
});
export const deleteTicketStatusFailure = (error) => ({
  type: DELETE_TICKET_STATUS_FAILURE,
  error,
});
export const clearTicketStatusErrors = () => ({
  type: CLEAR_TICKET_STATUS_ERRORS,
});

export const fetchTicketCategoriesRequest = () => ({
  type: FETCH_TICKET_CATEGORIES_REQUEST,
});
export const fetchTicketCategoriesSuccess = (data) => ({
  type: FETCH_TICKET_CATEGORIES_SUCCESS,
  data,
});
export const fetchTicketCategoriesFailure = () => ({
  type: FETCH_TICKET_CATEGORIES_FAILURE,
});
export const insertTicketCategoryRequest = (payload) => ({
  type: INSERT_TICKET_CATEGORY_REQUEST,
  payload,
});
export const insertTicketCategorySuccess = () => ({
  type: INSERT_TICKET_CATEGORY_SUCCESS,
});
export const insertTicketCategoryFailure = (error) => ({
  type: INSERT_TICKET_CATEGORY_FAILURE,
  error,
});
export const clearTicketCategoryErrors = () => ({
  type: CLEAR_TICKET_CATEGORY_ERRORS,
});
export const editTicketCategoryRequest = (payload) => ({
  type: EDIT_TICKET_CATEGORY_REQUEST,
  payload,
});
export const editTicketCategorySuccess = () => ({
  type: EDIT_TICKET_CATEGORY_SUCCESS,
});
export const editTicketCategoryFailure = (error) => ({
  type: EDIT_TICKET_CATEGORY_FAILURE,
  error,
});
export const deleteTicketCategoryRequest = (payload) => ({
  type: DELETE_TICKET_CATEGORY_REQUEST,
  payload,
});
export const deleteTicketCategorySuccess = () => ({
  type: DELETE_TICKET_CATEGORY_SUCCESS,
});
export const deleteTicketCategoryFailure = (error) => ({
  type: DELETE_TICKET_CATEGORY_FAILURE,
  error,
});
export const editTicketRequest = (payload) => ({
  type: EDIT_TICKET_REQUEST,
  payload,
});
export const editTicketSuccess = (data) => ({
  type: EDIT_TICKET_SUCCESS,
  data,
});
export const editTicketFailure = (error) => ({
  type: EDIT_TICKET_FAILURE,
  error,
});
export const clearEditTicketErrors = () => ({
  type: CLEAR_EDIT_TICKET_ERRORS,
});
export const fetchTicketSettingsRequest = () => ({
  type: FETCH_TICKET_SETTINGS_REQUEST,
});
export const fetchTicketSettingsSuccess = (data) => ({
  type: FETCH_TICKET_SETTINGS_SUCCESS,
  data,
});
export const fetchTicketSettingsFailure = (error) => ({
  type: FETCH_TICKET_SETTINGS_FAILURE,
  error,
});
export const updateTicketSettingsRequest = (payload) => ({
  type: UPDATE_TICKET_SETTINGS_REQUEST,
  payload,
});
export const updateTicketSettingsSuccess = (data) => ({
  type: UPDATE_TICKET_SETTINGS_SUCCESS,
  data,
});
export const updateTicketSettingsFailure = (error) => ({
  type: UPDATE_TICKET_SETTINGS_FAILURE,
  error,
});
export const fetchTicketDashboardHeaderRequest = () => ({
  type: FETCH_TICKET_DASHBOARD_HEADER_REQUEST,
});
export const fetchTicketDashboardHeaderSuccess = (data) => ({
  type: FETCH_TICKET_DASHBOARD_HEADER_SUCCESS,
  data,
});
export const fetchTicketDashboardHeaderFailure = (error) => ({
  type: FETCH_TICKET_DASHBOARD_HEADER_FAILURE,
  error,
});
export const fetchTicketDashboardProgressBarRequest = () => ({
  type: FETCH_TICKET_DASHBOARD_PROGRESS_BAR_REQUEST,
});
export const fetchTicketDashboardProgressBarSuccess = (data) => ({
  type: FETCH_TICKET_DASHBOARD_PROGRESS_BAR_SUCCESS,
  data,
});
export const fetchTicketDashboardProgressBarFailure = (error) => ({
  type: FETCH_TICKET_DASHBOARD_PROGRESS_BAR_FAILURE,
  error,
});
export const fetchTicketDashboardUsersStatisticsRequest = () => ({
  type: FETCH_TICKET_DASHBOARD_USERS_STATISTICS_REQUEST,
});
export const fetchTicketDashboardUsersStatisticsSuccess = (data) => ({
  type: FETCH_TICKET_DASHBOARD_USERS_STATISTICS_SUCCESS,
  data,
});
export const fetchTicketDashboardUsersStatisticsFailure = (error) => ({
  type: FETCH_TICKET_DASHBOARD_USERS_STATISTICS_FAILURE,
  error,
});
export const fetchTicketDashboardGraphStatisticsRequest = (params) => ({
  type: FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_REQUEST,
  params,
});
export const fetchTicketDashboardGraphStatisticsSuccess = (data) => ({
  type: FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_SUCCESS,
  data,
});
export const fetchTicketDashboardGraphStatisticsFailure = (error) => ({
  type: FETCH_TICKET_DASHBOARD_GRAPH_STATISTICS_FAILURE,
  error,
});
export const fetchTicketsListShortcutRequest = () => ({
  type: FETCH_TICKETS_LIST_SHORTCUT_REQUEST,
});
export const fetchTicketsListShortcutSuccess = (data) => ({
  type: FETCH_TICKETS_LIST_SHORTCUT_SUCCESS,
  data,
});
export const fetchTicketsListShortcutFailure = (error) => ({
  type: FETCH_TICKETS_LIST_SHORTCUT_FAILURE,
  error,
});
export const fetchTicketsForExcelRequest = (params) => ({
  type: FETCH_TICKETS_FOR_EXCEL_REQUEST,
  params,
});
export const fetchTicketsForExcelSuccess = (data) => ({
  type: FETCH_TICKETS_FOR_EXCEL_SUCCESS,
  data,
});
export const fetchTicketsForExcelFailure = () => ({
  type: FETCH_TICKETS_FOR_EXCEL_FAILURE,
});
export const reopenTicketRequest = (payload) => ({
  type: REOPEN_TICKET_REQUEST,
  payload,
});
export const reopenTicketSuccess = () => ({
  type: REOPEN_TICKET_SUCCESS,
});
export const reopenTicketFailure = (error) => ({
  type: REOPEN_TICKET_FAILURE,
  error,
});
export const setTicketsListOrder = (order) => ({
  type: SET_TICKETS_LIST_ORDER,
  order,
});
export const archiveTicketRequest = (payload) => ({
  type: ARCHIVE_TICKET_REQUEST,
  payload,
});
export const archiveTicketSuccess = () => ({
  type: ARCHIVE_TICKET_SUCCESS,
});
export const archiveTicketFailure = () => ({
  type: ARCHIVE_TICKET_FAILURE,
});
