import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { FormGroup } from 'reactstrap';
import moment from 'moment';
import { calculateSMSNumber } from '../../../js/sms/SMSUtils';
import Alert from '../../../components/messages/Alert';
import Button from '../../../components/formControls/Button';
import Label from '../../../components/formControls/Label';
import Input from '../../../components/formControls/Input';
import ToastMessage from '../../../components/messages/ToastMessage';
import Form from '../../../components/Form';
import SelectPhoneNumbers from '../../../components/SelectPhoneNumbers';
import {
  fetchSMSCampaignRequest,
  fetchSMSListRequest,
  fetchSMSScheduledRequest,
  saveSMSCampaignRequest,
  saveSMSScheduledRequest,
  sendSMSRequest,
} from '../../../js/sms/actions';
import {
  getFetchSMSCreditsError,
  getFetchSMSCreditsLoaded,
  getSaveSMSCampaignError,
  getSaveSMSCampaignLoaded,
  getSaveSMSScheduledError,
  getSaveSMSScheduledLoaded,
  getSendSMSError,
  getSendSMSLoaded,
  getValidSMSAliasIds,
  getSMSById,
  getSMSCampaignById,
  getSMSCredits,
  getSMSsAliasById,
  getSMSScheduledById,
  isSMSCreditsFetched,
  usingSMSAlias,
  usingSMSLink,
} from '../../../js/sms/selectors';
import SMSTextArea from './SMSTextArea';
import TimeInput from '../../../components/formControls/TimeInput';
import DatePickerInput from '../../../components/formControls/DatePickerInput';
import {
  getAbookListsIds,
  getAbookListsById,
} from '../../../js/contacts/selectors';
import { fetchAbookListsRequest } from '../../../js/contacts/actions';
import AliasSelect from './AliasSelect';
import ContactsListsSelect from '../../../components/formControls/ContactsListsSelect';
import Utils from '../../../js/lib/utils';
import SMSCreditsAlert from './SMSCreditsAlert';
import AbookListsCreateListModal from '../Abook/AbookListsCreateListModal';

const messages = defineMessages({
  name: {
    id: 'SMSEdit.label.name',
    defaultMessage: 'Name',
  },
  public: {
    id: 'SMSEdit.label.public',
    defaultMessage: 'Show this SMS to all users',
  },
  sender: {
    id: 'SMSEdit.label.sender',
    defaultMessage: 'Sender',
  },
  contacts: {
    id: 'SMSEdit.label.contacts',
    defaultMessage: 'Receivers',
  },
  lists: {
    id: 'SMSEdit.label.lists',
    defaultMessage: 'Lists',
  },
  text: {
    id: 'SMSEdit.label.text',
    defaultMessage: 'Text',
  },
  url: {
    id: 'SMSEdit.label.url',
    defaultMessage: 'Url',
  },
  date: {
    id: 'SMSEdit.label.date',
    defaultMessage: 'Scheduling date',
  },
  time: {
    id: 'SMSEdit.label.time',
    defaultMessage: 'Scheduling time',
  },
  buttonSend: {
    id: 'SMSEdit.button.send',
    defaultMessage: 'Send',
  },
  errorMessage: {
    id: 'SMSEdit.errorMessage',
    defaultMessage: 'Error message',
  },
  notAlias: {
    id: 'SMSEdit.notAlias',
    defaultMessage: 'You must create an alias to send SMS',
  },
  NOT_ENOUGH_SMS: {
    id: 'SMSEdit.NOT_ENOUGH_SMS',
    defaultMessage: 'Not enough SMS',
  },
  NOT_VALID_RECIPIENTS: {
    id: 'SMSEdit.NOT_VALID_RECIPIENTS',
    defaultMessage: 'There is no valid recipient in the selected list',
  },
  NOT_ENOUGH_GATEWAY_SMS: {
    id: 'SMSEdit.NOT_ENOUGH_GATEWAY_SMS',
    defaultMessage:
      'There are problems with SMS service. It will be restored in few hours',
  },
  errorFetchingCredits: {
    id: 'SMSEdit.errorFetchingCredits',
    defaultMessage:
      'There has been not possible to retrieve credits, so you cannot send SMS',
  },
  errorMissingName: {
    id: 'SMSEdit.errorMissingName',
    defaultMessage: 'Please insert a name for the campaign',
  },
  errorMissingSender: {
    id: 'SMSEdit.errorMissingSender',
    defaultMessage: 'Please insert a sender',
  },
  errorMissingText: {
    id: 'SMSEdit.errorMissingText',
    defaultMessage: 'You need to fill in some text',
  },
  errorUrlInText: {
    id: 'SMSEdit.errorUrlInText',
    defaultMessage:
      "Attenzione! il testo del messaggio contiene un link o un indirizzo email. E' possibile inserire questo tipo di informazioni solo se si invia il messaggio usando un alias",
  },
  errorMissingRecipients: {
    id: 'SMSEdit.errorMissingRecipients',
    defaultMessage: 'Please select almost one receiver',
  },
  errorMissingLists: {
    id: 'SMSEdit.errorMissingLists',
    defaultMessage: 'Please select almost one list',
  },
  errorMissingDate: {
    id: 'SMSEdit.errorMissingDate',
    defaultMessage: 'Please set a valid date',
  },
  errorMissingTime: {
    id: 'SMSEdit.errorMissingTime',
    defaultMessage: 'Please set a valid time',
  },
  errorWrongDateTime: {
    id: 'SMSEdit.errorWrongDateTime',
    defaultMessage: 'Date and time must be in the future',
  },
  defaultNumber: {
    id: 'SMSEdit.defaultNumber',
    defaultMessage: 'Default number',
  },
  noMoreRecipients: {
    id: 'SMSEdit.noMoreRecipients',
    defaultMessage: 'You can select maximum 10 recipients',
  },
  aliasPlaceholder: {
    id: 'SMSEdit.aliasPlaceholder',
    defaultMessage: 'Default number',
  },
  errorForeignRecipients: {
    id: 'SMSEdit.errorForeignRecipients',
    defaultMessage: 'Recipients cannot be foreign numbers',
  },
  acceptDisclaimer: {
    id: 'SMSEdit.acceptDisclaimer',
    defaultMessage:
      'I affirm to be legitimized, having valid legal bases, to use this service for the sending that I requested.',
  },
  errorAcceptDisclaimer: {
    id: 'SMSEdit.errorAcceptDisclaimer',
    defaultMessage: 'It is mandatory to accept to accept this rule',
  },
  or: {
    id: 'SMSEdit.or',
    defaultMessage: 'or',
  },
  buttonList: {
    id: 'SMSEdit.button.buttonList',
    defaultMessage: 'New list',
  },
});

class SMSEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openListModal: false,
      data: {},
      acceptDisclaimer: false,
      errors: {},
    };
  }

  componentDidMount() {
    const {
      fetchScheduled,
      campaign,
      scheduled,
      fetchCampaign,
      fetchLists,
      location,
      fetchSMS,
      smsData,
      id,
    } = this.props;

    if (scheduled && id) fetchScheduled();
    if (!scheduled && id && !smsData) {
      fetchSMS({ page: 0 });
    }
    if (campaign) {
      if (id) fetchCampaign({ id });
      fetchLists();
    }
    if (location && queryString.parse(location.search).phone) {
      this.setState({
        data: {
          ...this.state.data,
          recipients: [queryString.parse(location.search).phone],
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (
      location &&
      queryString.parse(location.search).phone &&
      (!prevProps.location ||
        queryString.parse(location.search).phone !==
          queryString.parse(prevProps.location.search).phone)
    ) {
      this.setState({
        data: {
          ...this.state.data,
          recipients: [queryString.parse(location.search).phone],
        },
      });
    }
  }

  handleNameChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        name: e.target.value,
      },
      errors: {},
    });
  };

  handlePublic = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        publicSMS: e.target.checked,
      },
      errors: {},
    });
  };

  handleSenderSelect = (selected) => {
    this.setState({
      data: {
        ...this.state.data,
        sender: selected && selected.value,
      },
      errors: {},
    });
  };

  handleListsSelect = (list) => {
    this.setState({
      data: {
        ...this.state.data,
        list,
      },
      errors: {},
    });
  };

  handleDateChange = (scheduledDate) => {
    this.setState({
      data: {
        ...this.state.data,
        scheduledDate,
      },
      errors: {},
    });
  };

  handleTimeChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        scheduledTime: e.target.value,
      },
      errors: {},
    });
  };

  handleUrlChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        link: e.target.value,
      },
      errors: {},
    });
  };

  handleRecipients = (recipients) => {
    if (recipients.length > 10) {
      this.setState({
        showNoMoreRecipientsAlert: true,
      });
      return;
    }
    this.setState({
      data: {
        ...this.state.data,
        recipients,
      },
      errors: {},
      showNoMoreRecipientsAlert: false,
    });
  };

  handleTextChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        text: e.target.value,
      },
      errors: {},
    });
  };

  handleAcceptDisclaimer = (e) => {
    this.setState({
      acceptDisclaimer: e.target.checked,
      errors: {},
    });
  };

  validate = (data) => {
    // check that:
    // - there is almost one receiver
    // - text is not empty
    // - number of sms * number of receivers is < SMS credits

    const {
      credits,
      scheduled,
      campaign,
      intl: { formatMessage },
    } = this.props;
    const { acceptDisclaimer } = this.state;
    const errors = {};
    if (!data.text || !data.text.trim().length) {
      errors.text = formatMessage(messages.errorMissingText);
    } else if (
      (data.text.search(Utils.URL_REGEX) > -1 ||
        data.text.search(Utils.PSEUDO_URL_REGEX) > -1 ||
        data.text.search(Utils.EMAIL_REGEX) > -1) &&
      !data.sender
    ) {
      errors.text = formatMessage(messages.errorUrlInText);
    }

    if (!data.sender) {
      errors.sender = formatMessage(messages.errorMissingSender);
    }

    if (campaign) {
      if (!data.name || !data.name.trim().length) {
        errors.name = formatMessage(messages.errorMissingName);
      }
      if (!data.lists || !data.lists.length) {
        errors.lists = formatMessage(messages.errorMissingLists);
      }
    } else {
      if (!data.recipients || !data.recipients.length) {
        errors.recipients = formatMessage(messages.errorMissingRecipients);
      }
      if (
        data.recipients &&
        (data.recipients.some((r) => r[0] === '+' && r.indexOf('+39') < 0) ||
          data.recipients.some(
            (r) => r[0] === '0' && r[1] === '0' && r.indexOf('0039') < 0
          ))
      ) {
        errors.recipients = formatMessage(messages.errorForeignRecipients);
      }
      if (
        data.recipients &&
        calculateSMSNumber(data.text) * data.recipients.length > credits
      ) {
        errors.NOT_ENOUGH_SMS = formatMessage(messages.NOT_ENOUGH_SMS);
      }
    }

    if (
      (scheduled || campaign) &&
      (!data.scheduledDate || !moment(data.scheduledDate).isValid())
    ) {
      errors.date = formatMessage(messages.errorMissingDate);
    }
    const pattern = new RegExp(/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/);
    if ((scheduled || campaign) && !pattern.test(data.scheduledTime)) {
      errors.time = formatMessage(messages.errorMissingTime);
    }
    if ((scheduled || campaign) && !errors.date && !errors.time) {
      const scheduledAll = moment(data.scheduledDate)
        .hour(+data.scheduledTime.split(':')[0])
        .minute(+data.scheduledTime.split(':')[1])
        .second(0);
      if (scheduledAll.isBefore(new Date(), 'minute')) {
        errors.date = formatMessage(messages.errorWrongDateTime);
        errors.time = formatMessage(messages.errorWrongDateTime);
      }
    }
    if (!acceptDisclaimer) {
      errors.acceptDisclaimer = formatMessage(messages.errorAcceptDisclaimer);
    }
    return errors;
  };

  sendSMS = (e) => {
    e.preventDefault();

    const {
      sendSMS,
      saveScheduledSMS,
      saveCampaign,
      scheduled,
      campaign,
      id,
      location,
    } = this.props;
    const params = {
      sender: this.arrangeField('sender'),
      text: this.arrangeField('text'),
      link: this.arrangeField('link'),
    };

    if (scheduled || campaign) {
      params.scheduledDate = this.arrangeScheduledDate();
      params.scheduledTime = this.arrangeScheduledTime();
    }
    if (campaign) {
      params.lists = [this.arrangeSelectedList()];
      params.name = this.arrangeField('name');
    } else {
      params.public = this.arrangePublic() || false;
      params.recipients = this.arrangeRecipients();
    }
    const errors = this.validate(params);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      if (id && !location.pathname.includes('copy')) {
        params.id = id;
      }
      if (scheduled || campaign) {
        params.scheduled = moment(params.scheduledDate)
          .hour(+params.scheduledTime.split(':')[0])
          .minute(+params.scheduledTime.split(':')[1])
          .second(0)
          .valueOf();
        delete params.scheduledDate;
        delete params.scheduledTime;
      }
      if (scheduled) {
        saveScheduledSMS(params);
      } else if (campaign) {
        delete params.public;
        saveCampaign(params);
      } else {
        sendSMS(params);
      }
    }
  };

  arrangeField = (field) => {
    const { smsData } = this.props;
    const { data } = this.state;
    if (data[field] !== undefined) return data[field];
    if (!smsData) return null;
    return smsData[field];
  };

  arrangeRecipients = () => {
    const { smsData } = this.props;
    const { data } = this.state;
    if (data.recipients !== undefined) return data.recipients;
    if (!smsData) return [];
    if (smsData.recipient) return [smsData.recipient];
    return smsData.recipients;
  };

  arrangePublic = () => {
    const { smsData } = this.props;
    const { data } = this.state;
    if (data.publicSMS !== undefined) return data.publicSMS;
    if (!smsData) return false;
    return smsData.public;
  };

  arrangeScheduledDate = () => {
    const { smsData } = this.props;
    const { data } = this.state;
    if (data.scheduledDate !== undefined) return data.scheduledDate;
    if (!smsData || !smsData.scheduled) return null;
    return moment(+smsData.scheduled).toDate();
  };

  arrangeScheduledTime = () => {
    const { smsData } = this.props;
    const { data } = this.state;
    if (data.scheduledTime !== undefined) return data.scheduledTime;
    if (!smsData) return null;
    return moment(+smsData.scheduled).format('HH:mm');
  };

  arrangeSelectedAlias = () => {
    const { smsData } = this.props;
    const { data } = this.state;
    if (data.sender !== undefined) return data.sender;
    if (!smsData) return [];
    return smsData.sender;
  };

  arrangeListsOptions = () => {
    const { listsIds, listsById } = this.props;
    return listsIds.map((id) => ({
      label: listsById[id].name,
      value: id,
    }));
  };

  arrangeSelectedList = () => {
    const { smsData } = this.props;
    const { data } = this.state;
    if (data.list !== undefined) return data.list;
    if (!smsData) return null;
    return smsData.lists && smsData.lists[0];
  };

  openListModal = () => {
    this.setState({
      openListModal: true,
    });
  };

  returnFromListModal = (idList) => {
    if (idList) {
      this.setState({
        openListModal: false,
        data: {
          ...this.state.data,
          list: idList,
        },
      });
    } else {
      this.setState({
        openListModal: false,
      });
    }
  };

  render() {
    const {
      saving,
      errorSaving,
      credits,
      fetchingCreditsError,
      creditsFetched,
      useAlias,
      aliasIds,
      scheduled,
      campaign,
      useLink,
      intl: { formatMessage },
    } = this.props;
    const { errors, showNoMoreRecipientsAlert } = this.state;

    const name = this.arrangeField('name');
    const alias = this.arrangeSelectedAlias();
    const recipients = this.arrangeRecipients();
    const text = this.arrangeField('text');
    const link = this.arrangeField('link');
    const publicSMS = this.arrangePublic();
    const scheduledDate = this.arrangeScheduledDate();
    const scheduledTime = this.arrangeScheduledTime();
    const selectedList = this.arrangeSelectedList();

    return (
      <>
        {showNoMoreRecipientsAlert && (
          <ToastMessage
            type="danger"
            text={formatMessage(messages.noMoreRecipients)}
          />
        )}
        {errorSaving && (
          <ToastMessage
            type="danger"
            text={
              messages[errorSaving]
                ? formatMessage(messages[errorSaving])
                : formatMessage(messages.errorMessage)
            }
          />
        )}
        {errors.NOT_ENOUGH_SMS && (
          <ToastMessage
            type="danger"
            text={formatMessage(messages.NOT_ENOUGH_SMS)}
          />
        )}
        {(!aliasIds || aliasIds.length === 0) && (
          <ToastMessage
            type="danger"
            text={formatMessage(messages.notAlias)}
          />
        )}
        {fetchingCreditsError && (
          <Alert
            text={formatMessage(messages.errorFetchingCredits)}
            type="danger"
          />
        )}
        {creditsFetched && credits === 0 && (
          <SMSCreditsAlert credits={credits} />
        )}
        <Form
          className="col p-2 mt-2 rounded border"
          onSubmit={this.sendSMS}
          scrolling
          header
        >
          {campaign && (
            <div className="row">
              <div className="col pt-2">
                <FormGroup>
                  <Label mandatory for="name">
                    {formatMessage(messages.name)}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={this.handleNameChange}
                    value={name}
                    error={errors.name}
                  />
                </FormGroup>
              </div>
            </div>
          )}
          {useAlias && (
            <div className="row">
              <div className="col pt-2">
                <FormGroup>
                  <Label for="sender" mandatory>{formatMessage(messages.sender)}</Label>
                  <AliasSelect
                    alias={alias}
                    onSelect={this.handleSenderSelect}
                    error={errors.sender}
                  />
                </FormGroup>
              </div>
            </div>
          )}
          {campaign ? (
            <div className="d-flex flex-column mb-3">
              <Label mandatory for="lists">
                {formatMessage(messages.lists)}
              </Label>
              <div className="row">
                <div className="col-lg col-12 pr-xl-2 ">
                  <ContactsListsSelect
                    selected={selectedList}
                    onSelect={this.handleListsSelect}
                    error={errors.lists}
                    type="PHONE"
                    showContactsLength
                  />
                </div>
                <div className="col-lg-auto col-12 px-lg-2 px-3 py-lg-2 py-1">
                  {formatMessage(messages.or)}
                </div>
                <div className="col-lg-auto col-12 pl-xl-2 ">
                  <div>
                    <Button
                      className="btn btn-primary"
                      text={formatMessage(messages.buttonList)}
                      type="button"
                      onClick={this.openListModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col">
                <FormGroup>
                  <Label mandatory for="contacts">
                    {formatMessage(messages.contacts)}
                  </Label>
                  <SelectPhoneNumbers
                    phoneNumbers={recipients}
                    onChange={this.handleRecipients}
                    error={errors.recipients}
                  />
                </FormGroup>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              <FormGroup>
                <Label mandatory for="SMStext">
                  {formatMessage(messages.text)}
                </Label>
                <SMSTextArea
                  onChange={this.handleTextChange}
                  text={text}
                  error={errors.text}
                />
              </FormGroup>
            </div>
          </div>
          {useLink && (
            <div className="row">
              <div className="col pt-2">
                <FormGroup>
                  <Label for="url">{formatMessage(messages.url)}</Label>
                  <Input
                    type="text"
                    name="url"
                    id="url"
                    onChange={this.handleUrlChange}
                    value={link}
                  />
                </FormGroup>
              </div>
            </div>
          )}
          {(scheduled || campaign) && (
            <div className="row">
              <div className="col-xl-6">
                <FormGroup>
                  <Label for="scheduledDate" mandatory>
                    {formatMessage(messages.date)}
                  </Label>
                  <DatePickerInput
                    disablePastDays
                    onChange={this.handleDateChange}
                    error={errors && errors.date}
                    day={scheduledDate || ''}
                    clearable
                  />
                </FormGroup>
              </div>
              <div className="col-xl-6">
                <FormGroup>
                  <Label for="scheduledTime" mandatory>
                    {formatMessage(messages.time)}
                  </Label>
                  <TimeInput
                    value={scheduledTime || ''}
                    name="scheduledTime"
                    id="scheduledTime"
                    onChange={this.handleTimeChange}
                    error={errors && errors.time}
                  />
                </FormGroup>
              </div>
            </div>
          )}
          {!campaign && (
            <div className="row">
              <div className="col pt-2">
                <FormGroup>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <input
                      type="checkbox"
                      id="publicSMS"
                      name="publicSMS"
                      checked={publicSMS}
                      className="custom-control-input"
                      onChange={this.handlePublic}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="publicSMS"
                      style={{ fontWeight: 600, color: 'var(--yn-blue-dark)' }}
                    >
                      {formatMessage(messages.public)}
                    </label>
                  </div>
                </FormGroup>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col pt-2">
              <FormGroup>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    id="acceptDisclaimer"
                    name="acceptDisclaimer"
                    className={`custom-control-input ${
                      errors.acceptDisclaimer ? 'is-invalid' : ''
                    }`}
                    onChange={this.handleAcceptDisclaimer}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="acceptDisclaimer"
                    style={{ color: 'var(--yn-blue-dark)' }}
                  >
                    {formatMessage(messages.acceptDisclaimer)}
                  </label>
                </div>
                {errors.acceptDisclaimer && (
                  <div
                    className="text-left"
                    style={{ fontSize: '90%', color: '#dc3545' }}
                  >
                    {errors.acceptDisclaimer}
                  </div>
                )}
              </FormGroup>
            </div>
          </div>
          <div className="pt-4 pb-4 text-right">
            <Button
              className="btn btn-primary"
              loading={saving}
              text={formatMessage(messages.buttonSend)}
              disabled={!creditsFetched || credits === 0}
            />
          </div>
        </Form>
        {this.state.openListModal && (
          <AbookListsCreateListModal onClose={this.returnFromListModal} />
        )}
      </>
    );
  }
}

SMSEdit.propTypes = {
  id: PropTypes.number,
  scheduled: PropTypes.bool,
  campaign: PropTypes.bool,
};

SMSEdit.defaultProps = {
  scheduled: false,
  campaign: false,
};

const mapStateToProps = (state, ownProps) => {
  const saving = ownProps.campaign
    ? !getSaveSMSCampaignLoaded(state)
    : ownProps.scheduled
    ? !getSaveSMSScheduledLoaded(state)
    : !getSendSMSLoaded(state);

  const errorSaving = ownProps.campaign
    ? getSaveSMSCampaignError(state)
    : ownProps.scheduled
    ? getSaveSMSScheduledError(state)
    : getSendSMSError(state);

  const smsData = ownProps.id
    ? ownProps.campaign
      ? getSMSCampaignById(state, ownProps.id)
      : ownProps.scheduled
      ? getSMSScheduledById(state, ownProps.id)
      : getSMSById(state, ownProps.id)
    : {};

  return {
    saving,
    errorSaving,
    creditsFetched: isSMSCreditsFetched(state),
    fetchingCredits: !getFetchSMSCreditsLoaded(state),
    fetchingCreditsError: getFetchSMSCreditsError(state),
    credits: getSMSCredits(state),
    useAlias: usingSMSAlias(state),
    useLink: usingSMSLink(state),
    smsData,
    aliasIds: getValidSMSAliasIds(state),
    aliasById: getSMSsAliasById(state),
    listsIds: getAbookListsIds(state),
    listsById: getAbookListsById(state),
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    sendSMS: sendSMSRequest,
    saveScheduledSMS: saveSMSScheduledRequest,
    saveCampaign: saveSMSCampaignRequest,
    fetchSMS: fetchSMSListRequest,
    fetchScheduled: fetchSMSScheduledRequest,
    fetchLists: fetchAbookListsRequest,
    fetchCampaign: fetchSMSCampaignRequest,
  })(SMSEdit)
);
