import { createSelector } from 'reselect';
import uniq from 'lodash.uniq';
import findKey from 'lodash.findkey';
import sortBy from 'lodash.sortby';
import { hasGrants } from '../me/selectors';
import { getUsers, getUserMainExtensionUsername } from '../users/selectors';
import { PhoneEnums } from './PhoneUtils';
import { PbxSettings, removeUsernameToCallid } from './PbxSettingsUtils';

const defaultObject = {};
const getAllCallsIds = (state) => state.phone.allCallsIds;
export const getAllCallsById = (state) => state.phone.allCallsById;
export const getMyPbxId = (state) =>
  state.phoneSettings.pbx && state.phoneSettings.pbx.id;
export const getSupplier = (state) =>
  state.phoneSettings.pbx && state.phoneSettings.pbx.supplier;
export const getPhoneError = (state) =>
  state.phone.status === PhoneEnums.PhoneStatuses.error;
export const getExtensionsByUsername = (state) =>
  state.phone.extensionsByUsername;
export const getMainPhoneData = (state) => state.phoneSettings.extensions.main;
export const getOthersPhoneData = (state) =>
  state.phoneSettings.extensions.others;
export const getPbxData = (state) => state.phoneSettings.pbx;
export const getMeMainExtensionUsername = (state) =>
  state.me.mainExtensionUsername;
export const getMeMainExtensionNumber = (state) => state.me.mainExtensionNumber;
// export const getMeWebrtcExtensionUsername = state => state.me.webrtcExtensionUsername
export const getMeWebrtcExtensionNumber = (state) =>
  state.me.webrtcExtensionNumber;
export const getMeMobileExtensionNumber = (state) =>
  state.me.mobileExtensionNumber;
export const isPhoneInited = (state) =>
  (state.me.mainExtensionUsername &&
    state.phone.status === PhoneEnums.PhoneStatuses.offline) ||
  state.phone.status === PhoneEnums.PhoneStatuses.inited ||
  state.phone.status === PhoneEnums.PhoneStatuses.nophone;
export const isPhoneAvailable = (state) =>
  !!(
    getMeMainExtensionUsername(state) &&
    state.phone.extensionsByUsername[getMeMainExtensionUsername(state)]
  );
export const isAssistedTransferAvailable = (state) =>
  state.phoneSettings.pbx && state.phoneSettings.pbx.assistedTransferEnabled;
export const isHoldCallAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.ABILIS &&
  state.phoneSettings.extensions.main.port !== PhoneEnums.Interfaces.SIP;
export const isSwitchCallsAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.ABILIS &&
  state.phoneSettings.extensions.main.port !== PhoneEnums.Interfaces.SIP;
export const isLinkCallsAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.ABILIS;
export const isMuteCallAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.NETHESIS;
export const isRecordCallAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.NETHESIS &&
  state.phoneSettings.pbx.recordingCallsEnabled &&
  hasGrants(state, 'PBX_REC');
export const isSpyCallAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.NETHESIS &&
  hasGrants(state, 'PBX_SPY');
export const isCallingForwardRuleAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.ABILIS;
export const isPbxManagementAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.ABILIS;
export const isResponderAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.ABILIS;
export const isCallDirectionAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.ABILIS;
export const isParkedDndAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.ABILIS;
export const isConferenceAvailable = (state) =>
  state.phoneSettings.pbx &&
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.NETHESIS &&
  state.phoneSettings.pbx.conferenceEnabled;
export const getExtensionsUsernameByNumbers = createSelector(
  [getExtensionsByUsername],
  (extensionsByUsername) => {
    let numbers = {};
    Object.keys(extensionsByUsername).map((username) =>
      Object.keys(extensionsByUsername[username].numbers).forEach((number) => {
        numbers = {
          ...numbers,
          [number]: username,
        };
      })
    );
    return numbers;
  }
);
export const getExtensionsLoaded = (state) => state.phone.fetchedUsers;
const calculateUserPhoneStatus = (state, username, excludedNumber) => {
  const statuses = state.phone.extensionsByUsername[username]
    ? state.phone.extensionsByUsername[username].numbers
    : {};
  let status = '';
  Object.keys(statuses).forEach((number) => {
    if (!excludedNumber || number !== excludedNumber) {
      if (status === '') {
        status = statuses[number];
      } else if (
        PhoneEnums.StatusPriorities[statuses[number]] >
        PhoneEnums.StatusPriorities[status]
      ) {
        status = statuses[number];
      }
    }
  });
  return status;
};
export const getMePhoneStatus = (state) => {
  if (Object.keys(state.phone.extensionsByUsername).length === 0) {
    return state.phone.status;
  }
  return calculateUserPhoneStatus(
    state,
    getMeMainExtensionUsername(state),
    getMeMobileExtensionNumber(state)
  );
};
export const getMePhoneDnd = (state) => {
  if (Object.keys(state.phone.extensionsByUsername).length === 0) {
    return null;
  }
  return (
    state.phone.extensionsByUsername[getMeMainExtensionUsername(state)] &&
    state.phone.extensionsByUsername[getMeMainExtensionUsername(state)].dnd
  );
};
export const getMePhoneActiveRule = (state) => {
  if (Object.keys(state.phone.extensionsByUsername).length === 0) {
    return null;
  }
  return state.phone.extensionsByUsername[getMeMainExtensionUsername(state)]
    .pbxSettings &&
    state.phone.extensionsByUsername[getMeMainExtensionUsername(state)]
      .pbxSettings.active
    ? state.phone.extensionsByUsername[getMeMainExtensionUsername(state)]
        .pbxSettings.status
    : null;
};
const selectUserPhoneStatus = (state, userId) => {
  if (!state.users.byId[userId]) {
    return null;
  }
  return (
    (state.phone.extensionsByUsername[
      getUserMainExtensionUsername(state, userId)
    ] &&
      calculateUserPhoneStatus(
        state,
        getUserMainExtensionUsername(state, userId)
      )) ||
    PhoneEnums.PhoneStatuses.nophone
  );
};
export const getUserPhoneStatus = createSelector(
  [selectUserPhoneStatus],
  (status) => status
);
export const getUserPhoneDnd = (state, userId) =>
  state.users.byId[userId] &&
  state.phone.extensionsByUsername[
    state.users.byId[userId].mainExtensionUsername
  ]
    ? state.phone.extensionsByUsername[
        state.users.byId[userId].mainExtensionUsername
      ].dnd
    : null;
export const getExtensionDnd = (state, username) =>
  state.phone.extensionsByUsername[username] &&
  state.phone.extensionsByUsername[username].dnd;
export const getExtensionStatus = (state, username) => {
  if (state.phone.extensionsByUsername[username])
    return calculateUserPhoneStatus(state, username);
  return defaultObject;
};
export const isSearchedContactsLoaded = (state) =>
  state.phone.searchContacts.loaded;
export const isSearchedContactSelected = (state, target) =>
  state.phone.searchContacts[target].selected;
export const getSearchedContacts = (state) =>
  state.phone.searchContacts.data.map((contact) => ({
    id: contact.id,
    fullname: contact.fullname,
    avatar: contact.avatar,
    number: contact.number.number,
    type: contact.number.type,
    username: contact.username,
    interlocutorType: contact.type,
  }));
export const getSearchedContactsTarget = (state) =>
  state.phone.searchContacts.filter.target;
export const getSearchedNumber = (state, target) =>
  state.phone.searchContacts[target].number;
export const isSearchContactsListOpen = (state, target) =>
  state.phone.searchContacts[target].listOpen;
export const isPhoneLogged = (state) =>
  state.phone.status === PhoneEnums.PhoneStatuses.inited;
export const getParkPrefix = (state) => state.phoneSettings.pbx.parkPrefix;
export const getExtensionsLength = (state) =>
  state.phoneSettings.pbx.extensionsLength;
export const isPhoneMuted = (state) => state.phone.muted;
export const isRecording = (state) => state.phone.muted;
export const isWebrtcAvailable = (state) =>
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.NETHESIS &&
  state.phoneSettings.extensions.others &&
  state.phoneSettings.extensions.others.filter(
    (other) => other.type === 'WEBRTC'
  ).length > 0;
export const isWebrtcActive = (state) =>
  isWebrtcAvailable(state) ? state.phone.webrtc.active : 'OFF';
export const getToggleWebrtcError = (state) => state.phone.webrtc.error;
export const getIncomingCallJsep = (state) =>
  state.phone.webrtc.incomingCallJsep;
export const isWebrtcSpying = (state) =>
  state.phone.webrtc.incomingCallJsep && state.phone.webrtc.isSpying;
const getSingleCallsIds = createSelector(
  [getAllCallsIds, getAllCallsById],
  (allCallsIds, allCallsById) => {
    let calls = [];
    // const ids = uniq(getCallsIds(state).map(id => removeUsernameToCallid(id)));
    const ids = uniq(
      allCallsIds.map((id) => removeUsernameToCallid(id))
    ); /* in beta environment this filter excludes calls for some extensions
    .filter(
      id => id.indexOf('Local') !== 0
    ) */
    ids.forEach((id) => {
      calls = [...calls, findKey(allCallsById, { callid: id })];
    });
    return calls;
  }
);

export const getCalls = (state) => {
  const callsIds = hasGrants(state, 'PBX_VIEW_ALL_CALLS')
    ? getSingleCallsIds(state)
    : state.phone.allCallsIds; // getCallsIds(state);

  return callsIds
    .map((callId) => state.phone.allCallsById[callId])
    .filter(
      (call) =>
        call.called !== getMeMainExtensionNumber(state) &&
        call.calling !== getMeMainExtensionNumber(state) &&
        call.called !== getMeWebrtcExtensionNumber(state) &&
        call.calling !== getMeWebrtcExtensionNumber(state) &&
        call.username !== getMeMainExtensionUsername(state) &&
        !(
          call.called &&
          call.called.indexOf(getParkPrefix(state)) === 0 &&
          call.called.indexOf(getMeMainExtensionNumber(state)) ===
            call.called.length - getMeMainExtensionNumber(state).length
        ) &&
        !(
          call.called &&
          getMeWebrtcExtensionNumber(state) &&
          call.called.indexOf(getParkPrefix(state)) === 0 &&
          call.called.indexOf(getMeWebrtcExtensionNumber(state)) ===
            call.called.length - getMeWebrtcExtensionNumber(state).length
        ) &&
        !(
          call.calling &&
          call.calling.indexOf(getParkPrefix(state)) === 0 &&
          call.calling.indexOf(getMeMainExtensionNumber(state)) ===
            call.calling.length - getMeMainExtensionNumber(state).length
        ) &&
        !(
          call.calling &&
          getMeWebrtcExtensionNumber(state) &&
          call.calling.indexOf(getParkPrefix(state)) === 0 &&
          call.calling.indexOf(getMeWebrtcExtensionNumber(state)) ===
            call.calling.length - getMeWebrtcExtensionNumber(state).length
        ) &&
        /* AL 07/08/2019 added       
          (!hasGrants(state, 'PBX_VIEW_ALL_CALLS') || !call.callback)
          to not to show 2 panels when there is a call born from a callback */
        (!hasGrants(state, 'PBX_VIEW_ALL_CALLS') || !call.callback)
      /* !startsWith(call.calling, state.phoneSettings.pbx.parkPrefix) &&
        !startsWith(call.called, state.phoneSettings.pbx.parkPrefix) &&
        call.called !== '<unknown>' && 
        call.calling !== '<unknown>' */
    );
};

export const isPhoneStatusAutoChanged = (state) =>
  state.phoneSettings.pbx.supplier === PhoneEnums.PbxType.NETHESIS;
export const getParkings = (state) =>
  state.phone.allParksIds
    .map((parkId) => state.phone.allParksById[parkId])
    .filter(
      (call) =>
        call.called ===
          `${PbxSettings.PREFIX_PARK}${getMeMainExtensionNumber(state)}` ||
        call.owner === getMeWebrtcExtensionNumber(state) ||
        call.owner === getMeMainExtensionNumber(state)
    );
export const getQueues = (state) =>
  state.phone.allQueuesIds
    .map((queueId) => state.phone.allQueuesById[queueId])
    .filter(
      (call) =>
        call.called !== getMeMainExtensionNumber(state) &&
        call.calling !== getMeMainExtensionNumber(state) &&
        call.called !== getMeWebrtcExtensionNumber(state) &&
        call.calling !== getMeWebrtcExtensionNumber(state)
    );
export const activeCallback = (state) => state.me.callback;
export const getExtensionsSelectOptions = createSelector(
  getUsers,
  (users) =>
    users &&
    sortBy(
      users
        .filter(
          (user) =>
            user.fullname &&
            user.mainExtensionNumber &&
            user.mainExtensionNumber !== PbxSettings.DISABLED_PHONE_NUMBER
        )
        .map((user) => ({
          value: user.mainExtensionNumber,
          label: `${user.mainExtensionNumber} - ${user.departmentFullname}`,
        })),
      [(option) => option.label]
    )
);
export const getRoomsSelectOptions = createSelector(
  getUsers,
  (users) =>
    users &&
    sortBy(
      users
        .filter(
          (user) =>
            user.mainExtensionNumber &&
            user.mainExtensionNumber !== PbxSettings.DISABLED_PHONE_NUMBER
        )
        .map((user) => ({
          value: user.id,
          label: user.mainExtensionNumber,
        })),
      [(option) => option.label]
    )
);
export const getUsernameSelectOptions = createSelector(
  getUsers,
  (users) =>
    users &&
    sortBy(
      users
        .filter(
          (user) =>
            user.fullname &&
            user.mainExtensionUsername &&
            user.mainExtensionNumber !== PbxSettings.DISABLED_PHONE_NUMBER
        )
        .map((user) => ({
          value: user.mainExtensionUsername,
          label: `${user.mainExtensionNumber} - ${user.departmentFullname}`,
        })),
      [(option) => option.label]
    )
);

export const getMeVideocallDnd = (state) => state.me.videocallDnd;
export const isNumberAnExtension = (state, number) =>
  state.users.allIds
    .map((id) => state.users.byId[id])
    .filter(
      (user) =>
        user.mainExtensionNumber === number ||
        user.webrtcExtensionNumber === number
    ).length > 0;
export const isPhoneOffline = (state) =>
  state.phone.status === PhoneEnums.PhoneStatuses.offline;
export const isPhonePlayingBusy = (state) => state.phone.playbusy;
export const sentStopUseWebphoneError = (state) =>
  state.phone.sentStopUseWebphoneError;
export const sentStopUseWebphoneLoaded = (state) =>
  state.phone.sentStopUseWebphoneLoaded;
export const isWebrtcInUse = (state) =>
  isWebrtcAvailable(state) && state.phone.webrtc.using;
export const isWebrtcNoSdpCall = (state) => state.phone.webrtc.noSdpCall;
export const isWebrtcSdpCall = (state) => state.phone.webrtc.sdpCall;
export const isValidJsep = (state) => state.phone.webrtc.validJsep;
export const isOutgoingWebrtcCall = (state) => state.phone.webrtc.outgoingCall;
export const isIncomingWebrtcCall = (state) => state.phone.webrtc.incomingCall;
export const isActiveWebrtcCall = (state) => state.phone.webrtc.activeCall;
export const isWebCallWithoutJsep = (state) => {
  return (
    getMePhoneStatus(state) === PhoneEnums.CallsStatuses.ringing &&
    isWebrtcActive(state) &&
    isWebrtcInUse(state) &&
    !isValidJsep(state) &&
    !isOutgoingWebrtcCall(state)
  );
};
export const isWebphoneDetached = (state) => state.phone.webrtc.detached;
export const isNoValidJsepCall = (state) => state.phone.webrtc.noValidJsepCall;
export const isWebphoneReactivating = (state) =>
  state.phone.webrtc.reactivating;
export const getFetchPbxRoutesError = (state) =>
  state.phoneSettings.pbx.fetchPbxRoutesError;
export const getFetchPbxRoutesLoaded = (state) =>
  state.phoneSettings.pbx.fetchPbxRoutesLoaded;
export const getPbxRoutes = (state) => state.phoneSettings.pbx.routes;
export const getSavePbxRouteError = (state) =>
  state.phoneSettings.pbx.savePbxRouteError;
export const getSavePbxRouteLoaded = (state) =>
  state.phoneSettings.pbx.savePbxRouteLoaded;
export const getFetchPbxQueuesError = (state) =>
  state.phoneSettings.pbx.fetchPbxQueuesError;
export const getFetchPbxQueuesLoaded = (state) =>
  state.phoneSettings.pbx.fetchPbxQueuesLoaded;
export const getPbxEditingQueues = (state) =>
  state.phone.queues.map((queue) => {
    const found = state.phoneSettings.pbx.queues
      ? state.phoneSettings.pbx.queues.find(
          (queueEdit) => queueEdit.queue === queue.id
        )
      : null;
    return {
      ...queue,
      description: found ? found.name : null,
    };
  });
export const getPbxEditedQueues = (state) =>
  state.phone.queues.map((queue) => {
    const found = state.phoneSettings.pbx.queues
      ? state.phoneSettings.pbx.queues.find(
          (queueEdit) => queueEdit.queue === queue.id
        )
      : null;
    return {
      ...queue,
      name: found ? found.name : queue.name,
    };
  });
export const getSavePbxQueueError = (state) =>
  state.phoneSettings.pbx.savePbxRouteError;
export const getSavePbxQueueLoaded = (state) =>
  state.phoneSettings.pbx.savePbxRouteLoaded;
export const getPbxQueues = (state) => state.phone.queues;
export const isWebPhoneRegistered = (state) => state.phone.webrtc.registered;
export const receivedDefaultDevices = (state) =>
  state.phone.webrtc.active !== null;
