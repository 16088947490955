import { createSelector } from 'reselect';

const defaultList = [];
export const isPuncherEnabled = (state) => state.me.customer.puncherEnabled;
export const getFetchSettingsLoaded = (state) =>
  state.puncher.fetchSettingsLoaded;
export const getFetchSettingsError = (state) =>
  state.puncher.fetchSettingsError;
export const getPuncherTimeDifference = (state) => state.puncher.timeDifference;
export const isLastPunghingIn = (state) => state.puncher.lastPunchingIsIn;
export const getPuncherFilter = (state) => state.puncher.filter;
export const getPunchingsTotal = (state) => state.puncher.totalPunchings;
export const getPunchingsAllIds = (state) => state.puncher.allIds;
export const getPunchingById = (state, id) => state.puncher.allById[id];
export const getPunchingByIds = (state) => state.puncher.allById;
export const getPunchings = createSelector(
  [getPunchingsAllIds, getPunchingByIds],
  (punchingsAllIds, punchingByIds) =>
    punchingsAllIds.map((id) => punchingByIds[id])
);
export const getFetchPunchingsLoaded = (state) =>
  state.puncher.fetchPunchingsLoaded;
export const getFetchPunchingsError = (state) =>
  state.puncher.fetchPunchingsError;
export const getSavePunchingLoaded = (state) =>
  state.puncher.savePunchingLoaded;
export const getSavePunchingError = (state) => state.puncher.savePunchingError;
export const getCurrentPage = (state) => state.puncher.currentPage;
export const getFetchPunchingDetailsLoaded = (state) =>
  state.puncher.fetchPunchingDetailsLoaded;
export const isFetchPbxRegistryLoaded = (state) => state.registry.fetched;
export const isFetchPbxRegistryError = (state) => state.registry.errorFetching;
export const getPbxRegistryDataById = (state, id) => state.registry.data[id];
export const isSavePbxRegistryLoaded = (state) => state.registry.savingLoaded;
export const isSavePbxRegistryError = (state) => state.registry.errorSaving;
export const isSavePbxRegistrySuccess = (state) => state.registry.successSaving;
export const isPunchingsChanged = (state) => state.puncher.punchingsChanged;

export const getFetchPunchingsToValidateLoaded = (state) =>
  state.puncher.fetchPunchingsToValidateLoaded;
export const getFetchPunchingsToValidateError = (state) =>
  state.puncher.fetchPunchingsToValidateError;
export const getPunchingsToValidateCurrentPage = (state) =>
  state.puncher.punchingsToValidateCurrentPage;
export const getPunchingsToValidateTotal = (state) =>
  state.puncher.punchingsToValidateTotal;
export const getPunchingsToBeValidatedIdsPaged = (state) => {
  const page = state.puncher.punchingsToValidateCurrentPage;
  const pageIds = state.puncher.punchingsToValidatePages[page];
  if (pageIds === undefined) {
    return defaultList;
  }
  return pageIds;
};
export const getPunchingsToBeValidatedById = (state, id) =>
  state.puncher.punchingsToValidateById[id];
export const getPunchingsToBeValidatedFilter = (state) =>
  state.puncher.punchingValidationsFilter;

export const getValidatePunchingLoading = (state) =>
  state.puncher.validatePunchingLoading;
export const getValidatePunchingError = (state) =>
  state.puncher.validatePunchingError;
export const isPunchingsToValidateChanged = (state) =>
  state.puncher.punchingsToValidateChanged;
export const getDeleteOtherPunchingLoading = (state) =>
  state.puncher.deletePunchingLoading;
export const getDeleteOtherPunchingError = (state) =>
  state.puncher.deletePunchingError;
export const getEditOtherPunchingLoading = (state) =>
  state.puncher.editPunchingLoading;
export const getEditOtherPunchingError = (state) =>
  state.puncher.editPunchingError;